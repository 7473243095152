import type {
  AttachmentPublic,
  BasicContact,
  ComplexQuestion,
  ComplexQuestionInsert,
  ComplexQuestionType,
  InterviewAgentTypesEnum,
  InterviewLanguagesEnum,
  InterviewQuestion,
  InterviewStatus,
  LineChartDataSegment,
  MatrixQuestionPublic,
  MultipleChoiceQuestionPublic,
  ProjectModesEnum,
  SurveyScale,
  TranscriptFragmentRolesEnum,
} from "app-types";
import type * as React from "react";

/*
 * Utilities.
 */

export type Handler = React.MouseEventHandler<HTMLElement>;

export type HandlerOf<T> = (data: T) => void;

// eslint-disable-next-line @typescript-eslint/no-empty-function -- Intentionally empty utility function
export function noop() {}

/*
 * Enums.
 */

export enum IncentiveProviderType {
  Amazon = "AMAZON",
}

export function getRatingsFromSurveyScale(surveyScale: SurveyScale) {
  switch (surveyScale) {
    case "important_unimportant":
      return [
        {
          name: "Not at all important",
          rating: 1,
        },
        {
          name: "Not so important",
          rating: 2,
        },
        {
          name: "Somewhat important",
          rating: 3,
        },
        {
          name: "Very important",
          rating: 4,
        },
        {
          name: "Extremely important",
          rating: 5,
        },
      ];
    case "agree_disagree":
      return [
        {
          name: "Strongly disagree",
          rating: 1,
        },
        {
          name: "Disagree",
          rating: 2,
        },
        {
          name: "Neutral",
          rating: 3,
        },
        {
          name: "Agree",
          rating: 4,
        },
        {
          name: "Strongly agree",
          rating: 5,
        },
      ];
    case "satisfied_dissatisfied":
      return [
        {
          name: "Very dissatisfied",
          rating: 1,
        },
        {
          name: "Dissatisfied",
          rating: 2,
        },
        {
          name: "Neutral",
          rating: 3,
        },
        {
          name: "Satisfied",
          rating: 4,
        },
        {
          name: "Very satisfied",
          rating: 5,
        },
      ];
    default:
      return [];
  }
}

export const ComplexQuestionTypeToString: Record<ComplexQuestionType, string> =
  {
    rating: "Rating scale",
    multiple_choice: "Multiple choice",
  };

export type SurveyInsights = MatrixQuestionInsights | MultipleChoiceInsights;

export interface MatrixQuestionInsights {
  question: string;
  scale: SurveyScale;
  response_count: number;
  type: "rating";
  data: RatingSurveyDataPoint[];
}

export interface RatingSurveyDataPoint {
  name: string;
  // The key is the rating, the value is the count of that rating
  // e.g. 1: 12 means 12 people rated this as 1
  ratings: Record<number, number>;
}

export interface MultipleChoiceInsights {
  question: string;
  type: "multiple_choice";
  response_count: number;
  data: MultipleChoiceDataPoint[];
}

export interface MultipleChoiceDataPoint {
  name: string;
  response_count: number;
}

export enum TrendDataTypesEnum {
  SENTIMENT = "sentiment",
}

export interface TrendData {
  type: TrendDataTypesEnum;
  data: LineChartDataSegment[];
}

// Use `category:object_action` naming convention
// `action` should be a present tense verb
export enum PosthogEventTypesEnum {
  ProjectCreate = "projects_list:project_create",
  ProjectLaunch = "project_configuration:project_launch",
  ParticipantsInvite = "project_configuration:participants_invite",
  TeammatesInvite = "company_settings:teammates_invite",
  TeammateDomainLogin = "signup_flow:teammate_domainlogin",
  InterviewStart = "interview_app:interview_start",
  InterviewEnd = "interview_app:interview_end",
  TrialContactUs = "trial:contactus_click",
  InterviewUnsupportedBrowser = "interview_app:interview_unsupported_browser",
  InterviewFeedbackSubmit = "interview_slideover:feedback_submit",
  InterviewEmptyAudioResponse = "interview_app:empty_audio_response",
}

/*
 * Types.
 */

export interface BasicQuestion {
  question: string;
}

export type ProjectQuestion =
  | BasicQuestion
  | ComplexQuestion
  | ComplexQuestionInsert;

export type ComplexQuestionPublic =
  | MatrixQuestionPublic
  | MultipleChoiceQuestionPublic;

export type ProjectQuestionPublic = BasicQuestion | ComplexQuestionPublic;

export interface PhoneNumberWithExtensionPublic {
  phone_number: string;
  extension: string;
}

export interface ProjectPublic {
  company_name: string;
  logo_url: string | null;
  favicon_url: string | null;
  redirect_url: string | null;
  incentive_quantity: number | null;
  short_id: string;
  questions: ProjectQuestionPublic[];
  has_sensitive_data: boolean;
  interview_color: string | null;
  welcome_message: string | null;
  outro_message: string | null;
  mode: ProjectModesEnum | null;
  interview_agent_type: InterviewAgentTypesEnum;
  resume_collection_type: ResumeCollectionTypesEnum | null;
  interview_language: InterviewLanguagesEnum;
  should_force_fullscreen: boolean;
  should_record_video: boolean;
  phone_number_with_extension: PhoneNumberWithExtensionPublic | null;
}

// The interview object that the interview client uses
export interface InterviewPublic {
  id: string;
  questions: InterviewQuestion[];
  status: InterviewStatus;
  contact: BasicContact;
  attachments: AttachmentPublic[];
}

export enum TranscriptFragmentSaveStatusEnum {
  IDLE = "idle",
  SAVING = "saving",
  SAVED = "saved",
  ERROR = "error",
}

// Used in the interview client
export interface TranscriptFragmentPublicJSON {
  id: string;
  start_time: string;
  end_time: string;
  question: string | null;
  is_dynamic_question: boolean;
  save_status: TranscriptFragmentSaveStatusEnum;
}

// Used in the admin app client
export interface TranscriptFragment {
  id: string;
  interview_id: string;
  project_id: string;
  start_time: string;
  end_time: string;
  question: string | null;
  is_dynamic_question: boolean;
  dynamic_transcript_fragment_ids: string[];
  save_status: TranscriptFragmentSaveStatusEnum;
  text_transcript: string | null;
  text_transcript_translations?: Partial<
    Record<InterviewLanguagesEnum, string>
  >;
  role: TranscriptFragmentRolesEnum | null;
}

export enum LoadingStatesEnum {
  LOADING = "loading",
  LOADED = "loaded",
  ERROR = "error",
}

export enum ResumeCollectionTypesEnum {
  BeforeInterview = "before_interview",
  AfterInterview = "after_interview",
}

export enum InterviewDeviceTypesEnum {
  MICROPHONE = "microphone",
  CAMERA = "camera",
}
