import axios, { AxiosInstance } from "axios";
import { apiBaseUrl, getApiAuthorizationHeaderValue } from "./shared";

const instance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

/*
 * Example usage in an async function:
 *   const axios = await getAxiosInstanceWithAuth();
 *   const response = axios.post("/company", { name: companyName });
 */
export async function getAxiosInstanceWithAuth(): Promise<AxiosInstance> {
  const authorization = await getApiAuthorizationHeaderValue();
  instance.defaults.headers["Authorization"] = authorization;
  return instance;
}
