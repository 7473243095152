import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MessageTemplate } from "app-types";
import { apiBaseUrl, getApiAuthorizationHeaderValue } from "./shared";

const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: async (headers) => {
    const authorization = await getApiAuthorizationHeaderValue();
    headers.set("Authorization", authorization);
  },
});

const TagType = {
  MessageTemplate: "MessageTemplate",
} as const;

const SpecialTagId = {
  All: "$all",
} as const;

export const api = createApi({
  baseQuery,
  tagTypes: [TagType.MessageTemplate],
  endpoints: (builder) => ({
    // TODO: Refactor to make it easier to add endpoints for other entities in the future.
    allMessageTemplates: builder.query<MessageTemplate[], void>({
      query: () => "/message-templates",
      providesTags: (result) => [
        {
          type: TagType.MessageTemplate,
          id: SpecialTagId.All,
        },
        ...(result ?? []).map(({ id }) => ({
          type: TagType.MessageTemplate,
          id,
        })),
      ],
    }),
    createMessageTemplate: builder.mutation<
      MessageTemplate,
      Pick<MessageTemplate, "type" | "subject" | "body">
    >({
      query: (values) => ({
        method: "POST",
        url: "/message-templates",
        body: values,
      }),
      invalidatesTags: () => [
        {
          type: TagType.MessageTemplate,
          id: SpecialTagId.All,
        },
      ],
    }),
    updateMessageTemplate: builder.mutation<
      MessageTemplate,
      Pick<MessageTemplate, "id"> &
        Partial<Pick<MessageTemplate, "type" | "subject" | "body">>
    >({
      query: (values) => ({
        method: "PATCH",
        url: `/message-templates/${encodeURIComponent(values.id)}`,
        body: values,
      }),
      invalidatesTags: (result, error, { id }) => [
        {
          type: TagType.MessageTemplate,
          id,
        },
        {
          type: TagType.MessageTemplate,
          id: SpecialTagId.All,
        },
      ],
    }),
    deleteMessageTemplate: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/message-templates/${encodeURIComponent(id)}`,
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          api.util.updateQueryData(
            "allMessageTemplates",
            undefined,
            (draft) => {
              const index = draft.findIndex((template) => template.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
      invalidatesTags: (result, error, id) => [
        {
          type: TagType.MessageTemplate,
          id,
        },
        {
          type: TagType.MessageTemplate,
          id: SpecialTagId.All,
        },
      ],
    }),
  }),
});

export const {
  useAllMessageTemplatesQuery,
  useCreateMessageTemplateMutation,
  useUpdateMessageTemplateMutation,
  useDeleteMessageTemplateMutation,
} = api;
