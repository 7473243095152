import {
  TablesInsertWithFixedTypes,
  TablesUpdateWithFixedTypes,
  TablesWithFixedTypes,
} from "../db/supabase_with_fixed_types";

export enum MessageTemplateTypesEnum {
  ADVANCED = "advanced",
  REJECTED = "rejected",
}

export const MessageTemplateTypeToLabel = {
  [MessageTemplateTypesEnum.ADVANCED]: "Advancement message",
  [MessageTemplateTypesEnum.REJECTED]: "Rejection message",
};

export const MessageTemplateVariablesEnum = {
  CONTACT_FIRST_NAME: "contact.first_name",
  CONTACT_LAST_NAME: "contact.last_name",
};

export type MessageTemplateDb = TablesWithFixedTypes<"message_template">;
export type MessageTemplateInsert =
  TablesInsertWithFixedTypes<"message_template">;
export type MessageTemplateUpdate =
  TablesUpdateWithFixedTypes<"message_template">;
export type MessageTemplate = Omit<MessageTemplateDb, "type"> & {
  type: MessageTemplateTypesEnum;
};
