import { MessageTemplateTypesEnum, Project } from "app-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Label, SizesEnum } from "ui";
import { useAllMessageTemplatesQuery } from "../../../api/redux";
import { useAppDispatch } from "../../../hooks/hook";
import { MessageTemplateField } from "../../messageTemplates/messageTemplateField";
import { updateProject } from "../projectsSlice";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";

export interface MessagingAutomationSectionProps {
  project: Project;
}

export const MessagingAutomationSection: FC<
  MessagingAutomationSectionProps
> = ({ project }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [acceptanceTemplateId, setAcceptanceTemplateId] = useState<
    string | null
  >(project.settings.acceptance_template_id);
  const [rejectionTemplateId, setRejectionTemplateId] = useState<string | null>(
    project.settings.rejection_template_id,
  );
  const [isSaving, setIsSaving] = useState(false);

  const { data: allMessageTemplates, isLoading } =
    useAllMessageTemplatesQuery();

  const acceptanceTemplates =
    allMessageTemplates?.filter(
      (template) => template.type === MessageTemplateTypesEnum.ADVANCED,
    ) ?? [];
  const rejectionTemplates =
    allMessageTemplates?.filter(
      (template) => template.type === MessageTemplateTypesEnum.REJECTED,
    ) ?? [];

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await dispatch(
        updateProject({
          projectId: project.id,
          changes: {
            settings: {
              ...project.settings,
              acceptance_template_id: acceptanceTemplateId,
              rejection_template_id: rejectionTemplateId,
            },
          },
        }),
      ).unwrap();
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    setAcceptanceTemplateId(project.settings.acceptance_template_id);
    setRejectionTemplateId(project.settings.rejection_template_id);
  };

  const hasChanges =
    acceptanceTemplateId !== project.settings.acceptance_template_id ||
    rejectionTemplateId !== project.settings.rejection_template_id;

  const currentAcceptanceTemplate = acceptanceTemplates.find(
    (t) => t.id === acceptanceTemplateId,
  );
  const currentRejectionTemplate = rejectionTemplates.find(
    (t) => t.id === rejectionTemplateId,
  );

  return (
    <div>
      <Label size={SizesEnum.LARGE}>Messaging automation</Label>
      <div className="flex flex-col space-y-3">
        <div className="text-sm text-gray-600">
          Text or email candidates automatically when they are advanced or
          rejected. Manage your message templates in{" "}
          <span
            className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
            onClick={() => navigate("/settings/message-templates")}
          >
            settings
          </span>
          .
        </div>
        <MessageTemplateField
          label="Advancement message"
          template={currentAcceptanceTemplate}
          templates={acceptanceTemplates}
          onChange={(template) => setAcceptanceTemplateId(template?.id ?? null)}
        />
        <MessageTemplateField
          label="Rejection message"
          template={currentRejectionTemplate}
          templates={rejectionTemplates}
          onChange={(template) => setRejectionTemplateId(template?.id ?? null)}
        />
        {hasChanges && (
          <SaveAndCancelButtons
            onSave={handleSave}
            onCancel={handleCancel}
            isSaving={isSaving}
            isSaveDisabled={!hasChanges}
          />
        )}
      </div>
    </div>
  );
};
