export enum AssessmentEnum {
  Excellent = "Excellent",
  Good = "Good",
  Acceptable = "Acceptable",
  Poor = "Poor",
  VeryPoor = "Very Poor",
}

export const assessmentScoreToLabelText = {
  5: AssessmentEnum.Excellent,
  4: AssessmentEnum.Good,
  3: AssessmentEnum.Acceptable,
  2: AssessmentEnum.Poor,
  1: AssessmentEnum.VeryPoor,
};
