import { useId, useState } from "react";

const CUSTOM_MIME = "application/vnd.alpharun.drag-item";

interface UseDraggableListProps<T> {
  items: T[];
  onReorder: (newItems: T[]) => void;
}

interface DragHandlers {
  handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  handleDragLeave: () => void;
  dragOverIndex: number | null;
}

export function useDraggableList<T>({
  items,
  onReorder,
}: UseDraggableListProps<T>): DragHandlers {
  const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
  const listId = useId();

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    const index = Number(e.currentTarget.dataset.itemIndex);
    e.dataTransfer.setData(CUSTOM_MIME, JSON.stringify({ index, listId }));
    e.currentTarget.classList.add("opacity-50");
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move";
    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const dropIndex = Number(e.currentTarget.dataset.itemIndex);

    // If we're on the bottom half of the item, we'll drop after it
    if (y > rect.height / 2) {
      setDragOverIndex(dropIndex + 1);
    } else {
      setDragOverIndex(dropIndex);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData(CUSTOM_MIME));
    const { index: dragIndex, listId: sourceListId } = data;

    // Only allow drops within the same list
    if (sourceListId !== listId) return;

    if (dragOverIndex === null || dragIndex === dragOverIndex) return;

    const newItems = [...items];
    const [removed] = newItems.splice(dragIndex, 1);

    // When dropping after the drag position, decrement the drop index by 1 since removing the dragged item shifts all subsequent indices down
    const adjustedDropIndex =
      dragOverIndex > dragIndex ? dragOverIndex - 1 : dragOverIndex;

    newItems.splice(adjustedDropIndex, 0, removed);

    onReorder(newItems);
    setDragOverIndex(null);
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove("opacity-50");
    setDragOverIndex(null);
  };

  const handleDragLeave = () => {
    setDragOverIndex(null);
  };

  return {
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    handleDragLeave,
    dragOverIndex,
  };
}
