import { LoadingStatesEnum } from "app-types";
import { FC, useEffect, useState } from "react";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";

export const WebhooksSettingsSection: FC = () => {
  const [portalUrl, setPortalUrl] = useState<string | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingStatesEnum>(
    LoadingStatesEnum.LOADING,
  );

  const fetchWebhookPortalUrl = async () => {
    setLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.get(`/company/webhooks-session`);
      setPortalUrl(data.url);
      setLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  useEffect(() => {
    fetchWebhookPortalUrl();
  }, []);

  if (loadingState === LoadingStatesEnum.LOADING) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  if (loadingState === LoadingStatesEnum.ERROR) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="text-red-500">
          Failed to load webhook portal. Please try again later.
        </div>
      </div>
    );
  }

  if (!portalUrl) {
    return null;
  }

  return (
    <div className="h-full w-full">
      <iframe
        src={portalUrl}
        className="w-full h-full border-0"
        title="Webhook Portal"
      />
    </div>
  );
};
