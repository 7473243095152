import { configureStore } from "@reduxjs/toolkit";
import { api } from "../api/redux";
import accountsReducer from "../features/accounts/accountsSlice";
import activitiesReducer from "../features/activities/activitiesSlice";
import assessmentsReducer from "../features/assessments/assessmentsSlice";
import billingReducer from "../features/billing/billingSlice";
import companyDomainsReducer from "../features/company/companyDomainsSlice";
import companyReducer from "../features/company/companySlice";
import contactsReducer from "../features/contacts/contactsSlice";
import customFieldDefinitionsReducer from "../features/customFieldDefinitions/customFieldDefinitionsSlice";
import insightsReducer from "../features/insights/insightsSlice";
import integrationsReducer from "../features/integrations/integrationsSlice";
import interviewsReducer from "../features/interviews/interviewsSlice";
import keywordTrackersReducer from "../features/keywordTrackers/keywordTrackersSlice";
import notificationsReducer from "../features/notificationsOverlay/notificationsSlice";
import phoneNumbersReducer from "../features/phoneNumbers/phoneNumbersSlice";
import projectsReducer from "../features/projects/projectsSlice";
import teammateReducer from "../features/teammate/teammateSlice";
import teammatesReducer from "../features/teammates/teammatesSlice";
import transcriptFragmentsReducer from "../features/transcriptFragments/transcriptFragmentsSlice";

const store = configureStore({
  reducer: {
    teammate: teammateReducer,
    company: companyReducer,
    teammates: teammatesReducer,
    projects: projectsReducer,
    notifications: notificationsReducer,
    interviews: interviewsReducer,
    contacts: contactsReducer,
    accounts: accountsReducer,
    transcriptFragments: transcriptFragmentsReducer,
    insights: insightsReducer,
    activities: activitiesReducer,
    keywordTrackers: keywordTrackersReducer,
    integrations: integrationsReducer,
    customFieldDefinitions: customFieldDefinitionsReducer,
    companyDomains: companyDomainsReducer,
    billing: billingReducer,
    phoneNumbers: phoneNumbersReducer,
    assessments: assessmentsReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
