import { ArrowPathIcon, PauseCircleIcon } from "@heroicons/react/24/outline";
import {
  CustomFieldResourceTypesEnum,
  getCompanyHasFeatureAccess,
  isSalesforceIntegrationSettings,
} from "app-types";
import { FC } from "react";
import {
  Button,
  ButtonVariantsEnum,
  InfoBanner,
  Label,
  Pill,
  PillColorsEnum,
  SizesEnum,
} from "ui";
import { openOAuthSigninPopupForIntegration } from "../../../helpers/urlHelpers";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { UpsellBanner } from "../../billing/upsellBanner";
import { selectCompany } from "../../company/companySlice";
import {
  selectCustomFieldDefinitionsByProvider,
  setCustomFieldDefinitionsForProvider,
} from "../../customFieldDefinitions/customFieldDefinitionsSlice";
import {
  selectSalesforceIntegration,
  updateIntegration,
} from "../../integrations/integrationsSlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { IntegrationFieldPicker } from "./integrationFieldPicker";
import { OAuthIntegrationButtonsRow } from "./oauthIntegrationCtas";

export const CRMIntegrationsSection: FC = () => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);
  const salesforceIntegration = useAppSelector(selectSalesforceIntegration);
  const salesforceCustomFieldDefinitions = useAppSelector(
    selectCustomFieldDefinitionsByProvider("salesforce"),
  );

  const accountCustomFieldDefinitions = salesforceCustomFieldDefinitions.filter(
    (cfd) => cfd.resource_type === CustomFieldResourceTypesEnum.ACCOUNT,
  );
  const contactCustomFieldDefinitions = salesforceCustomFieldDefinitions.filter(
    (cfd) => cfd.resource_type === CustomFieldResourceTypesEnum.CONTACT,
  );

  // TODO: If we're authenticated, allow the user to edit settings + disconnect their account

  const onToggleEnabledStatus = (isEnabled: boolean) => {
    if (!salesforceIntegration) return;

    dispatch(
      updateIntegration({
        id: salesforceIntegration.id,
        changes: {
          settings: {
            ...salesforceIntegration.settings,
            is_enabled: isEnabled,
          },
        },
      }),
    );

    showNotification(dispatch, {
      id: `integration-toggle-${new Date().getTime()}`,
      primaryMessage: `Salesforce sync ${isEnabled ? "enabled" : "paused"}`,
      type: NotificationTypeEnum.SUCCESS,
    });
  };

  const renderContent = () => {
    if (
      !company ||
      !getCompanyHasFeatureAccess(company, "salesforce_integration")
    )
      return <UpsellBanner planFeatureName="salesforce_integration" />;

    if (!salesforceIntegration)
      return (
        <Button
          label="Login with Salesforce"
          onClick={() => openOAuthSigninPopupForIntegration("salesforce")}
          variant={ButtonVariantsEnum.Primary}
        />
      );

    return (
      <div className="flex flex-col space-y-5">
        <OAuthIntegrationButtonsRow integration={salesforceIntegration} />
        <div>
          <Label size={SizesEnum.SMALL}>Sync status</Label>
          <div className="mt-2 flex flex-row space-x-2">
            {isSalesforceIntegrationSettings(salesforceIntegration.settings) &&
            salesforceIntegration.settings.is_enabled ? (
              <>
                <Pill
                  label="Active"
                  size={SizesEnum.MEDIUM}
                  color={PillColorsEnum.GREEN}
                />
                <Button
                  variant={ButtonVariantsEnum.Secondary}
                  onClick={() => {
                    onToggleEnabledStatus(false);
                  }}
                  label="Pause Salesforce sync"
                  icon={<PauseCircleIcon className="h-4 w-4 mr-2" />}
                />
              </>
            ) : (
              <>
                <Pill
                  label="Inactive"
                  size={SizesEnum.MEDIUM}
                  color={PillColorsEnum.BLUE}
                />
                <Button
                  variant={ButtonVariantsEnum.Primary}
                  onClick={() => {
                    onToggleEnabledStatus(true);
                  }}
                  label="Enable Salesforce sync"
                  icon={<ArrowPathIcon className="h-4 w-4 mr-2" />}
                />
              </>
            )}
          </div>
        </div>
        <IntegrationFieldPicker
          resourceType={CustomFieldResourceTypesEnum.ACCOUNT}
          integrationId={salesforceIntegration.id}
          selectedCustomFieldDefinitions={accountCustomFieldDefinitions}
          onSave={(newCustomFieldMetadatas) => {
            dispatch(
              setCustomFieldDefinitionsForProvider({
                provider: "salesforce",
                customFieldMetadatas: newCustomFieldMetadatas.concat(
                  contactCustomFieldDefinitions,
                ),
              }),
            );
          }}
        />
        <IntegrationFieldPicker
          resourceType={CustomFieldResourceTypesEnum.CONTACT}
          integrationId={salesforceIntegration.id}
          selectedCustomFieldDefinitions={contactCustomFieldDefinitions}
          onSave={(newCustomFieldMetadatas) => {
            dispatch(
              setCustomFieldDefinitionsForProvider({
                provider: "salesforce",
                customFieldMetadatas: newCustomFieldMetadatas.concat(
                  accountCustomFieldDefinitions,
                ),
              }),
            );
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="mb-4">
        <Label size={SizesEnum.MEDIUM}>Salesforce</Label>
      </div>
      <div className="mb-4">
        <InfoBanner
          title={"How the Salesforce integration works"}
          description={
            <ul className="list-disc ml-4">
              <li className={`mb-2`}>
                {
                  "Contact and account data is synced one-way from Salesforce to Alpharun and then displayed alongside your interview transcripts"
                }
              </li>
              <li className={`mb-2`}>
                {
                  "The email of the contact in Alpharun is used to find a matching contact and account in Salesforce"
                }
              </li>
            </ul>
          }
        />
      </div>

      {renderContent()}
    </div>
  );
};
