import { isValidDomain, LoadingStatesEnum } from "app-types";
import axios from "axios";
import { FC, useEffect, useState } from "react";
import { Button, ButtonVariantsEnum, DefaultSkeleton, Input, Link } from "ui";
import { supabase } from "../../api/supabaseService";
import { imageLinks } from "../../helpers/imageLinks";

export const POPUP_AUTH_CALLBACK_QUERY_PARAM_NAME = "popupAuthCallback";

export const SALES_DEMO_LINK =
  "https://cal.frontapp.com/alpharun/paul/41a63af7";

interface SignInPageProps {
  isAcceptingInvitation: boolean;
}

enum InstructionVariantsEnum {
  ACCEPTING_INVITATION = "accepting_invitation",
  STANDARD = "standard",
  INSIGHTS_WITH_DOMAIN_LOGIN = "insights_with_domain_login",
  INSIGHTS_WITHOUT_DOMAIN_LOGIN = "insights_without_domain_login",
}

export const SignInPage: FC<SignInPageProps> = ({ isAcceptingInvitation }) => {
  const [instructionVariant, setInstructionVariant] =
    useState<InstructionVariantsEnum>(
      isAcceptingInvitation
        ? InstructionVariantsEnum.ACCEPTING_INVITATION
        : InstructionVariantsEnum.STANDARD,
    );
  const [loginInstructionLoadingState, setLoginInstructionLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADING);
  const [isUsingSSO, setIsUsingSSO] = useState(false);
  const [ssoDomain, setSSoDomain] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const queryParams = new URLSearchParams(window.location.search);
  const interviewContactName = queryParams.get("n");
  const shouldUsePopupAuth = queryParams.get("int") === "fountain";

  const fetchLoginInstructions = async (projectId: string) => {
    try {
      const { data } = await axios.get(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/domain-login-status?project_id=${projectId}`,
      );

      setInstructionVariant(
        data.is_domain_login_available
          ? InstructionVariantsEnum.INSIGHTS_WITH_DOMAIN_LOGIN
          : InstructionVariantsEnum.INSIGHTS_WITHOUT_DOMAIN_LOGIN,
      );
      setLoginInstructionLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      // If login instruction check fails, the standard login UI be used.
      setLoginInstructionLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  // Handles receiving the auth code from the auth popup used for embedded iframe auth (i.e. Fountain).
  function handleAuthPopupPostMessage(event: MessageEvent): void {
    // Confirm valid origin
    const allowedOrigin = window.location.origin;
    if (event.origin !== allowedOrigin) {
      console.warn(
        `Rejected message from unauthorized origin: ${event.origin}`,
      );
      setErrorMessage("Authentication failed - please try again.");
      return;
    }

    if (
      event.data &&
      "code" in event.data &&
      typeof (event.data as { code: string }).code === "string"
    ) {
      // Exchange the code for a session
      void supabase.auth
        .exchangeCodeForSession((event.data as { code: string }).code)
        .then(({ data: { session }, error }) => {
          if (error) {
            console.error("Error exchanging code for session:", error);
            setErrorMessage(
              "Authentication failed - invalid session code. Please try again.",
            );
          } else if (session) {
            void supabase.auth.setSession(session);
          }
        });
    } else {
      setErrorMessage("Authentication failed: Invalid response");
    }
  }

  useEffect(() => {
    // Try to get the project ID from the URL of a project or interview page.
    const projectId = extractProjectIdFromProjectOrInterviewPage();

    if (shouldUsePopupAuth) {
      window.addEventListener("message", handleAuthPopupPostMessage);
    }

    // Only check for domain login possibility if we are on a project or interview page
    // (i.e. the user is trying to access a link to insights)
    if (projectId) {
      fetchLoginInstructions(projectId);
    } else {
      setLoginInstructionLoadingState(LoadingStatesEnum.LOADED);
    }

    return () => {
      window.removeEventListener("message", handleAuthPopupPostMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- query params won't change so no need to include shouldUsePopupAuth.
  }, []);

  const insightsActionText = interviewContactName
    ? `view the full interview with ${interviewContactName}.`
    : "view interview insights.";
  const computeSubtitle = () => {
    switch (instructionVariant) {
      case InstructionVariantsEnum.ACCEPTING_INVITATION:
        return "You've been invited to join your team on Alpharun. Sign in to continue.";
      case InstructionVariantsEnum.INSIGHTS_WITH_DOMAIN_LOGIN:
        return `Sign in to ${insightsActionText}`;
      case InstructionVariantsEnum.INSIGHTS_WITHOUT_DOMAIN_LOGIN:
        return `Please sign in to ${insightsActionText}`;
      default:
        return "Please sign in to continue.";
    }
  };

  const maybeRenderBottomMessage = () => {
    if (isUsingSSO) return null;

    switch (instructionVariant) {
      case InstructionVariantsEnum.ACCEPTING_INVITATION:
      case InstructionVariantsEnum.INSIGHTS_WITH_DOMAIN_LOGIN:
        return (
          <div className="mt-4 text-center text-sm text-gray-600">
            Don't have an account yet? Sign in with your work email to join your
            team. Accounts for teammates are free.
          </div>
        );
      case InstructionVariantsEnum.INSIGHTS_WITHOUT_DOMAIN_LOGIN:
        return (
          <div className="mt-4 text-center text-sm text-gray-600">
            Don't have an account yet? Request one from your Alpharun admin.
            Accounts for teammates are free.
          </div>
        );
      default:
        return (
          <p className="mt-4 text-center text-sm text-gray-600">
            {"Don't have an account yet? "}
            <a
              href={SALES_DEMO_LINK}
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              Book a demo.
            </a>
          </p>
        );
    }
  };

  const handleOAuthSignIn = async (provider: "google" | "azure") => {
    const redirectTo = new URL(window.location.href.split("#")[0]); // Remove # from the URL
    // If we are doing popup auth, include a flag in the callback URL so the popup knows to send the code back to the parent window
    if (shouldUsePopupAuth) {
      redirectTo.searchParams.set(POPUP_AUTH_CALLBACK_QUERY_PARAM_NAME, "true");
    }

    const { data, error } = await supabase.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: redirectTo.toString(),
        queryParams: {
          // Ensures that user is always able to choose the account they want to log in with
          prompt: "select_account",
        },
        // Don't redirect to OAuth signin page if we are using popup auth (we need to open it in a popup instead)
        skipBrowserRedirect: shouldUsePopupAuth,
        ...(provider === "azure" && { scopes: "email" }),
      },
    });

    if (error) {
      console.error(error);
      return;
    }

    // If embedded and we have a URL, open in popup window
    if (shouldUsePopupAuth && data.url) {
      const width = 600;
      const height = 700;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      window.open(
        data.url,
        "Auth Popup",
        `width=${String(width)},height=${String(height)},left=${String(left)},top=${String(top)},popup=1`,
      );
    }
  };

  const renderSigninOptions = () => {
    if (isUsingSSO) {
      return (
        <div className="w-full">
          <Input
            label="Company domain"
            placeholder="company.com"
            value={ssoDomain}
            onChange={(e) => {
              setSSoDomain(e.target.value);
              setErrorMessage("");
            }}
          />
          {errorMessage ? (
            <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
          ) : null}
          <Button
            className="mt-4 mx-auto"
            onClick={async () => {
              try {
                const { data, error } = await supabase.auth.signInWithSSO({
                  domain: ssoDomain,
                });

                if (error) {
                  if (error.message.includes("No SSO provider assigned")) {
                    setErrorMessage(
                      "No SSO provider found for this domain. Please try again.",
                    );
                    return;
                  }
                  throw error;
                }

                if (data.url) {
                  // redirect the user to the identity provider's authentication flow
                  window.location.href = data.url;
                } else {
                  setErrorMessage("No SSO URL returned. Please try again.");
                }
              } catch (error) {
                console.log("SSO sign-in error:", error);
                setErrorMessage(
                  "An error occurred during SSO sign-in. Please try again.",
                );
              }
            }}
            variant={ButtonVariantsEnum.Primary}
            isDisabled={!isValidDomain(ssoDomain)}
          >
            Sign in with SSO
          </Button>
        </div>
      );
    }

    return (
      <>
        <Button
          className="w-52 justify-center grid grid-cols-[auto,1fr]"
          onClick={() => void handleOAuthSignIn("google")}
          variant={ButtonVariantsEnum.Secondary}
        >
          <svg
            className="w-5 h-5 mr-2" // Adjust the size as needed
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Google Logo</title>
            <clipPath id="g">
              <path d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z" />
            </clipPath>
            <g clipPath="url(#g)">
              <path fill="#FBBC05" d="M0 37V11l17 13z" />
              <path fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
              <path fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
              <path fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
            </g>
          </svg>
          <span>
            {instructionVariant === InstructionVariantsEnum.ACCEPTING_INVITATION
              ? "Sign up with Google"
              : "Sign in with Google"}
          </span>
        </Button>

        <Button
          className="w-52 justify-center grid grid-cols-[auto,1fr]"
          onClick={() => void handleOAuthSignIn("azure")}
          variant={ButtonVariantsEnum.Secondary}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 mr-2"
            viewBox="0 0 23 23"
          >
            <path fill="#f35325" d="M0 0h10v10H0z" />
            <path fill="#81bc06" d="M11 0h10v10H11z" />
            <path fill="#05a6f0" d="M0 11h10v10H0z" />
            <path fill="#ffba08" d="M11 11h10v10H11z" />
          </svg>
          <span>
            {instructionVariant === InstructionVariantsEnum.ACCEPTING_INVITATION
              ? "Sign up with Microsoft"
              : "Sign in with Microsoft"}
          </span>
        </Button>

        <div>
          <p className="mt-4 text-center text-sm text-gray-600">
            {"Using SSO? "}
            <button
              className="font-medium text-blue-600 hover:text-blue-500"
              onClick={() => {
                setIsUsingSSO(true);
              }}
            >
              Sign in with SSO.
            </button>
          </p>
        </div>
      </>
    );
  };

  return (
    <div className="flex min-h-screen bg-gray-50 items-center justify-center px-4 sm:px-6 lg:px-8">
      <img
        src={imageLinks.LOGO_BLUE}
        alt="Alpharun"
        className="mx-auto h-4 fixed top-8"
      />
      <div className="w-full max-w-md">
        <div className="bg-white py-10 px-6 shadow rounded-lg sm:rounded-lg">
          {loginInstructionLoadingState === LoadingStatesEnum.LOADING ? (
            <DefaultSkeleton />
          ) : (
            <>
              <h2 className="mb-2 text-center text-2xl font-semibold text-gray-900">
                {instructionVariant ===
                InstructionVariantsEnum.ACCEPTING_INVITATION
                  ? "Join your team on Alpharun"
                  : "Welcome"}
              </h2>
              <div className="mb-4 text-center text-sm text-gray-600">
                {computeSubtitle()}
              </div>
              <div className="flex flex-col items-center space-y-4">
                {renderSigninOptions()}
              </div>
              {maybeRenderBottomMessage()}
              {!isUsingSSO && errorMessage ? (
                <p className="mt-4 text-center text-sm text-red-600">
                  {errorMessage}
                </p>
              ) : null}
            </>
          )}
        </div>
      </div>
      <div className="absolute bottom-3 w-full px-4">
        <p className="text-center text-xs text-gray-500">
          By signing in, you agree to Alpharun's{" "}
          <Link href="https://assets.alpharun.com/terms_of_service.pdf">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link href="https://assets.alpharun.com/privacy_policy.pdf">
            Privacy Policy
          </Link>
          .
        </p>
      </div>
    </div>
  );
};

function extractProjectIdFromProjectOrInterviewPage() {
  // Regular expression for a UUID
  const uuidRegex =
    "[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";

  // Regular expression for the URL path
  // It matches either /project/{UUID} or /project/{UUID}/interviews/{UUID}
  // and captures the first UUID
  const urlPathRegex = new RegExp(
    `^/project/(${uuidRegex})(?:/interviews/${uuidRegex})?$`,
  );

  const match = urlPathRegex.exec(window.location.pathname);
  return match ? match[1] : null;
}
