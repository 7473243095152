import {
  ArrowUpOnSquareStackIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  BasicQuestion,
  Company,
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  getErrorMessageForAssessmentCriteriaValue,
  getHaveAssessmentFieldsChanged,
  Project,
  ProjectUpdate,
  sortAssessmentFields,
} from "app-types";
import { FC, useEffect, useRef, useState } from "react";
import { Button, ButtonVariantsEnum, Label, SizesEnum, Slideover } from "ui";
import { DraggableItem } from "ui/src/DraggableList/draggableItem";
import { useConfirmationModal } from "ui/src/hooks/useConfirmationModal";
import { useDraggableList } from "ui/src/hooks/useDraggableList";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { AssessmentEditor } from "../../assessments/assessmentEditor";
import {
  selectAssessmentById,
  updateAssessment,
} from "../../assessments/assessmentsSlice";
import { RecruitingQuestionsManagerCell } from "../recruitingInterviewQuestions/recruitingQuestionsManagerCell";
import { ImportQuestionsModal } from "./importQuestionsModal";

const MAX_INTERVIEW_QUESTIONS = 25;

interface QuestionWithTemporaryId extends BasicQuestion {
  id: string;
}

/**
 * For stable react keys, we append random IDs to questions
 */
const appendRandomIDToQuestions = (
  questions: BasicQuestion[],
): QuestionWithTemporaryId[] => {
  return questions.map((q) => ({
    ...q,
    id: crypto.randomUUID(),
  }));
};

const getEmptyQuestion = (): QuestionWithTemporaryId => ({
  question: "",
  id: crypto.randomUUID(),
});

interface RecruitingQuestionsManagerSlideoverProps {
  basicQuestions: BasicQuestion[];
  project: Project;
  company: Company;
  onSaveProject: (changes: ProjectUpdate) => void;
  isOpen: boolean;
  onClose: () => void;
}

export const RecruitingQuestionsManagerSlideover: FC<
  RecruitingQuestionsManagerSlideoverProps
> = (props) => {
  const { project, onSaveProject, isOpen, onClose } = props;
  const dispatch = useAppDispatch();

  const assessment = useAppSelector(
    selectAssessmentById(project.assessment_id),
  );

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  // State for managing basic questions
  const [questions, setQuestions] = useState<QuestionWithTemporaryId[]>(() =>
    props.basicQuestions.length > 0
      ? appendRandomIDToQuestions(props.basicQuestions)
      : [getEmptyQuestion()],
  );
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const questionsContainerRef = useRef<HTMLDivElement>(null);

  // State for managing assessment criteria
  const sortedOriginalFields = sortAssessmentFields(
    assessment?.custom_field_definitions || [],
  );
  const [assessmentFields, setAssessmentFields] =
    useState<(CustomFieldDefinition | CustomFieldDefinitionInsertClient)[]>(
      sortedOriginalFields,
    );
  const [assessmentFieldErrors, setAssessmentFieldErrors] = useState<string[]>(
    [],
  );

  const { renderConfirmationModal, onConfirmThenClose } = useConfirmationModal({
    needsConfirmation: hasMadeChanges,
    onClose,
  });

  const {
    handleDragStart,
    handleDragOver,
    handleDrop,
    handleDragEnd,
    handleDragLeave,
    dragOverIndex,
  } = useDraggableList({
    items: questions,
    onReorder: (newQuestions) => {
      setQuestions(newQuestions);
      setHasMadeChanges(true);
    },
  });

  // Reset state when slideover is opened/closed
  useEffect(() => {
    setQuestions(appendRandomIDToQuestions(props.basicQuestions));
    setAssessmentFields(sortedOriginalFields);
    setAssessmentFieldErrors([]);
    setHasMadeChanges(false);
  }, [isOpen]);

  const validateAssessmentFields = (
    fieldsToValidate: (
      | CustomFieldDefinition
      | CustomFieldDefinitionInsertClient
    )[],
  ) => {
    const newErrors = fieldsToValidate.map((field) =>
      getErrorMessageForAssessmentCriteriaValue(field.display_name),
    );
    setAssessmentFieldErrors(newErrors);
    return newErrors.every((error) => error === "");
  };

  const onSave = () => {
    const nonEmptyFields = assessmentFields.filter(
      (field) => field.display_name.trim() !== "",
    );

    if (validateAssessmentFields(nonEmptyFields)) {
      const shouldUpdateAssessment =
        assessment &&
        getHaveAssessmentFieldsChanged(sortedOriginalFields, nonEmptyFields);

      if (shouldUpdateAssessment) {
        dispatch(
          updateAssessment({
            assessmentId: assessment.id,
            changes: {
              custom_field_definitions: nonEmptyFields,
            },
          }),
        );
      }

      setHasMadeChanges(false);

      onSaveProject({
        questions: questions
          .filter((q) => q.question)
          .map((q) => ({
            question: q.question,
          })), // Filter out empty questions and remove the temporary IDs
      });
      onClose();
    }
  };

  return (
    <>
      {renderConfirmationModal({
        message: "Your changes will be discarded.",
      })}
      <Slideover
        buttons={
          <>
            <Button
              isDisabled={!hasMadeChanges}
              label="Save changes"
              onClick={() => {
                onSave();
              }}
              size={SizesEnum.MEDIUM}
              variant={ButtonVariantsEnum.Primary}
            />
            <Button
              label="Discard changes"
              onClick={onClose}
              size={SizesEnum.MEDIUM}
              variant={ButtonVariantsEnum.Secondary}
            />
          </>
        }
        onClickClose={onConfirmThenClose}
        shouldShow={isOpen}
        title="Interview Editor"
      >
        <div className="flex h-full bg-gray-50 flex-row">
          {assessment ? (
            <div className="w-[400px] border-r border-gray-200 flex flex-col">
              <div className="px-3 py-2 flex flex-col space-between border-b border-gray-200 bg-white">
                <Label size={SizesEnum.MEDIUM}>Assessment Criteria</Label>
                <div className="text-gray-600 text-xs">
                  Interviews are automatically scored based on these criteria to
                  help identify qualified candidates.
                </div>
              </div>
              <div className="overflow-y-auto flex-1">
                <AssessmentEditor
                  fieldErrors={assessmentFieldErrors}
                  fields={assessmentFields}
                  interviewLanguage={project.settings.interview_language}
                  setFieldErrors={setAssessmentFieldErrors}
                  setFields={(fields) => {
                    setHasMadeChanges(true);
                    setAssessmentFields(fields);
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className="flex-1 flex flex-col">
            <div className="px-3 py-2 flex flex-col space-between border-b border-gray-200 bg-white">
              <div className="flex flex-row items-center">
                <Label size={SizesEnum.MEDIUM}>Interview Questions</Label>
              </div>
              <div className="text-gray-600 text-xs">
                The AI interviewer will automatically ask clarifying questions
                and adapt to the candidate's responses.
              </div>
            </div>
            <div className="overflow-y-auto flex-1" ref={questionsContainerRef}>
              <div>
                {questions.map((question, index) => (
                  <DraggableItem
                    dragOverIndex={dragOverIndex}
                    index={index}
                    isDraggable
                    key={question.id}
                    onDragEnd={handleDragEnd}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDragStart={handleDragStart}
                    onDrop={handleDrop}
                  >
                    <RecruitingQuestionsManagerCell
                      onChange={(question) => {
                        setQuestions(
                          questions.map((q, i) =>
                            i === index ? { ...question, id: q.id } : q,
                          ),
                        );
                        setHasMadeChanges(true);
                      }}
                      onEnterKeyDown={() => {
                        // Add a new question below this question
                        setQuestions((previousQuestions) => {
                          const newQuestions = [...previousQuestions];
                          newQuestions.splice(index + 1, 0, getEmptyQuestion());
                          return newQuestions;
                        });
                        setHasMadeChanges(true);
                      }}
                      onRemove={() => {
                        setQuestions(questions.filter((_, i) => i !== index));
                        setHasMadeChanges(true);
                      }}
                      question={question}
                      questionNumber={index + 1}
                    />
                  </DraggableItem>
                ))}

                <div className="m-3 flex flex-row justify-between space-x-3">
                  <Button
                    icon={<PlusIcon className="w-4 h-4 mr-2" />}
                    isDisabled={questions.length >= MAX_INTERVIEW_QUESTIONS}
                    label="Add question"
                    onClick={() => {
                      setQuestions((previousQuestions) => {
                        const newQuestions = [
                          ...previousQuestions,
                          getEmptyQuestion(),
                        ];
                        return newQuestions;
                      });
                    }}
                    size={SizesEnum.MEDIUM}
                    variant={ButtonVariantsEnum.Secondary}
                  />
                  <Button
                    icon={<ArrowUpOnSquareStackIcon className="w-4 h-4 mr-2" />}
                    label="Bulk import"
                    onClick={() => {
                      setIsImportModalOpen(true);
                    }}
                    size={SizesEnum.MEDIUM}
                    variant={ButtonVariantsEnum.Secondary}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImportQuestionsModal
          isOpen={isImportModalOpen}
          onClose={() => {
            setIsImportModalOpen(false);
          }}
          onImport={(questions: BasicQuestion[]) => {
            setQuestions(appendRandomIDToQuestions(questions));
            setHasMadeChanges(true);
            setIsImportModalOpen(false);
          }}
          project={project}
        />
      </Slideover>
    </>
  );
};
