import {
  type JobTypeSettings,
  type TablesInsertWithFixedTypes,
  type TablesUpdateWithFixedTypes,
  type TablesWithFixedTypes,
} from "../..";
import { InterviewLanguagesEnum } from "../constants/languages";

export type JobTypeDB = TablesWithFixedTypes<"job_type">;
export type JobTypeInsert = TablesInsertWithFixedTypes<"job_type">;
export type JobTypeUpdate = TablesUpdateWithFixedTypes<"job_type">;

export const JobTypeSettingsDefault: JobTypeSettings = {
  interview_languages: [InterviewLanguagesEnum.ENGLISH],
  resume_collection_type: null,
  should_force_fullscreen: false,
  should_record_video: false,
  auto_advance_assessment_score_threshold: null,
};

export const HAS_JOB_TYPE_COMPANY_IDS = [
  "19aac94b-24dc-42ac-bfcf-44c275493736",
];
