import { ProjectDetailsTabPathsEnum, buildProjectUrlPath } from "app-types";
import { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  clearFocusedInterview,
  fetchFocusedInterview,
  selectFocusedInterviewAndContact,
} from "../interviewsSlice";
import { InterviewSlideover } from "./interviewSlideover";

export const InterviewSlideoverContainer: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // project_id is always in the URL but interview_id can be route param (/interview/abc) or query param (?interview_id=abc)
  const { interview_id: interviewIdRouteParam, project_id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const interviewIdQueryParam = queryParams.get("interview_id");
  const interview_id = interviewIdRouteParam || interviewIdQueryParam;

  const focusedInterviewAndContact = useAppSelector(
    selectFocusedInterviewAndContact,
  );
  const { interview, contact } = focusedInterviewAndContact || {};

  useEffect(() => {
    if (interview_id && project_id)
      dispatch(
        fetchFocusedInterview({
          projectId: project_id,
          interviewId: interview_id,
        }),
      );
    else if (!interview_id) dispatch(clearFocusedInterview());
  }, [interview_id, project_id]);

  const onClickClose = () => {
    dispatch(clearFocusedInterview());

    // Update the URL by removing interview_id query or route param
    if (interviewIdQueryParam) {
      // Insights tab case: url like /project/abc/insights?=interview_id
      navigate(
        // Remove the interview_id query param from the current location
        {
          search: "",
        },
        {
          replace: true,
        },
      );
    } else if (project_id) {
      // Interviews tab case: URL like: /project/abc/interviews/def
      navigate(
        buildProjectUrlPath(project_id, ProjectDetailsTabPathsEnum.Interviews),
      );
    }
  };

  return (
    <InterviewSlideover
      interview={interview}
      contact={contact}
      onClickClose={onClickClose}
    />
  );
};
