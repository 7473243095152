import { LockClosedIcon } from "@heroicons/react/24/outline";
import {
  Company,
  HandlerOf,
  Project,
  ProjectModesEnum,
  ProjectUpdate,
  convertToValidHttpsUrl,
  getCompanyHasFeatureAccess,
} from "app-types";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Label, SizesEnum } from "ui";
import { UpgradeText } from "../../billing/upgradeText";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";
import { WelcomeAndOutroMessagesEditor } from "./welcomeAndOutroMessagesEditor";

interface AppearanceSettingsSectionProps {
  onSaveProject: HandlerOf<ProjectUpdate>;
  company: Company;
  project: Project;
}

export const AppearanceSettingsSection: FC<AppearanceSettingsSectionProps> = ({
  onSaveProject,
  company,
  project,
}) => {
  const navigate = useNavigate();
  const isVoiceModeProject = project.mode === ProjectModesEnum.VOICE_AGENT;

  const [redirectUrl, setRedirectUrl] = useState(
    project.settings.redirect_url ?? "",
  );
  const [redirectUrlError, setRedirectUrlError] = useState("");

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling",
  );

  const onClickSaveRedirectUrl = () => {
    setRedirectUrlError("");

    if (!redirectUrl) {
      onSaveProject({
        id: project.id,
        settings: {
          ...project.settings,
          redirect_url: null,
        },
      });
      return;
    }

    const url = convertToValidHttpsUrl(redirectUrl);

    if (!url) {
      setRedirectUrlError(
        "Invalid URL - please provide a valid URL starting with https://",
      );
      return;
    }

    setRedirectUrl(url);
    onSaveProject({
      id: project.id,
      settings: {
        ...project.settings,
        redirect_url: url,
      },
    });
  };

  return (
    <div>
      <Label size={SizesEnum.LARGE}>
        {isVoiceModeProject ? "Interview Link Settings" : "Appearance"}
      </Label>
      <div className="mt-2 text-sm text-gray-600">
        You can add a custom logo and background color in{" "}
        <span
          className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
          onClick={() => {
            navigate("/settings/organization");
          }}
        >
          organization settings
        </span>
        .
      </div>

      <WelcomeAndOutroMessagesEditor
        project={project}
        onSaveProject={onSaveProject}
      />

      <div className="mt-4">
        <Label size={SizesEnum.SMALL}>Redirect URL</Label>
        <div className="mt-2">
          <Input
            value={redirectUrl}
            onChange={(evt) => {
              setRedirectUrlError("");
              setRedirectUrl(evt.target.value);
            }}
            placeholder="https://example.com"
            errorDescription={redirectUrlError}
            inputClassName="max-w-[300px]"
            leftIcon={
              isMissingFeatureAccess ? (
                <LockClosedIcon className="h-4 w-4 text-gray-700" />
              ) : undefined
            }
            isDisabled={isMissingFeatureAccess}
            maxLength={200}
          />
        </div>
        {redirectUrl !== (project.settings.redirect_url ?? "") ? (
          <SaveAndCancelButtons
            onSave={onClickSaveRedirectUrl}
            onCancel={() => {
              setRedirectUrl(project.settings.redirect_url ?? "");
              setRedirectUrlError("");
            }}
          />
        ) : null}
        <div className="mt-2 text-sm text-gray-600">
          The URL to redirect participants to after an interview is completed.
          {isMissingFeatureAccess ? (
            <UpgradeText planFeatureName="whitelabeling" />
          ) : null}
        </div>
      </div>
    </div>
  );
};
