import { FC, ReactNode } from "react";

interface SettingsTableErrorBodyContentProps {
  colSpan: number;
  children: ReactNode;
}

export const SettingsTableErrorBodyContent: FC<
  SettingsTableErrorBodyContentProps
> = ({ colSpan, children }) => (
  <tr>
    <td
      className="px-6 py-4 text-center text-sm text-red-500"
      colSpan={colSpan}
    >
      {children}
    </td>
  </tr>
);
