import type {
  Assessment,
  CustomField,
  InterviewCallMetadata,
  InterviewDecision,
  InterviewInsights,
  InterviewStatus,
  ProjectPublicAPI,
  TablesInsertWithFixedTypes,
  TablesUpdateWithFixedTypes,
  TablesWithFixedTypes,
} from "app-types";
import type { ComplexAnswerPublicAPI } from "./complexAnswer.ts";
import type { BasicContact, ContactDB } from "./contact.ts";
import type { TranscriptFragmentPublicAPI } from "./transcriptFragment.ts";

export type CompletedInterviewCallMetadata = InterviewCallMetadata & {
  start_time: string;
  end_time: string;
};

export function isCompletedCallMetadata(
  call: InterviewCallMetadata,
): call is CompletedInterviewCallMetadata {
  return Boolean(call.start_time) && Boolean(call.end_time);
}

export type InterviewDB = TablesWithFixedTypes<"interview">;
export type InterviewInsert = TablesInsertWithFixedTypes<"interview">;
export type InterviewUpdate = TablesUpdateWithFixedTypes<"interview">;
export type Interview = InterviewDB;

export interface InterviewLoaded extends Interview {
  custom_fields: CustomField[];
}

export type InterviewWithBasicContact = Omit<Interview, "contact_id"> & {
  contact: BasicContact;
};

interface InterviewInsightsPublicAPI {
  summary: string | null;
  sentiment_score: number | null;
  assessment_score: number | null;
  key_positive_quotes: string[] | null;
  key_negative_quotes: string[] | null;
  strengths: string[] | null;
  weaknesses: string[] | null;
}

export interface AssessmentCriteriaPublicAPI {
  name: string;
  value: string | number | Date | boolean | null | undefined;
  commentary: string | null;
}

export interface InterviewPublicAPI {
  id: string;
  project_id: string;
  project: ProjectPublicAPI;
  status: InterviewStatus;
  contact: BasicContact;
  started_at: string;
  completed_at: string | null;
  transcript_fragments: TranscriptFragmentPublicAPI[];
  survey_answers: ComplexAnswerPublicAPI[];
  insights: InterviewInsightsPublicAPI | null;
  calls: InterviewPublicAPICall[];
  assessment_criteria: AssessmentCriteriaPublicAPI[] | undefined;
  decision: InterviewDecision | null;
}

export interface InterviewPublicAPICall {
  id: string;
  recording_url: string;
  started_at: string;
  ended_at: string;
}

export interface WindowEventMetadata {
  duration: number; // In seconds
  seconds_from_start: number;
  call_id: string;
}

export interface InterviewCheatingRiskSummary {
  num_significant_calls: number;
  long_pauses: WindowEventMetadata[];
  fullscreen_exits: WindowEventMetadata[];
  window_blurs: WindowEventMetadata[];
}

export const EMPTY_INTERVIEW_INSIGHTS: InterviewInsights = {
  summary: null,
  sentiment_score: null,
  assessment_score: null,
  key_positive_quotes: null,
  key_negative_quotes: null,
  strengths: null,
  weaknesses: null,
  unanswered_candidate_questions: null,
};

export const mapInterviewDbToInterview = (
  interviewDb: InterviewDB,
): Interview => {
  const questions = interviewDb.questions ?? [];

  const insights: InterviewInsights = {
    ...EMPTY_INTERVIEW_INSIGHTS,
    ...(typeof interviewDb.insights === "object" ? interviewDb.insights : {}),
  };

  const interview: Interview = {
    ...interviewDb,
    questions,
    decision: interviewDb.decision,
    insights,
  };

  return interview;
};

export const mapInterviewDbToInterviewLoaded = (
  interviewDb: InterviewDB,
  customFields: CustomField[],
) => {
  const interview = mapInterviewDbToInterview(interviewDb);

  const interviewLoaded: InterviewLoaded = {
    ...interview,
    custom_fields: customFields,
  };

  return interviewLoaded;
};

export const mapInterviewAndContactToInterviewWithContact = (
  interview: Interview,
  contact: ContactDB,
): InterviewWithBasicContact => {
  return {
    ...interview,
    contact: {
      email: contact.email,
      phone_number: contact.phone_number ?? null,
      first_name: contact.first_name ?? null,
      last_name: contact.last_name ?? null,
    },
  } as InterviewWithBasicContact;
};

export const mapInterviewInsightsToPublicAPIInsights = (
  insights: InterviewInsights,
) => {
  return {
    summary: insights.summary,
    sentiment_score: insights.sentiment_score,
    assessment_score: insights.assessment_score,
    key_positive_quotes: insights.key_positive_quotes,
    key_negative_quotes: insights.key_negative_quotes,
    strengths: insights.strengths,
    weaknesses: insights.weaknesses,
  } as InterviewInsightsPublicAPI;
};

export const mapInterviewAssessmentFieldsToPublicAPIFormat = (
  assessment: Assessment,
  customFields: CustomField[],
) => {
  // Return an array with an entry for each assessment criteria, even if the custom field is not present
  return assessment.custom_field_definitions.map((definition) => {
    const matchingFieldValue = customFields.find(
      (field) => field.custom_field_definition_id === definition.id,
    );

    return {
      name: definition.display_name,
      value: matchingFieldValue?.data.value,
      commentary: matchingFieldValue?.data.display_value ?? null,
    };
  });
};
