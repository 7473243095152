import { FC, ReactNode } from "react";

export interface SettingsTableSectionHeaderProps {
  children: ReactNode;
}

export const SettingsTableSectionHeader: FC<SettingsTableSectionHeaderProps> = (
  props,
) => (
  <tr>
    <td
      className="px-6 pt-4 pb-2 text-xs font-semibold text-gray-500"
      colSpan={100}
    >
      {props.children}
    </td>
  </tr>
);
