import { XMarkIcon } from "@heroicons/react/24/outline";
import { LoadingStatesEnum, PosthogEventTypesEnum } from "app-types";
import { isAxiosError } from "axios";
import posthog from "posthog-js";
import { FC, useRef, useState } from "react";
import { Button, ButtonVariantsEnum, IconButton, Input } from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { selectIsUsingSSO } from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

const MAX_INVITE_EMAILS = 100;

interface InviteTeammatesProps {
  onInviteTeammates: () => void;
}

export const InviteTeammatesSection: FC<InviteTeammatesProps> = ({
  onInviteTeammates,
}) => {
  const dispatch = useAppDispatch();

  const [inviteEmails, setInviteEmails] = useState<string[]>(["", ""]);
  const [sendInvitationsLoadingState, setSendInvitationsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const isUsingSSO = useAppSelector(selectIsUsingSSO);

  const onClickInvite = async () => {
    const emailsToInvite = inviteEmails
      .map((email) => email.trim())
      .filter((email) => email);

    if (emailsToInvite.length === 0) return;

    setSendInvitationsLoadingState(LoadingStatesEnum.LOADING);

    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.post(
        `/company-invitations/invite-teammates`,
        {
          invite_emails: emailsToInvite,
        },
      );

      const { already_teammates, company_invitations, invalid_emails } = data;

      if (company_invitations.length > 0) {
        showNotification(dispatch, {
          id: `invites-sent-${new Date().getTime()}`,
          primaryMessage: `${company_invitations.length} invite${
            company_invitations.length > 1 ? "s were" : " was"
          } successfully sent`,
          type: NotificationTypeEnum.SUCCESS,
        });

        posthog.capture(PosthogEventTypesEnum.TeammatesInvite, {
          num_teammates_invited: company_invitations.length,
        });
      }

      if (invalid_emails.length > 0) {
        showNotification(dispatch, {
          id: `invites-invalid-emails-${new Date().getTime()}`,
          primaryMessage: `${invalid_emails.length} invalid email${
            invalid_emails.length > 1 ? "s were" : " was"
          } skipped`,
          type: NotificationTypeEnum.FAILURE,
        });
      }

      if (already_teammates.length > 0) {
        showNotification(dispatch, {
          id: `invites-already-teammates-${new Date().getTime()}`,
          primaryMessage: `${already_teammates.length} existing teammate${
            already_teammates.length > 1 ? "s were" : " was"
          } skipped`,
          type: NotificationTypeEnum.FAILURE,
        });
      }

      setInviteEmails([""]);

      // Refetch the invitations
      onInviteTeammates();

      setSendInvitationsLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      // Handle rate limiting
      if (isAxiosError(err) && err.response?.status === 429) {
        showNotification(dispatch, {
          id: `invite-rate-limited-${new Date().getTime()}`,
          primaryMessage: `Max of 1000 invites per day exceeded. Please email support if you need assistance.`,
          type: NotificationTypeEnum.FAILURE,
        });
        setSendInvitationsLoadingState(LoadingStatesEnum.LOADED);
        return;
      }

      setSendInvitationsLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const handleEmailChange =
    (index: number) => (evt: React.ChangeEvent<HTMLInputElement>) => {
      const newEmails = [...inviteEmails];
      newEmails[index] = evt.target.value;
      setInviteEmails(newEmails);
    };

  const handlePaste =
    (index: number) => (event: React.ClipboardEvent<HTMLInputElement>) => {
      event.preventDefault();
      const pastedData = event.clipboardData.getData("Text");
      const emails = pastedData
        .split(/[\n,]+/)
        .map((email) => email.trim())
        .filter((email) => email); // Remove empty strings

      // Calculate the number of elements to keep after the pasted data
      const remainingSpace = 100 - index;
      const numToKeep = remainingSpace - emails.length;

      // Check if the total number exceeds the limit
      if (index + emails.length > 100) {
        showNotification(dispatch, {
          id: `invite-limit-exceeded-${new Date().getTime()}`,
          primaryMessage: `You cannot invite more than 100 people at once.`,
          type: NotificationTypeEnum.FAILURE,
        });
        return;
      }

      setInviteEmails([
        ...inviteEmails.slice(0, index),
        ...emails,
        ...inviteEmails.slice(index + 1, index + 1 + numToKeep),
      ]);
    };

  const addInviteField = (index?: number) => {
    if (inviteEmails.length > MAX_INVITE_EMAILS) {
      return;
    }

    const newEmails = [...inviteEmails];
    const insertAtIndex =
      (index !== undefined ? index : inviteEmails.length) + 1;

    newEmails.splice(insertAtIndex, 0, "");
    setInviteEmails(newEmails);
    setTimeout(() => {
      inputRefs.current[insertAtIndex]?.focus();
    }, 0);
  };

  if (isUsingSSO) {
    return (
      <div className="text-sm pb-2 text-gray-600">
        Your organization is using SSO to manage access to Alpharun. Please add
        and remove teammates via your SSO provider.
      </div>
    );
  }

  return (
    <>
      <div className="text-sm pb-2 text-gray-600">
        Your plan includes unlimited teammates. Add emails individually, or
        paste comma or newline separated emails.
      </div>
      {inviteEmails.map((email, index) => (
        <div className="mb-2 max-w-xs relative group" key={index}>
          <Input
            key={index}
            placeholder="johndoe@example.com"
            value={email}
            onChange={handleEmailChange(index)}
            onPaste={handlePaste(index)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                addInviteField(index);
              }
            }}
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
          />
          <div className="text-gray-500 absolute right-1 top-0 mt-1 cursor-pointer hidden group-hover:block">
            <IconButton
              variant={ButtonVariantsEnum.Secondary}
              icon={<XMarkIcon className="h-4 w-4" />}
              onClick={() => {
                const newEmails = [...inviteEmails];
                newEmails.splice(index, 1);
                setInviteEmails(newEmails);
              }}
              isDisabled={inviteEmails.length === 1}
            />
          </div>
        </div>
      ))}
      <Button
        variant={ButtonVariantsEnum.Secondary}
        label="Add email"
        onClick={() => addInviteField()}
        isDisabled={inviteEmails.length === MAX_INVITE_EMAILS}
      />
      <div className="mt-4">
        <Button
          variant={ButtonVariantsEnum.Primary}
          label="Invite teammates"
          onClick={onClickInvite}
          isLoading={sendInvitationsLoadingState === LoadingStatesEnum.LOADING}
          isDisabled={sendInvitationsLoadingState === LoadingStatesEnum.LOADING}
        />
      </div>
    </>
  );
};
