import { ExclamationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";

import {
  ColumnDef,
  RowData,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Button, ButtonVariantsEnum, InfoTooltip } from "ui";
import {
  TargetingContact,
  TargetingSlideoverVariantsEnum,
} from "./targetingSlideover";

// Extend the TableMeta interface to include an updateData function
declare module "@tanstack/react-table" {
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

export interface RowErrorsMap {
  [key: number]: string;
}

export interface TargetingSlideoverTableProps {
  variant: TargetingSlideoverVariantsEnum;
  contacts: TargetingContact[];
  rowErrors: RowErrorsMap;
  setContacts?: React.Dispatch<React.SetStateAction<TargetingContact[]>>;
}

export const TargetingSlideoverTable: FC<TargetingSlideoverTableProps> = ({
  variant,
  contacts,
  rowErrors,
  setContacts,
}) => {
  const isEditable =
    variant === TargetingSlideoverVariantsEnum.MANUAL ||
    variant === TargetingSlideoverVariantsEnum.PENDING_PARTICIPANTS;

  const shouldAllowDelete =
    variant === TargetingSlideoverVariantsEnum.MANUAL ||
    variant === TargetingSlideoverVariantsEnum.PENDING_PARTICIPANTS ||
    variant === TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS;

  // Using a ref is required to support proper pasting and error behavior
  const contactsRef = useRef(contacts);
  useEffect(() => {
    contactsRef.current = contacts;
  }, [contacts]);

  // Hack to force a re-render with rowErrors change.
  // This was the only method found that worked to update the errors displayed
  // in the table on blur, without breaking the proper input focus.
  const [triggerRerender, setTriggerRerender] = useState(0);
  const rowErrorsRef = useRef(rowErrors);
  useEffect(() => {
    rowErrorsRef.current = rowErrors;
    setTriggerRerender((prev) => prev + 1);
  }, [rowErrors]);

  // Columns present for both editable and non-editable tables
  const coreColumns = [
    {
      accessorKey: "email",
      id: "email",
      header: () => <span>{isEditable ? "Email (required)" : "Email"}</span>,
    },
    {
      accessorKey: "first_name",
      id: "first_name",
      header: () => <span>First Name</span>,
    },
    {
      accessorKey: "last_name",
      id: "last_name",
      header: () => <span>Last Name</span>,
    },
  ];

  const generateColumnsForTableType = () => {
    let additionalColumns = [];

    if (variant !== TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS) {
      additionalColumns.push({
        id: "error",
        header: () => null, // No header for this column
        cell: ({ row: { index } }: { row: { index: number } }) => {
          const maybeRenderErrorIcon = () => {
            const error = rowErrorsRef.current[index];

            // Render a placeholder div to maintain proper spacing
            if (!error) return <div className="h-5 w-5"></div>;

            return (
              <InfoTooltip id={`row--error`} place="left" content={error}>
                <span className="cursor-pointer justify-end" onClick={() => {}}>
                  <ExclamationCircleIcon
                    aria-hidden="true"
                    className="h-4 w-4 text-red-500"
                  />
                </span>
              </InfoTooltip>
            );
          };

          return <div className="flex">{maybeRenderErrorIcon()}</div>;
        },
      });
    }

    if (variant === TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS) {
      additionalColumns.push({
        id: "outreach",
        accessorKey: "activity_summary",
        header: () => <span>Outreach</span>,
        cell: ({ getValue }: { getValue: () => string[] | undefined }) => {
          const activities = getValue();
          if (activities && activities.length > 0) {
            return (
              <div className="py-2 px-3 text-left text-sm">
                {activities.map((a) => {
                  return <div>{a}</div>;
                })}
              </div>
            );
          }
        },
      });
    }

    if (shouldAllowDelete) {
      additionalColumns.push({
        id: "delete",
        header: () => null, // No header for this column
        cell: ({ row: { index } }: { row: { index: number } }) => {
          // If we're manually editing, down show a delete icon if there's only one row.
          if (
            variant === TargetingSlideoverVariantsEnum.MANUAL &&
            contactsRef.current.length < 2
          )
            return null;

          return (
            <span
              className="delete-icon hidden group-hover:block cursor-pointer"
              onClick={() => {
                setContacts &&
                  setContacts((oldData) =>
                    oldData.filter((_, i) => i !== index),
                  );
              }}
            >
              <TrashIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
            </span>
          );
        },
      });
    }

    return [...coreColumns, ...additionalColumns];
  };

  const columns = useMemo<ColumnDef<TargetingContact>[]>(
    generateColumnsForTableType,
    [],
  );

  // Refs necessary to support proper keyboard behavior when tabbing/entering from inside a cell
  const inputRefs = useRef<(HTMLInputElement | null)[][]>([]);

  // Define behavior for a read only column's cell.
  const readOnlyColumn: Partial<ColumnDef<TargetingContact>> = {
    cell: ({ getValue }) => {
      return (
        <div className="py-2 px-3 text-left text-sm">
          {getValue() as string}
        </div>
      );
    },
  };

  // For editable columns, each cell must be an input and we need to handle keyboard and paste events.
  const editableColumn: Partial<ColumnDef<TargetingContact>> = {
    cell: ({
      getValue,
      row: { index: rowIndex },
      column: { id: columnId },
      table,
    }) => {
      const initialValue = getValue();
      // Track the current value in local state. This allows us to only update
      // table state on blur, not every keystroke (minimizing re-renders of the whole table).
      const [value, setValue] = useState(initialValue);

      // When the input is blurred, update the table state.
      const onBlur = () => {
        table.options.meta?.updateData(rowIndex, columnId, value);
      };

      // If the initialValue is changed externally, sync it up with our state
      useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);
      const currentColumnIndex = columns.findIndex(
        (col) => col.id === columnId,
      );

      const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // If user presses "Tab" on the last cell of the last row, automatically create a new empty row.
        if (e.key === "Tab" && !e.shiftKey) {
          if (
            rowIndex === contactsRef.current.length - 1 &&
            columnId === "last_name"
          ) {
            e.preventDefault();
            setContacts &&
              setContacts((old) => [
                ...old,
                { first_name: "", last_name: "", email: "" },
              ]);
            // Set focus to the first cell of the newly created row.
            setTimeout(() => {
              if (!inputRefs.current[rowIndex + 1]) {
                inputRefs.current[rowIndex + 1] = [];
              }
              inputRefs.current[rowIndex + 1][0]?.focus();
            }, 0);
          }
        }

        // If user presses "Enter", focus on the cell below or create a new row if it's the last row.
        if (e.key === "Enter") {
          e.preventDefault();
          // If it's the last row, create a new row and focus on the first cell.
          if (rowIndex === contactsRef.current.length - 1) {
            setContacts &&
              setContacts((old) => [
                ...old,
                { first_name: "", last_name: "", email: "" },
              ]);
            setTimeout(() => {
              if (!inputRefs.current[rowIndex + 1]) {
                inputRefs.current[rowIndex + 1] = [];
              }
              inputRefs.current[rowIndex + 1][0]?.focus();
            }, 0);
          } else {
            // Focus on the cell below.
            inputRefs.current[rowIndex + 1][currentColumnIndex]?.focus();
          }
        }
      };

      const handlePaste = async (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();

        const clipboardData = e.clipboardData;
        const pastedData = clipboardData.getData("Text");

        // Convert the pasted data into a 2D array of rows and cells
        // Cells pasted from a CSV or Google sheets will have rows split by newlines and cells in a row split by tabs
        const rows = pastedData.split("\n").map((row) => row.split(`\t`));

        // Update the table data with the pasted values, pasting relative to the currently focused cell
        setContacts &&
          setContacts((old) => {
            const newData = [...old];

            // Get the starting column index based on columnId
            const startingColumnIndex = columns.findIndex(
              (col) => col.id === columnId,
            );

            rows.forEach((rowCells, rIdx) => {
              rowCells.forEach((cellValue, cIdx) => {
                // Calculate the target row and column based on current position and offset from pasted data
                const targetRowIndex = rowIndex + rIdx;
                const targetColumnId = columns[startingColumnIndex + cIdx]?.id;

                // If the target row index exceeds the current data length, create a new row
                if (targetRowIndex >= newData.length) {
                  newData.push({ first_name: "", last_name: "", email: "" });
                }

                if (newData[targetRowIndex] && targetColumnId) {
                  (newData[targetRowIndex] as any)[targetColumnId] = cellValue;
                }
              });
            });

            return newData;
          });
      };

      const renderPlaceholder = () => {
        switch (columnId) {
          case "first_name":
            return "Amy";
          case "last_name":
            return "Smith";
          case "email":
          default:
            return "amy@example.com";
        }
      };

      return (
        <input
          className="border-none w-full h-full text-sm focus:outline-none bg-transparent"
          ref={(input) => {
            if (!inputRefs.current[rowIndex]) {
              inputRefs.current[rowIndex] = [];
            }
            inputRefs.current[rowIndex][currentColumnIndex] = input;
          }}
          value={value as string}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          placeholder={rowIndex === 0 ? renderPlaceholder() : ""}
        />
      );
    },
  };

  const table = useReactTable({
    data: contacts,
    columns,
    defaultColumn: isEditable ? editableColumn : readOnlyColumn,
    getCoreRowModel: getCoreRowModel(),
    // Provide our updateData function to our table meta
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        setContacts &&
          setContacts((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex]!,
                  [columnId]: value,
                };
              }
              return row;
            }),
          );
      },
    },
  });

  const maybeRenderAddParticipantButton = () => {
    if (!isEditable) return null;

    return (
      <div className="px-2 py-2">
        <Button
          variant={ButtonVariantsEnum.Tertiary}
          label="+ Add participant"
          onClick={() => {
            setContacts &&
              setContacts((old) => [
                ...old,
                { first_name: "", last_name: "", email: "" },
              ]);
            setTimeout(() => inputRefs.current[contacts.length][0]?.focus(), 0);
          }}
        />
      </div>
    );
  };

  const renderTableBody = () => {
    if (
      variant === TargetingSlideoverVariantsEnum.OUTREACH_PARTICIPANTS &&
      contacts.length === 0
    ) {
      return (
        <tr>
          <td colSpan={4} className="py-2 px-3 text-center text-sm">
            No remaining participants in outreach
          </td>
        </tr>
      );
    }

    return table.getRowModel().rows.map((row) => {
      return (
        <tr
          key={row.id}
          className={shouldAllowDelete ? "group hover:bg-gray-50" : ""}
        >
          {row.getVisibleCells().map((cell) => {
            return (
              <td
                key={cell.id}
                className="whitespace-nowrap text-xs text-gray-900"
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <>
      <div className="overflow-y-auto shadow ring-1 ring-black ring-opacity-5 flex-1 rounded-md pb-1">
        <table className="w-full">
          <thead className="bg-gray-100 sticky top-0">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="py-2 px-3 text-left text-sm font-semibold text-gray-900"
                    >
                      {header.isPlaceholder ? null : (
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white flex-shrink">
            {renderTableBody()}
          </tbody>
        </table>
      </div>
      {maybeRenderAddParticipantButton()}
    </>
  );
};
