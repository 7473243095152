import { FC, ReactNode } from "react";
import { joinClassnames } from "ui";

export interface SettingsTableDataCellProps {
  className?: string;
  children: ReactNode;
}

export const SettingsTableDataCell: FC<SettingsTableDataCellProps> = (
  props,
) => (
  <td
    className={joinClassnames(
      "overflow-hidden text-ellipsis px-6 py-2 whitespace-nowrap text-sm text-gray-800",
      props.className,
    )}
  >
    {props.children}
  </td>
);
