import { InterviewWithBasicContact } from "../entities/interview";
import { MessageTemplateVariablesEnum } from "../entities/messageTemplate";
import {
  InlineNode,
  MultilineContent,
  ParagraphNode,
  PlaceholderNode,
  SingleLineContent,
  TextNode,
} from "./types";

/**
 * @file
 * Parsing and stringifying content for plain text user input.
 *
 * This module converts between plain text and a structured document format
 * that's compatible with ProseMirror-style editors. The format uses a tree of
 * nodes (paragraphs, text, placeholders) similar to ProseMirror's document
 * schema, allowing easy integration with rich text editing while supporting
 * a simpler plain text input mode.
 *
 * Text like "Hello [name]" is parsed into a document tree with text and
 * placeholder nodes that can be rendered in various ways.
 */

function parseInlineContent(content: string): InlineNode[] {
  const parts = content.split(/\[([a-z._-]+)\]/).filter(Boolean);
  const nodes: InlineNode[] = [];

  for (let i = 0; i < parts.length; i++) {
    if (i % 2 === 0) {
      // Text node
      nodes.push({
        type: "text",
        text: parts[i],
      });
    } else {
      // Placeholder node
      nodes.push({
        type: "placeholder",
        attrs: {
          name: parts[i],
        },
      });
    }
  }

  return nodes;
}

function parseParagraphNode(paragraph: string): ParagraphNode {
  return {
    type: "paragraph",
    content: parseInlineContent(paragraph),
  };
}

export function parseSingleLineContent(subject: string): SingleLineContent {
  return {
    content: parseInlineContent(subject),
  };
}

export function parseMultilineContent(body: string): MultilineContent {
  return {
    content: body.split(/[\r\n]{2,}/).map(parseParagraphNode),
  };
}

function stringifyTextNode(node: TextNode): string {
  return node.text;
}

function stringifyPlaceholderNode(
  node: PlaceholderNode,
  interviewForVariables?: InterviewWithBasicContact,
): string {
  if (!interviewForVariables) return `[${node.attrs.name}]`;

  switch (node.attrs.name) {
    case MessageTemplateVariablesEnum.CONTACT_FIRST_NAME:
      return interviewForVariables.contact.first_name || "";
    case MessageTemplateVariablesEnum.CONTACT_LAST_NAME:
      return interviewForVariables.contact.last_name || "";
    default:
      return `[${node.attrs.name}]`;
  }
}

function stringifyInlineNode(
  node: InlineNode,
  interviewForVariables?: InterviewWithBasicContact,
): string {
  switch (node.type) {
    case "text":
      return stringifyTextNode(node);
    case "placeholder":
      return stringifyPlaceholderNode(node, interviewForVariables);
    default:
      return "";
  }
}

function stringifyParagraphNode(
  node: ParagraphNode,
  interviewForVariables?: InterviewWithBasicContact,
): string {
  return node.content
    .map((node) => stringifyInlineNode(node, interviewForVariables))
    .join("");
}

export function stringifySingleLineContent(
  subject: SingleLineContent,
  interviewForVariables?: InterviewWithBasicContact,
): string {
  return subject.content
    .map((node) => stringifyInlineNode(node, interviewForVariables))
    .join("");
}

export function stringifyMultilineContent(
  body: MultilineContent,
  interviewForVariables?: InterviewWithBasicContact,
): string {
  return body.content
    .map((node) => stringifyParagraphNode(node, interviewForVariables))
    .join("\n\n");
}
