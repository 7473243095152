import { TrashIcon } from "@heroicons/react/24/outline";
import {
  BasicQuestion,
  ComplexQuestion,
  ComplexQuestionInsert,
} from "app-types";
import { FC } from "react";
import {
  ButtonVariantsEnum,
  IconButton,
  Pill,
  PillColorsEnum,
  SizesEnum,
  Textarea,
  TextareaVariantsEnum,
} from "ui";

interface RecruitingQuestionsManagerCellProps {
  questionNumber: number;
  question: ComplexQuestion | ComplexQuestionInsert | BasicQuestion;
  onRemove: () => void;
  onChange?: (question: BasicQuestion) => void;
  onEnterKeyDown?: () => void;
}

export const RecruitingQuestionsManagerCell: FC<
  RecruitingQuestionsManagerCellProps
> = ({ questionNumber, question, onRemove, onChange, onEnterKeyDown }) => {
  return (
    <div
      className={
        "bg-white group relative w-full flex flex-row space-x-2 p-2 cursor-pointer border-b border-gray-900/5"
      }
    >
      <div className="cursor-move">
        <Pill
          label={questionNumber}
          color={PillColorsEnum.GREY}
          size={SizesEnum.SMALL}
        />
      </div>
      <div className="w-full">
        <div className="w-full">
          <Textarea
            autoFocus={!question.question}
            value={question.question}
            variant={TextareaVariantsEnum.MINIMAL}
            onChange={(evt) => {
              onChange?.({
                ...question,
                question: evt.target.value,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && onEnterKeyDown) {
                e.preventDefault();
                e.currentTarget.blur();
                onEnterKeyDown();
              }
            }}
            placeholder="Add a question..."
            shouldDisableNewline
          />
        </div>
      </div>
      <div className="absolute z-10 right-3 top-1/2 transform -translate-y-1/2 ml-3 opacity-0 group-hover:opacity-100">
        <IconButton
          variant={ButtonVariantsEnum.Secondary}
          icon={<TrashIcon className="h-4 w-4 text-red-500" />}
          onClick={onRemove}
          tabIndex={-1}
        />
      </div>
    </div>
  );
};
