import { IntegrationProvider } from "app-types";
import { getAxiosInstanceWithAuth } from "../api/axiosConfig";
import { POPUP_AUTH_CALLBACK_QUERY_PARAM_NAME } from "../features/signin/signInPage";

export const openOAuthSigninPopupForIntegration = async (
  integration: IntegrationProvider,
) => {
  // Open a blank popup immediately on user click
  const popup = window.open("about:blank", "OAuthPopup");

  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.get(`/oauth/login/${integration}`);
    console.log(response.data);

    if (!popup) {
      // TODO Popup may have been blocked, show the URL in the UI for user to click
      return;
    }

    // Set the URL of the popup
    popup.location.href = response.data;
  } catch (error) {
    console.error("Error during OAuth login:", error);
    popup && popup.close(); // Close the popup if there's an error
  }
};

let isPopupAuthCallback: boolean | null = null;

// Check if we are on the callback page for the popup auth flow (i.e. embedded in Fountain)
export function getIsPopupAuthCallback(): boolean {
  if (isPopupAuthCallback !== null) {
    return isPopupAuthCallback;
  }

  if (typeof window === "undefined") {
    isPopupAuthCallback = false;
    return false;
  }

  const searchParams = new URLSearchParams(window.location.search);
  isPopupAuthCallback =
    searchParams.get(POPUP_AUTH_CALLBACK_QUERY_PARAM_NAME) === "true";

  return isPopupAuthCallback;
}
