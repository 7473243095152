import {
  InlineNode,
  MultilineContent,
  ParagraphNode,
  PlaceholderNode,
  SingleLineContent,
  TextNode,
} from "app-types";
import { ReactNode } from "react";

/**
 * @file
 * Rendering content for displaying in a React UI.
 */

function renderTextNode(node: TextNode): ReactNode {
  return node.text;
}

function renderPlaceholderNode(node: PlaceholderNode): ReactNode {
  // TODO: Tweak styling.
  return (
    <span className="font-semibold font-mono text-indigo-800">
      {node.attrs.name}
    </span>
  );
}

function renderInlineNode(node: InlineNode): ReactNode {
  switch (node.type) {
    case "text":
      return renderTextNode(node);

    case "placeholder":
      return renderPlaceholderNode(node);

    default:
      return null;
  }
}

function renderParagraphNode(node: ParagraphNode): ReactNode {
  return <p>{node.content.map(renderInlineNode)}</p>;
}

export function renderSingleLineContent(content: SingleLineContent): ReactNode {
  return <>{content.content.map(renderInlineNode)}</>;
}

export function renderMultilineContent(content: MultilineContent): ReactNode {
  return <>{content.content.map(renderParagraphNode)}</>;
}
