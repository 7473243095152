import {
  BellSlashIcon,
  CheckIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { MessageTemplate } from "app-types";
import { FC, ReactNode, useMemo } from "react";
import {
  Button,
  Key,
  ListBox,
  ListBoxItem,
  Popover,
  Select,
} from "react-aria-components";
import { useNavigate } from "react-router-dom";
import { joinClassnames, Label, SizesEnum } from "ui";
import { MessageTemplateCard } from "./messageTemplateCard";

export interface MessageTemplateFieldProps {
  label: ReactNode;
  template: MessageTemplate | undefined;
  templates: MessageTemplate[];
  onChange: (template: MessageTemplate | undefined) => void;
}

type MessageTemplateItem =
  | NewMessageTemplateItem
  | ExistingMessageTemplateItem
  | NoMessageTemplateItem;

interface NewMessageTemplateItem {
  id: "new";
  type: "new";
}

interface ExistingMessageTemplateItem {
  type: "existing";
  id: Key;
  template: MessageTemplate;
}

interface NoMessageTemplateItem {
  id: "none";
  type: "none";
}

export const MessageTemplateField: FC<MessageTemplateFieldProps> = ({
  label,
  template,
  templates,
  onChange,
}) => {
  const navigate = useNavigate();

  const items: readonly MessageTemplateItem[] = useMemo(
    () => [
      { type: "new" as const, id: "new" },
      { type: "none" as const, id: "none" },
      ...templates.map((template) => ({
        type: "existing" as const,
        id: template.id,
        template,
      })),
    ],
    [templates],
  );

  return (
    <div className="relative">
      <Select
        className="flex flex-col space-y-2"
        selectedKey={template?.id ?? "none"}
        onSelectionChange={(key) => {
          if (key === "new") {
            navigate("/settings/message-templates");
          } else if (key === "none") {
            onChange(undefined);
          } else {
            onChange(templates.find((t) => t.id === key));
          }
        }}
      >
        <Label size={SizesEnum.SMALL}>{label}</Label>
        <Button
          className={joinClassnames(
            "w-max overflow-hidden rounded-lg pl-3 pr-3 py-2 space-x-2 flex flex-row items-center justify-between text-gray-900 text-left bg-white",
            template
              ? "border border-gray-300 shadow-sm"
              : "border border-dashed border-gray-300 hover:bg-gray-100",
          )}
        >
          <MessageTemplateCard template={template} />
        </Button>
        <Popover className="overflow-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1.5 data-[entering]:animate-popover-enter data-[exiting]:animate-popover-exit">
          <ListBox className="focus:outline-none" items={items}>
            {(item) => (
              <ListBoxItem
                className="pl-3 pr-2 py-2 space-x-2 flex flex-row items-center justify-between cursor-pointer focus:outline-none focus:bg-gray-100"
                textValue="Message template"
              >
                {({ isSelected }) => (
                  <>
                    {item.type === "new" ? (
                      <div className="max-w-72 overflow-hidden flex flex-row items-center space-x-3 text-gray-900">
                        <PlusIcon className="size-4 shrink-0" />
                        <div className="text-sm">
                          Create a new message template
                        </div>
                      </div>
                    ) : item.type === "none" ? (
                      <div className="max-w-72 overflow-hidden flex flex-row items-center space-x-3 text-gray-900">
                        <BellSlashIcon className="size-4 shrink-0" />
                        <div className="text-sm">No message</div>
                      </div>
                    ) : (
                      <MessageTemplateCard template={item.template} />
                    )}
                    <CheckIcon
                      aria-hidden="true"
                      className={joinClassnames(
                        "size-5 shrink-0 text-indigo-600",
                        isSelected ? "visible" : "invisible",
                      )}
                    />
                  </>
                )}
              </ListBoxItem>
            )}
          </ListBox>
        </Popover>
      </Select>
    </div>
  );
};
