import { CompanyUpdateDB, HandlerOf } from "app-types";
import { FC, useState } from "react";
import { Input } from "ui";
import { SaveAndCancelButtons } from "../../projects/saveAndCancelButtons";

const MIN_NAME_LENGTH = 2;
const MAX_NAME_LENGTH = 100;

const MIN_DESCRIPTION_LENGTH = 15;
const MAX_DESCRIPTION_LENGTH = 250;

interface CompanyNameDescriptionEditor {
  onSave: HandlerOf<CompanyUpdateDB>;
  name: string | null;
  description: string | null;
}

export const CompanyNameDescriptionEditor: FC<CompanyNameDescriptionEditor> = ({
  onSave,
  name,
  description,
}) => {
  const existingName = name || "";
  const existingDescription = description || "";

  const [companyName, setCompanyName] = useState(existingName);
  const [companyNameError, setCompanyNameError] = useState("");

  const [companyDescription, setCompanyDescription] =
    useState(existingDescription);
  const [companyDescriptionError, setCompanyDescriptionError] = useState("");

  const onClickSaveName = async () => {
    if (!companyName) {
      setCompanyNameError("A name for your organization is required");
      return;
    }

    if (companyName && companyName.length < MIN_NAME_LENGTH) {
      setCompanyNameError(
        `Name must be at least ${MIN_NAME_LENGTH} characters long`,
      );
      return;
    }

    if (companyName && companyName.length > MAX_NAME_LENGTH) {
      setCompanyNameError(
        `Name must be less than ${MAX_NAME_LENGTH} characters long`,
      );
      return;
    }

    setCompanyNameError("");
    onSave({
      name: companyName,
      description: existingDescription || undefined,
    });
  };

  const onClickSaveDescription = async () => {
    if (!companyDescription) {
      setCompanyDescriptionError(
        "A description of your product or service is required",
      );
      return;
    }

    if (
      companyDescription &&
      companyDescription.length < MIN_DESCRIPTION_LENGTH
    ) {
      setCompanyDescriptionError(
        `Must be at least ${MIN_DESCRIPTION_LENGTH} characters long`,
      );
      return;
    }

    if (
      companyDescription &&
      companyDescription.length > MAX_DESCRIPTION_LENGTH
    ) {
      setCompanyDescriptionError(
        `Must be less than ${MAX_DESCRIPTION_LENGTH} characters long`,
      );
      return;
    }

    setCompanyDescriptionError("");
    onSave({
      name: existingName || undefined,
      description: companyDescription,
    });
  };

  return (
    <>
      <div>
        <div className="mt-3 flex flex-col space-y-3">
          <div>
            <Input
              label={"Organization name"}
              value={companyName}
              onChange={(evt) => {
                setCompanyNameError("");
                setCompanyName(evt.target.value);
              }}
              placeholder="AcmeCRM"
              errorDescription={companyNameError}
              description={
                "The name of your company shown to interview participants."
              }
              inputClassName="max-w-[200px]"
            />
          </div>
          {existingName !== companyName ? (
            <SaveAndCancelButtons
              onSave={onClickSaveName}
              onCancel={() => setCompanyName(existingName)}
            />
          ) : null}
          <div>
            <Input
              label={"Description of your product or service"}
              value={companyDescription}
              onChange={(evt) => {
                setCompanyDescriptionError("");
                setCompanyDescription(evt.target.value);
              }}
              maxLength={500} // Longer character limit so we can add more context in an error message
              placeholder={
                "AcmeCRM provides intuitive CRM software to small businesses."
              }
              errorDescription={companyDescriptionError}
              description={`A simple one-sentence description of the product or service you provide to your customers (ex. Acme is...'). 
  This is used to generate more relevant AI clarifying questions in interviews - it can be overridden on a per-project basis with the "Custom AI context" setting.`}
              inputClassName="max-w-[500px]"
            />
          </div>
          {existingDescription !== companyDescription ? (
            <SaveAndCancelButtons
              onSave={onClickSaveDescription}
              onCancel={() => {
                setCompanyDescription(existingDescription);
                setCompanyDescriptionError("");
              }}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
