import { HandlerOf, QuestionsImportSourcesEnum } from "app-types";
import { FC, useState } from "react";
import { Textarea } from "ui";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";
import { questionsImportTypeToPlaceholder } from "../surveyQuestions/importQuestionsModal";

const MIN_JD_LENGTH = 20;
const MAX_JD_LENGTH = 10000;

interface JobDescriptionSetting {
  jobDescription: string | null;
  onSave: HandlerOf<string | null>;
}

export const JobDescriptionSetting: FC<JobDescriptionSetting> = (props) => {
  const { onSave, jobDescription } = props;
  const [newJobDescription, setNewJobDescription] = useState(
    jobDescription ?? "",
  );

  const onClickSave = (): void => {
    onSave(newJobDescription);
  };

  return (
    <div className="flex flex-col space-y-3">
      <JobDescriptionTextArea
        value={newJobDescription}
        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
          setNewJobDescription(evt.target.value);
        }}
        description="The job description for this role. Should include details about the employer, role responsibilites, location, benefits etc.."
      />
      {jobDescription !== newJobDescription ? (
        <SaveAndCancelButtons
          onSave={onClickSave}
          onCancel={() => {
            setNewJobDescription(jobDescription ?? "");
          }}
          isSaveDisabled={
            !newJobDescription ||
            newJobDescription.length < MIN_JD_LENGTH ||
            newJobDescription.length > MAX_JD_LENGTH
          }
        />
      ) : null}
    </div>
  );
};

interface JobDescriptionTextAreaProps {
  value: string;
  onChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  description: string;
  errorText?: string;
}

export const JobDescriptionTextArea: FC<JobDescriptionTextAreaProps> = ({
  value,
  onChange,
  errorText,
  description,
}) => {
  const [error, setError] = useState("");

  return (
    <Textarea
      label="Job description"
      value={value}
      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
        setError(""); // Clear error on change
        onChange(evt);
      }}
      onBlur={(evt) => {
        const newValue = evt.target.value;
        if (newValue.length < MIN_JD_LENGTH) {
          setError(
            `Job description must be at least ${MIN_JD_LENGTH.toString()} characters long`,
          );
        } else if (newValue.length > MAX_JD_LENGTH) {
          setError(
            `Job description must be less than ${MAX_JD_LENGTH.toString()} characters long`,
          );
        } else {
          setError("");
        }
      }}
      maxLength={MAX_JD_LENGTH}
      placeholder={
        questionsImportTypeToPlaceholder[
          QuestionsImportSourcesEnum.JOB_DESCRIPTION
        ]
      }
      errorDescription={error || errorText}
      description={description}
      className="w-full"
      height="250px"
    />
  );
};
