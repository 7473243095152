import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { InterviewAgentTypesEnum, ProjectWithInterviewCount } from "app-types";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { Label, SizesEnum } from "ui";

interface InterviewLinkCopyboxProps {
  inviteLinkUrl: string;
  project: ProjectWithInterviewCount;
  onCopyInterviewLink: () => void;
}

export const InterviewLinkCopybox: React.FC<InterviewLinkCopyboxProps> = ({
  inviteLinkUrl,
  project,
  onCopyInterviewLink,
}) => {
  const [isCopyingInterviewLink, setIsCopyingInterviewLink] = useState(false);
  const isRecruitingModeProject =
    project.settings.interview_agent_type ===
    InterviewAgentTypesEnum.RecruitingInterviewer;

  return (
    <div className="flex flex-col justify-between mb-2">
      <Label size={SizesEnum.SMALL}>Interview link</Label>
      <div className="mt-1 flex flex-row space-x-2">
        <div
          className="rounded-md bg-white py-2 px-2 border border-solid border-slate-300 w-fit flex space-x-2 hover:bg-slate-100 cursor-pointer"
          onClick={() => {
            setIsCopyingInterviewLink(true);
            copy(inviteLinkUrl);

            setTimeout(() => {
              setIsCopyingInterviewLink(false);
            }, 4000);

            onCopyInterviewLink();
          }}
        >
          <span className="text-sm font-medium text-blue-600">
            {inviteLinkUrl}
          </span>
          {isCopyingInterviewLink ? (
            <CheckIcon className="h-5 w-5 text-blue-600" />
          ) : (
            <ClipboardDocumentIcon className="h-5 w-5 text-blue-600" />
          )}
        </div>
      </div>
      {!isRecruitingModeProject && (
        <div className="text-sm mt-2 text-slate-600 space-y-2">
          Anyone you send this link to (via email,in-app popup, live chat, etc.)
          can complete an interview
          {project.is_live ? "" : " once this project is launched"}.{" "}
          <a
            className="text-blue-600 underline"
            href="https://help.alpharun.com/en/articles/687232"
            rel="noopener"
            target="_blank"
          >
            Check out the guide
          </a>{" "}
          for maximizing conversion with your interview link.
        </div>
      )}
    </div>
  );
};
