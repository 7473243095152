import { PencilIcon, RocketLaunchIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import {
  BasicQuestion,
  buildProjectUrlPath,
  ComplexQuestionInsert,
  getProjectAndJobTypeProperty,
  isRecruitingModeProject,
  JobTypePropertyEnum,
  PosthogEventTypesEnum,
  ProjectDetailsTabPathsEnum,
  ProjectModesEnum,
  ProjectUpdate,
  ProjectWithInterviewCount,
} from "app-types";
import copy from "copy-to-clipboard";
import { nanoid } from "nanoid";
import posthog from "posthog-js";
import { FC, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonVariantsEnum,
  getInterviewLinkForProject,
  InfoTooltip,
  ModalVariantsEnum,
  Pill,
  PillColorsEnum,
  SimpleModal,
  SizesEnum,
  Tabs,
} from "ui";
import { getAxiosInstanceWithAuth } from "../api/axiosConfig";
import {
  selectCompany,
  selectIsCompanyBlocked,
} from "../features/company/companySlice";
import { InsightsTabContainer } from "../features/insights/insightsTabContainer";
import { InterviewSlideoverContainer } from "../features/interviews/interviewSlideover/interviewSlideoverContainer";
import { InterviewsTab } from "../features/interviews/interviewsTab";
import {
  NotificationTypeEnum,
  showNotification,
} from "../features/notificationsOverlay/notificationsSlice";
import { selectPhoneNumberAndExtensionForProject } from "../features/phoneNumbers/phoneNumbersSlice";
import { InterviewLinkTipsModal } from "../features/projects/interviewLinkTipsModal";
import { ProjectMoreMenu } from "../features/projects/projectMoreMenu";
import { ProjectOwnersWidget } from "../features/projects/projectOwnersWidget";
import { RecruitingProjectSettings } from "../features/projects/projectSettings/recruiting/recruitingProjectSettings";
import { VoiceModeProjectSettings } from "../features/projects/projectSettings/voiceModeProjectSettings";
import {
  createProject,
  deleteProject,
  updateProject,
} from "../features/projects/projectsSlice";
import { SurveyModeProjectSettings } from "../features/projects/surveyModeProjectSettings";
import { VoiceModeShareTemplatesModal } from "../features/projects/voiceModeShareTemplatesModal";
import { fetchTeammates } from "../features/teammates/teammatesSlice";
import { useAppDispatch, useAppSelector } from "../hooks/hook";

export enum ProjectDetailsTabsEnum {
  ProjectConfiguration = "Project configuration",
  Interviews = "Interviews",
  Insights = "Insights",
}
const tabs = [
  ProjectDetailsTabsEnum.ProjectConfiguration,
  ProjectDetailsTabsEnum.Interviews,
  ProjectDetailsTabsEnum.Insights,
];

const mapTabToUrlTab = {
  [ProjectDetailsTabsEnum.ProjectConfiguration]:
    ProjectDetailsTabPathsEnum.ProjectConfiguration,
  [ProjectDetailsTabsEnum.Interviews]: ProjectDetailsTabPathsEnum.Interviews,
  [ProjectDetailsTabsEnum.Insights]: ProjectDetailsTabPathsEnum.Insights,
};

interface ProjectDetailsPageProps {
  project: ProjectWithInterviewCount;
  tab: ProjectDetailsTabsEnum;
}

export const ProjectDetailsPage: FC<ProjectDetailsPageProps> = ({
  project,
  tab,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const company = useAppSelector(selectCompany);
  const phoneNumberInfo = useAppSelector(
    selectPhoneNumberAndExtensionForProject(project.id),
  );
  const isRecruitingMode = isRecruitingModeProject(project);

  if (!company) {
    throw new Error("Company not found");
  }

  const navigateToTab = (tab: ProjectDetailsTabsEnum) => {
    const urlTab = mapTabToUrlTab[tab];
    if (!urlTab) return;

    navigate(buildProjectUrlPath(project.id, urlTab));
  };

  const isCompanyBlocked = useAppSelector(selectIsCompanyBlocked);

  useEffect(() => {
    dispatch(fetchTeammates());
  }, []);

  /**
   * State.
   */
  const [projectName, setProjectName] = useState(project.name);
  const [isEditingProjectName, setIsEditingProjectName] = useState(false);
  const projectNameInputRef = useRef<HTMLInputElement>(null);

  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState<boolean>(false);
  const [isDeleteWarningModalOpen, setIsDeleteWarningModalOpen] =
    useState<boolean>(false);
  const [isPauseModalOpen, setIsPauseModalOpen] = useState<boolean>(false);
  const [isSharingTemplatesModalOpen, setIsSharingTemplatesModalOpen] =
    useState<boolean>(false);
  const [isDuplicateProjectModalOpen, setIsDuplicateProjectModalOpen] =
    useState<boolean>(false);
  const [isCsvExportModalOpen, setIsCsvExportModalOpen] = useState(false);
  const [isCsvExportLoading, setIsCsvExportLoading] = useState(false);

  const { pending_interview_count } = project;

  const interviewLink = getInterviewLinkForProject(
    import.meta.env.VITE_INTERVIEW_APP_BASE_URL,
    company,
    project,
  );

  // If the project is live or if we have any interviews that are not pending, we should render tabs.
  // Otherwise, we'll only show the project configuration page.
  const shouldRenderTabs = project.is_live || project.interview_count > 0;

  // Ref to the interview limit setting so we can scroll to it when the user clicks the warning pill.
  const interviewLimitSettingRef = useRef<HTMLLabelElement>(null);

  const onClickPause = () => {
    setIsPauseModalOpen(true);
  };

  const onConfirmPause = async () => {
    try {
      await dispatch(
        updateProject({
          projectId: project.id,
          changes: { is_live: false },
        }),
      );

      showNotification(dispatch, {
        id: `project-${project.id}-paused-${new Date().getTime()}`,
        primaryMessage: `Project paused`,
        type: NotificationTypeEnum.SUCCESS,
      });
    } catch (err) {
      console.error("error updating project", err);
      showNotification(dispatch, {
        id: `project-${project.id}-pause-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to pause project`,
        type: NotificationTypeEnum.FAILURE,
      });
    }

    setIsPauseModalOpen(false);
  };

  const onConfirmLaunch = async () => {
    // Launch the project. No validation required because the button is disabled otherwise.
    try {
      await dispatch(
        updateProject({
          projectId: project.id,
          changes: { is_live: true },
        }),
      );

      posthog.capture(PosthogEventTypesEnum.ProjectLaunch, {
        project_id: project.id,
        project_name: project.name,
        num_pending_interviews: pending_interview_count,
      });

      showNotification(dispatch, {
        id: `project-${project.id}-launched-${new Date().getTime()}`,
        primaryMessage: `Project successfully launched`,
        type: NotificationTypeEnum.SUCCESS,
      });
    } catch (err) {
      console.error("error updating project", err);
      showNotification(dispatch, {
        id: `project-${project.id}-launch-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to launch project`,
        type: NotificationTypeEnum.FAILURE,
      });
    }

    setIsLaunchModalOpen(false);
  };

  const onConfirmDelete = async () => {
    try {
      // First navigate back to the projects page before deleting so
      // that we don't try to a project that doesn't exist.
      navigate("/");
      await dispatch(deleteProject(project.id));

      showNotification(dispatch, {
        id: `project-${project.id}-deleted-${new Date().getTime()}`,
        primaryMessage: `Project deleted`,
        type: NotificationTypeEnum.SUCCESS,
      });
    } catch (err) {
      console.error("error updating project", err);
    }

    setIsDeleteWarningModalOpen(false);
  };

  const onConfirmDuplicateProject = async () => {
    try {
      await dispatch(
        createProject({
          name: `Copy of ${project.name}`,
          incentive_quantity: project.incentive_quantity || 0,
          questions: getProjectAndJobTypeProperty(
            project,
            JobTypePropertyEnum.Questions,
          ).map((q) => {
            // Remove id and project_id so that server creates new questions
            const newQuestion: BasicQuestion | ComplexQuestionInsert = {
              ...q,
            };
            if ("id" in newQuestion) {
              delete newQuestion.id;
            }
            if ("project_id" in newQuestion) {
              delete newQuestion.project_id;
            }
            return newQuestion;
          }),
          short_id: nanoid(),
          settings: {
            ...project.settings,
            prompt_context: getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.PromptContext,
            ),
            interview_language: getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.Language,
            ),
            auto_advance_assessment_score_threshold:
              getProjectAndJobTypeProperty(
                project,
                JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold,
              ),
            should_force_fullscreen: getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.ShouldForceFullscreen,
            ),
            should_record_video: getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.ShouldRecordVideo,
            ),
            resume_collection_type: getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.ResumeCollectionType,
            ),
          },
          mode: project.mode,
          job_description:
            getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.JobDescription,
            ) ?? undefined,
          assessment_id_to_duplicate:
            getProjectAndJobTypeProperty(
              project,
              JobTypePropertyEnum.AssessmentId,
            ) ?? undefined,
          phone_number_id: phoneNumberInfo?.phoneNumber.id,
          owner_teammate_ids: project.owner_teammate_ids,
        }),
      ).unwrap();

      showNotification(dispatch, {
        id: `project-duplicated-${new Date().getTime()}`,
        primaryMessage: `Project duplicated`,
        type: NotificationTypeEnum.SUCCESS,
      });

      navigate("/");
    } catch (err) {
      console.error("error duplicating project", err);
    }
  };

  const handleCsvExport = async () => {
    setIsCsvExportLoading(true);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get(`/projects/${project.id}/csv-export`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project.name}_interviews.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      showNotification(dispatch, {
        id: `project-${project.id}-csv-export-success-${new Date().getTime()}`,
        primaryMessage: "CSV export successful",
        type: NotificationTypeEnum.SUCCESS,
      });
    } catch (error) {
      console.error("Error exporting CSV:", error);
      showNotification(dispatch, {
        id: `project-${project.id}-csv-export-error-${new Date().getTime()}`,
        primaryMessage: "Failed to export CSV",
        type: NotificationTypeEnum.FAILURE,
      });
    } finally {
      setIsCsvExportLoading(false);
      setIsCsvExportModalOpen(false);
    }
  };

  // TODO - handle different CTA scenarios based on project status.
  const renderActionButtons = () => {
    return (
      <>
        {project.is_live && project.mode === ProjectModesEnum.SURVEY ? (
          <Button
            label="Pause"
            onClick={onClickPause}
            variant={ButtonVariantsEnum.Secondary}
          />
        ) : null}
        {!project.is_live ? (
          <InfoTooltip
            id="launch-tooltip"
            place="left"
            content={computeLaunchErrorMessage()}
          >
            <Button
              label="Launch"
              onClick={() => {
                setIsLaunchModalOpen(true);
              }}
              variant={ButtonVariantsEnum.Primary}
              isDisabled={Boolean(computeLaunchErrorMessage())}
            />
          </InfoTooltip>
        ) : null}
        <ProjectMoreMenu
          projectId={project.id}
          inviteLinkUrl={interviewLink}
          onClickPause={
            project.is_live && project.mode !== ProjectModesEnum.SURVEY
              ? onClickPause
              : undefined
          }
          onClickCopyInterviewLink={
            project.mode === ProjectModesEnum.SURVEY
              ? () => {
                  copy(interviewLink);
                  setIsSharingTemplatesModalOpen(true);
                }
              : undefined
          }
          onClickViewDialInstructions={
            project.mode === ProjectModesEnum.VOICE_AGENT
              ? () => {
                  setIsSharingTemplatesModalOpen(true);
                }
              : undefined
          }
          onClickCopyProjectId={() => {
            copy(project.id);
            showNotification(dispatch, {
              id: `project-${project.id}-id-copied-${new Date().getTime()}`,
              primaryMessage: `Project ID copied - use it to reference this project in the API`,
              type: NotificationTypeEnum.SUCCESS,
            });
          }}
          onClickDeleteProject={() => {
            setIsDeleteWarningModalOpen(true);
          }}
          onClickDuplicateProject={() => {
            setIsDuplicateProjectModalOpen(true);
          }}
          onClickCsvExport={() => {
            setIsCsvExportModalOpen(true);
          }}
        />
      </>
    );
  };

  const maybeRenderInterviewLimitWarning = () => {
    if (!project.has_reached_interview_limit) return null;

    return (
      <div className="mx-auto w-full max-w-4xl mt-4">
        <Pill
          size={SizesEnum.MEDIUM}
          color={PillColorsEnum.RED}
          label={
            <div className="flex space-x-2">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
              <span>
                Monthly interview limit reached.{" "}
                <span
                  className="cursor-pointer font-semibold"
                  onClick={() => {
                    interviewLimitSettingRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  }}
                >
                  Increase this project's limit
                </span>{" "}
                to conduct more interviews.
              </span>
            </div>
          }
        />
      </div>
    );
  };

  const onSaveProject = async (
    changes: ProjectUpdate,
    hidesNotifications = false,
  ) => {
    try {
      await dispatch(
        updateProject({
          projectId: project.id,
          changes,
        }),
      );

      if (!hidesNotifications) {
        showNotification(dispatch, {
          id: `project-${project.id}-updated-${new Date().getTime()}`,
          primaryMessage: `Project successfully updated`,
          type: NotificationTypeEnum.SUCCESS,
        });
      }
    } catch (err) {
      console.error("error updating project", err);
      showNotification(dispatch, {
        id: `project-${project.id}-update-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to update project`,
        type: NotificationTypeEnum.FAILURE,
      });
    }
  };

  const getProjectSettings = (): JSX.Element => {
    if (isRecruitingMode) {
      return (
        <RecruitingProjectSettings
          project={project}
          onSaveProject={onSaveProject}
          company={company}
          onClickSharingTemplates={() => {
            setIsSharingTemplatesModalOpen(true);
          }}
        />
      );
    }

    if (project.mode === ProjectModesEnum.VOICE_AGENT) {
      return (
        <VoiceModeProjectSettings
          project={project}
          onSaveProject={onSaveProject}
          company={company}
          onClickSharingTemplates={() => {
            setIsSharingTemplatesModalOpen(true);
          }}
        />
      );
    }

    return (
      <SurveyModeProjectSettings
        project={project}
        company={company}
        onSaveProject={onSaveProject}
        setCurrentTab={navigateToTab}
        interviewLimitSettingRef={interviewLimitSettingRef}
        onCopyInterviewLink={() => {
          setIsSharingTemplatesModalOpen(true);
        }}
      />
    );
  };

  const renderPageContent = (): JSX.Element | undefined => {
    switch (tab) {
      case ProjectDetailsTabsEnum.ProjectConfiguration:
        return (
          <div className="mx-auto w-full max-w-4xl">
            <div className="overflow-y-auto -mx-4 p-4 space-y-6 h-full">
              {getProjectSettings()}
            </div>
          </div>
        );

      case ProjectDetailsTabsEnum.Interviews:
        return (
          <Routes>
            <Route
              path=":interview_id?"
              element={<InterviewsTab project={project} />}
            />
          </Routes>
        );

      case ProjectDetailsTabsEnum.Insights:
        return <InsightsTabContainer project={project} />;
    }
  };

  const computeLaunchErrorMessage = () => {
    if (!project.questions.length) {
      return "At least one question is required before launching your project.";
    }

    if (isCompanyBlocked)
      return "An active subscription is required to launch a project - please upgrade to continue.";

    return undefined;
  };

  const renderProjectName = () => {
    if (!isEditingProjectName) {
      return (
        <div className="flex group items-center h-9 space-x-2">
          <h2
            className="text-xl font-semibold text-gray-900 cursor-default text-ellipsis truncate max-w-xs"
            title={project.name}
          >
            {project.name}
          </h2>
          <div
            className="rounded-md p-1 hover:bg-slate-200 cursor-pointer h-fit"
            onClick={() => {
              setIsEditingProjectName(true);
              setTimeout(() => {
                projectNameInputRef.current?.focus();
              });
            }}
          >
            <PencilIcon className="h-4 w-4 text-gray-500 opacity-0 group-hover:opacity-100" />
          </div>
        </div>
      );
    }

    return (
      <div className="flex h-9">
        <input
          aria-describedby={`${project.id}-name`}
          className="rounded-md border-0 w-80 font-bold py-1.5 leading-7 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-500 text-xl"
          id={`${project.id}-name`}
          ref={projectNameInputRef}
          maxLength={150}
          minLength={0}
          onChange={(evt) => {
            setProjectName(evt.target.value);
          }}
          type="text"
          value={projectName}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              evt.preventDefault();
              projectNameInputRef.current?.blur(); // Trigger the onBlur by removing focus from the input
            }
          }}
          onBlur={(evt) => {
            setIsEditingProjectName(false);
            // If the name hasn't changed, don't dispatch an update.
            if (projectName === project.name) return;

            // If the name isn't valid, just reset it to the original value.
            if (projectName.length < 2) {
              setProjectName(project.name);
              return;
            }

            // Otherwise, update the project name.
            dispatch(
              updateProject({
                projectId: project.id,
                changes: { name: projectName },
              }),
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="w-full h-full grid grid-rows-[auto,1fr] min-h-full justify-items-center">
        <div className="w-full px-4">
          <div className="mx-auto w-full max-w-4xl border-b border-gray-200">
            <div className="flex items-center justify-between min-h-[52px]">
              <div className="flex flex-row space-x-2 items-center">
                {renderProjectName()}
                {shouldRenderTabs ? (
                  <Tabs
                    items={
                      project.mode === ProjectModesEnum.VOICE_AGENT
                        ? [
                            ProjectDetailsTabsEnum.ProjectConfiguration,
                            ProjectDetailsTabsEnum.Interviews,
                          ]
                        : tabs
                    }
                    currentTab={tab}
                    onSelectTab={(tab) => {
                      navigateToTab(tab as ProjectDetailsTabsEnum);
                    }}
                  />
                ) : null}
              </div>
              <div className="flex items-center space-x-3">
                <ProjectOwnersWidget
                  teammateIds={project.owner_teammate_ids}
                  onTeammateIdsSave={(teammateIds) =>
                    onSaveProject({ owner_teammate_ids: teammateIds }, true)
                  }
                />
                {renderActionButtons()}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-full overflow-auto px-4">
          {maybeRenderInterviewLimitWarning()}
          {renderPageContent()}
          <InterviewSlideoverContainer />
        </div>
      </div>

      <SimpleModal
        isOpen={isLaunchModalOpen}
        variant={ModalVariantsEnum.Standard}
        icon={
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
            <RocketLaunchIcon
              className="h-6 w-6 text-blue-600"
              aria-hidden="true"
            />
          </div>
        }
        title="Launch project"
        subtitle={
          <div className="text-left">
            {project.mode === ProjectModesEnum.VOICE_AGENT
              ? `• This project's phone number extension will be enabled and interviews will be saved when participants call in`
              : `• This project's interview link (${interviewLink}) will be enabled: you can send it to participants you want to interview`}
            <br />
            {pending_interview_count > 0 && (
              <>
                {`• Outreach emails will be sent to ${pending_interview_count} participant${
                  pending_interview_count > 1 ? "s" : ""
                }.`}
                <br />
              </>
            )}
            {project.incentive_quantity
              ? `• Each participant that completes an interview will receive a $${project.incentive_quantity} gift card`
              : ""}
          </div>
        }
        confirmButtonText="Launch"
        onCancel={() => {
          setIsLaunchModalOpen(false);
        }}
        onConfirm={onConfirmLaunch}
      />

      <SimpleModal
        isOpen={isPauseModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="Pause project"
        subtitle={`If you pause this project, any in progress interviews will be cancelled and you'll need to relaunch this project to run more interviews.`}
        confirmButtonText="Pause project"
        onCancel={() => {
          setIsPauseModalOpen(false);
        }}
        onConfirm={onConfirmPause}
      />

      <SimpleModal
        isOpen={isDeleteWarningModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="Delete project"
        subtitle="If you delete this project, insights from past interviews will be permanently lost and any outstanding interviews will be cancelled."
        confirmButtonText="Delete project"
        onCancel={() => {
          setIsDeleteWarningModalOpen(false);
        }}
        onConfirm={onConfirmDelete}
      />

      {project.mode === ProjectModesEnum.VOICE_AGENT ? (
        <VoiceModeShareTemplatesModal
          isOpen={isSharingTemplatesModalOpen}
          onClose={() => {
            setIsSharingTemplatesModalOpen(false);
          }}
          project={project}
          company={company}
        />
      ) : (
        <InterviewLinkTipsModal
          isOpen={isSharingTemplatesModalOpen}
          incentiveQuantity={project.incentive_quantity}
          link={interviewLink}
          onClose={() => {
            setIsSharingTemplatesModalOpen(false);
          }}
          numQuestions={project.questions.length}
        />
      )}

      <SimpleModal
        isOpen={isDuplicateProjectModalOpen}
        variant={ModalVariantsEnum.Standard}
        title="Duplicate project"
        subtitle="A new project will be created with the same questions and settings as this project."
        confirmButtonText="Continue"
        onCancel={() => {
          setIsDuplicateProjectModalOpen(false);
        }}
        onConfirm={onConfirmDuplicateProject}
      />

      <SimpleModal
        isOpen={isCsvExportModalOpen}
        variant={ModalVariantsEnum.Standard}
        onCancel={() => {
          setIsCsvExportModalOpen(false);
        }}
        title="Export interviews to CSV"
        subtitle="Interview data, transcripts, and insights for this project will be exported to a CSV. This may take up to a minute. "
        confirmButtonText="Continue"
        isPrimaryButtonLoading={isCsvExportLoading}
        onConfirm={handleCsvExport}
      />
    </>
  );
};
