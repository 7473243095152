import { FC, ReactNode } from "react";

export interface SettingsSectionProps {
  children: ReactNode;
}

export const SettingsSection: FC<SettingsSectionProps> = (props) => (
  <div className="max-w-2xl min-w-[450px] mx-auto px-8 py-6 flex flex-col space-y-6">
    {props.children}
  </div>
);
