import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatesEnum } from "app-types";
import { isAxiosError } from "axios";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";

const initialState: {
  domains: string[];
  status: LoadingStatesEnum;
  error: string | null;
} = {
  domains: [],
  status: LoadingStatesEnum.LOADED,
  error: null,
};

export const fetchCompanyDomains = createAsyncThunk(
  "companyDomains/fetch",
  async (_parameters, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get("domains");

      return thunkAPI.fulfillWithValue(response.data.domains);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateCompanyDomains = createAsyncThunk(
  "companyDomains/update",
  async (domains: string[], { dispatch, rejectWithValue }) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      await axios.post("/domains", { domains });

      return domains;
    } catch (err: any) {
      if (isAxiosError(err) && err.response?.data?.error) {
        return rejectWithValue(err.response.data.error);
      }

      return rejectWithValue(
        "An unknown error occurred while updating domains",
      );
    }
  },
);

// Slice
const companyDomainsSlice = createSlice({
  name: "companyDomains",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyDomains.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
        state.error = null;
      })
      .addCase(fetchCompanyDomains.fulfilled, (state, action) => {
        state.domains = action.payload;
        state.status = LoadingStatesEnum.LOADED;
      })
      .addCase(fetchCompanyDomains.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      })
      .addCase(updateCompanyDomains.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
        state.error = null;
      })
      .addCase(updateCompanyDomains.fulfilled, (state, action) => {
        state.domains = action.payload || [];
        state.status = LoadingStatesEnum.LOADED;
      })
      .addCase(updateCompanyDomains.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;

        if (typeof action.payload === "string") {
          state.error = action.payload;
        } else {
          state.error = "An unknown error occurred while updating domains";
        }
      });
  },
});

export default companyDomainsSlice.reducer;

// Selectors
export const selectCompanyDomainsState = (state: RootState) =>
  state.companyDomains;
