import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { AppDispatch, RootState } from "../../app/store";

export enum NotificationTypeEnum {
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface Notification {
  id: string;
  primaryMessage: string;
  secondaryMessage?: string;
  type: NotificationTypeEnum;
}

// Notifications are stored from newest to oldest
const initialState: readonly Notification[] = [];

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      return [action.payload, ...state];
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      return state.filter((notification) => notification.id !== action.payload);
    },
  },
});

export const { addNotification, removeNotification } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;

export const selectNotifications = (state: RootState) => state.notifications;

// Helper function to create a notification and dismiss after 5 seconds
export const showNotification = (
  dispatch: AppDispatch,
  notification: Notification,
) => {
  dispatch(addNotification(notification));
  // Dismiss notification after 5 seconds
  setTimeout(() => {
    dispatch(removeNotification(notification.id));
  }, 6000);
};
