import { LockClosedIcon } from "@heroicons/react/24/outline";
import { getCompanyHasFeatureAccess, HandlerOf } from "app-types";
import { FC, useState } from "react";
import { Link, Textarea } from "ui";
import { useAppSelector } from "../../../hooks/hook";
import { UpgradeText } from "../../billing/upgradeText";
import { selectCompany } from "../../company/companySlice";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";

const MIN_CONTEXT_LENGTH = 15;
const MAX_CONTEXT_LENGTH = 3000;

interface AIContextSettingProps {
  context: string | null;
  onSave: HandlerOf<string | null>;
}

export const AIContextSetting: FC<AIContextSettingProps> = (props) => {
  const { onSave } = props;

  const company = useAppSelector(selectCompany);
  const isMissingFeatureAccess = Boolean(
    company && !getCompanyHasFeatureAccess(company, "project_level_context"),
  );

  const context = props.context ?? "";
  const [newContext, setNewContext] = useState(context);
  const [contextError, setContextError] = useState("");

  const upgradeSpan = isMissingFeatureAccess ? (
    <UpgradeText planFeatureName="project_level_context" />
  ) : null;

  const onClickSaveContext = (): void => {
    if (newContext && newContext.length < MIN_CONTEXT_LENGTH) {
      setContextError(
        `Must be at least ${MIN_CONTEXT_LENGTH.toString()} characters long`,
      );
      return;
    }

    if (newContext && newContext.length > MAX_CONTEXT_LENGTH) {
      setContextError(
        `Must be less than ${MAX_CONTEXT_LENGTH.toString()} characters long`,
      );
      return;
    }

    setContextError("");
    onSave(newContext);
  };

  return (
    <div className="flex flex-col space-y-3">
      <div>
        <Textarea
          label="Custom AI context"
          value={newContext}
          onChange={(evt) => {
            setContextError("");
            setNewContext(evt.target.value);
          }}
          maxLength={MAX_CONTEXT_LENGTH}
          placeholder="Add custom context about this project..."
          errorDescription={contextError}
          description={
            <>
              Custom context for this project that should be used to generate
              more relevant AI clarifying questions (max {MAX_CONTEXT_LENGTH}{" "}
              characters). When empty, your{" "}
              <Link href="/settings/organization" sameTab>
                product or service description
              </Link>{" "}
              will be used.{" "}
              <Link href="https://help.alpharun.com/en/articles/2105857">
                Check out the guide
              </Link>{" "}
              for examples.
              {upgradeSpan}
            </>
          }
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess}
          className="w-[700px]"
        />
      </div>
      {context !== newContext ? (
        <SaveAndCancelButtons
          onSave={onClickSaveContext}
          onCancel={() => {
            setNewContext(context || "");
            setContextError("");
          }}
        />
      ) : null}
    </div>
  );
};
