export enum InterviewLanguagesEnum {
  ENGLISH = "en-US",
  GERMAN = "de-DE",
  ENGLISH_AND_SPANISH = "en-US,es-ES",
}

export type TranslationLanguages =
  | InterviewLanguagesEnum.ENGLISH
  | InterviewLanguagesEnum.GERMAN;

export const TRANSLATION_LANGUAGES = [
  InterviewLanguagesEnum.ENGLISH,
  InterviewLanguagesEnum.GERMAN,
];

export const LANGUAGE_DISPLAY_NAMES: Record<InterviewLanguagesEnum, string> = {
  [InterviewLanguagesEnum.ENGLISH]: "English",
  [InterviewLanguagesEnum.GERMAN]: "German",
  [InterviewLanguagesEnum.ENGLISH_AND_SPANISH]:
    "English & Spanish Multilingual",
};

export const LANGUAGE_TO_FLAG = {
  [InterviewLanguagesEnum.ENGLISH]: "🇺🇸",
  [InterviewLanguagesEnum.GERMAN]: "🇩🇪",
  [InterviewLanguagesEnum.ENGLISH_AND_SPANISH]: "🇺🇸🇪🇸",
};

export const getAgentLanguageInstructions = (
  language: InterviewLanguagesEnum,
) => {
  if (language === InterviewLanguagesEnum.ENGLISH_AND_SPANISH) {
    return "Respond in English or Spanish, whichever the candidate is speaking.";
  }

  return `Always respond in ${LANGUAGE_DISPLAY_NAMES[language]}`;
};

export const getCandidateLanguageDescription = (
  language: InterviewLanguagesEnum,
) => {
  if (language === InterviewLanguagesEnum.ENGLISH_AND_SPANISH) {
    return "This interview can be conducted in English or Spanish, whichever you prefer.";
  }

  return `This interview will be conducted in ${LANGUAGE_DISPLAY_NAMES[language]}`;
};
