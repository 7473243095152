export const SUPABASE_PUBLIC_STORAGE_BUCKET_NAME = "public-assets";
export enum PublicStorageFoldersEnum {
  LOGOS = "logos",
  FAVICONS = "favicons",
}
export const getLogoUrl = (appUrl: string, logoFileName: string) => {
  return `${appUrl}/storage/v1/object/public/${SUPABASE_PUBLIC_STORAGE_BUCKET_NAME}/${PublicStorageFoldersEnum.LOGOS}/${logoFileName}`;
};

export const getFaviconUrl = (appUrl: string, faviconFileName: string) => {
  return `${appUrl}/storage/v1/object/public/${SUPABASE_PUBLIC_STORAGE_BUCKET_NAME}/${PublicStorageFoldersEnum.FAVICONS}/${faviconFileName}`;
};
