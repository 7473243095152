import type { TranscriptFragment } from "../client/client";

export enum TranscriptFragmentRolesEnum {
  AGENT = "agent",
  PARTICIPANT = "participant",
}

export interface TranscriptFragmentPublicAPI {
  role: TranscriptFragmentRolesEnum;
  transcript: string;
  started_at: string;
  ended_at: string;
}

export function mapInterviewTranscriptFragmentsToPublicAPIFormat(
  transcriptFragments: TranscriptFragment[],
): TranscriptFragmentPublicAPI[] {
  const apiFragments: TranscriptFragmentPublicAPI[] = [];
  transcriptFragments.forEach((fragment) => {
    if (fragment.question) {
      // Handle survey-style fragments by adding the question and answer as separate fragments
      apiFragments.push({
        role: TranscriptFragmentRolesEnum.AGENT,
        transcript: fragment.question,
        started_at: fragment.start_time,
        ended_at: fragment.end_time,
      });
      apiFragments.push({
        role: TranscriptFragmentRolesEnum.PARTICIPANT,
        transcript: fragment.text_transcript || "",
        started_at: fragment.start_time,
        ended_at: fragment.end_time,
      });
    } else if (fragment.role) {
      // Phone call fragments
      apiFragments.push({
        role: fragment.role,
        transcript: fragment.text_transcript || "",
        started_at: fragment.start_time,
        ended_at: fragment.end_time,
      });
    }
  });

  return apiFragments;
}
