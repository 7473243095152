import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { KeywordTracker, LoadingStatesEnum, Project } from "app-types";
import { Selector } from "react-redux";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";

export const keywordTrackersAdapter = createEntityAdapter<KeywordTracker>();
// KeywordTracker looks like: {
//   id: string;
//   name: string;
//   keywords:[{phrase: "..."}]
//   type: KeywordTrackerType;
//   created_at: string;
//   updated_at: string | null;
// };

const initialState = keywordTrackersAdapter.getInitialState<{
  status: LoadingStatesEnum;
  error: string | null;
}>({
  status: LoadingStatesEnum.LOADING,
  error: null,
});

export const updateKeywordTracker = createAsyncThunk<
  KeywordTracker,
  { id: string; changes: Partial<KeywordTracker> },
  { rejectValue: string }
>("keywordTrackers/updateKeywordTracker", async ({ id, changes }, thunkAPI) => {
  try {
    const axios = await getAxiosInstanceWithAuth();
    const response = await axios.put(`/keyword-trackers/${id}`, changes);

    return thunkAPI.fulfillWithValue(response.data);
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const keywordTrackersSlice = createSlice({
  name: "keywordTrackers",
  initialState,
  reducers: {
    keywordTrackerUpdated: keywordTrackersAdapter.updateOne,
    setAllKeywordTrackers: keywordTrackersAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAllKeywordTrackers, (state) => {
        state.status = LoadingStatesEnum.LOADED;
      })
      .addCase(updateKeywordTracker.pending, (state, action) => {
        // Optimistic update
        keywordTrackersAdapter.updateOne(state, {
          id: action.meta.arg.id,
          changes: action.meta.arg.changes,
        });

        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(updateKeywordTracker.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;

        keywordTrackersAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(updateKeywordTracker.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      });
  },
});

export const { keywordTrackerUpdated, setAllKeywordTrackers } =
  keywordTrackersSlice.actions;
export default keywordTrackersSlice.reducer;

// Retrieve basic keyword search trackers (excludes AI-generated response summaries)
export const selectCompanyCompetitorsTracker: Selector<
  RootState,
  KeywordTracker | undefined
> = createSelector(
  [(state: RootState) => state.keywordTrackers],
  (keywordTrackersState) => {
    const allTrackers = keywordTrackersAdapter
      .getSelectors()
      .selectAll(keywordTrackersState);
    return allTrackers.find((tracker) => tracker.type === "lexical");
  },
);

export const selectKeywordTrackersForProject = (
  project: Project,
): Selector<RootState, KeywordTracker[]> =>
  createSelector(
    [(state: RootState) => state.keywordTrackers],
    (keywordTrackersState) => {
      const allTrackers = keywordTrackersAdapter
        .getSelectors()
        .selectAll(keywordTrackersState);

      const projectQuestions = project.questions.map((q) => q.question);
      // Include competitive tracker for this project and question summary trackers for each project question
      return allTrackers
        .filter(
          (tracker) =>
            (tracker.type === "competitive" &&
              tracker.project_id === project.id) ||
            (tracker.type === "question_summary" &&
              tracker.project_id === project.id &&
              tracker.question &&
              projectQuestions.indexOf(tracker.question) > -1), // Dont show response summaries for older questions
        )
        .sort((a, b) => {
          // Show competitive tracker before question summary trackers
          // Order question summary trackers in the same order as the project question
          if (a.type === "competitive" && b.type !== "competitive") {
            return -1; // a should come after b
          } else if (a.type !== "competitive" && b.type === "competitive") {
            return 1; // a should come before b
          }

          // For "QUESTION_SUMMARY" trackers, sort by the index of tracker.question in project.questions list
          if (a.type === "question_summary" && b.type === "question_summary") {
            const aIndex = a.question
              ? projectQuestions.indexOf(a.question)
              : -1;
            const bIndex = b.question
              ? projectQuestions.indexOf(b.question)
              : -1;
            return aIndex - bIndex; // Sort by ascending index
          }

          return 0; // No sorting criteria met, keep original order
        });
    },
  );
