import { useAppSelector } from "../../hooks/hook";
import { selectSurveySummaries } from "./insightsSlice";
import { MatrixSummaryCard } from "./matrixSummaryCard";
import { MultipleChoiceSummaryCard } from "./multipleChoiceSummaryCard";

export const SurveyInsightsSection = () => {
  const surveySummaries = useAppSelector(selectSurveySummaries);

  if (surveySummaries.length === 0) return null;

  return (
    <>
      {surveySummaries.map((summary, index) => {
        switch (summary.type) {
          case "multiple_choice":
            return (
              <MultipleChoiceSummaryCard
                key={`survey-summary-${index}`}
                summary={summary}
              />
            );
          case "rating":
            return (
              <MatrixSummaryCard
                key={`survey-summary-${index}`}
                summary={summary}
              />
            );
        }
      })}
    </>
  );
};
