import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { nanoid } from "nanoid";
import { FC, useState } from "react";
import { DropdownMenu, ModalVariantsEnum, SimpleModal } from "ui";
import { useAppDispatch } from "../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../notificationsOverlay/notificationsSlice";
import { updateProject } from "./projectsSlice";

interface ProjectMoreMenuProps {
  onClickPause?: () => void;
  onClickDeleteProject: () => void;
  onClickCopyInterviewLink?: () => void;
  onClickViewDialInstructions?: () => void;
  onClickCopyProjectId: () => void;
  onClickDuplicateProject: () => void;
  onClickCsvExport: () => void;
  projectId: string;
  inviteLinkUrl: string;
}

interface MenuItem {
  label: string;
  onClick: () => void;
}

export const ProjectMoreMenu: FC<ProjectMoreMenuProps> = ({
  onClickPause,
  onClickDeleteProject,
  onClickCopyInterviewLink,
  onClickViewDialInstructions,
  onClickCopyProjectId,
  onClickDuplicateProject,
  onClickCsvExport,
  projectId,
  inviteLinkUrl,
}) => {
  const dispatch = useAppDispatch();
  const [isRegenerateLinkModalOpen, setIsRegenerateLinkModalOpen] =
    useState(false);
  const [isInterviewLinkSaveInProgress, setIsInterviewLinkSaveInProgress] =
    useState(false);

  const handleRegenerateInterviewLink = async () => {
    setIsInterviewLinkSaveInProgress(true);
    try {
      await dispatch(
        updateProject({
          projectId: projectId,
          changes: {
            short_id: nanoid(),
          },
        }),
      );

      showNotification(dispatch, {
        id: `interviewlink-${projectId}-regenerated-${new Date().getTime()}`,
        primaryMessage: `Interview link refreshed`,
        type: NotificationTypeEnum.SUCCESS,
      });

      setIsRegenerateLinkModalOpen(false);
    } catch (err) {
      console.error("error regenerating interview link", err);
      showNotification(dispatch, {
        id: `interviewlink-${projectId}-regenerate-failed-${new Date().getTime()}`,
        primaryMessage: `Failed to refresh interview link`,
        type: NotificationTypeEnum.FAILURE,
      });
    } finally {
      setIsInterviewLinkSaveInProgress(false);
    }
  };

  const menuItems: MenuItem[] = [
    { label: "Pause", onClick: onClickPause },
    { label: "Copy Interview Link", onClick: onClickCopyInterviewLink },
    {
      label: "View Dialing Instructions",
      onClick: onClickViewDialInstructions,
    },
    { label: "Copy Project ID", onClick: onClickCopyProjectId },
    { label: "Duplicate Project", onClick: onClickDuplicateProject },
    { label: "CSV Export", onClick: onClickCsvExport },
    {
      label: "Refresh Interview Link",
      onClick: () => setIsRegenerateLinkModalOpen(true),
    },
    { label: "Delete Project", onClick: onClickDeleteProject },
  ].filter((item): item is MenuItem => item.onClick !== undefined);

  return (
    <>
      <Menu as="div" className="relative ml-3">
        <Menu.Button className="relative flex items-center ring-1 ring-inset ring-gray-300 bg-white p-1.5 hover:bg-gray-50 rounded-md shadow-sm text-gray-900 disabled:bg-slate-300 disabled:text-slate-500">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          <EllipsisVerticalIcon className="w-6 h-6" />
        </Menu.Button>
        <DropdownMenu items={menuItems} />
      </Menu>
      <SimpleModal
        isOpen={isRegenerateLinkModalOpen}
        variant={ModalVariantsEnum.Warning}
        title="Refresh interview link"
        subtitle={`If you continue, your current link (${inviteLinkUrl}) will be disabled, and a new link will be generated. Interviews already conducted using the old link will be preserved.`}
        confirmButtonText="Refresh link"
        onCancel={() => setIsRegenerateLinkModalOpen(false)}
        onConfirm={handleRegenerateInterviewLink}
        isPrimaryButtonLoading={isInterviewLinkSaveInProgress}
      />
    </>
  );
};
