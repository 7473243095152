import type {
  TablesUpdateWithFixedTypes,
  TablesWithFixedTypes,
} from "app-types";
import { compact } from "lodash";

export type TeammateDB = TablesWithFixedTypes<"teammate">;
export type TeammateUpdateDB = TablesUpdateWithFixedTypes<"teammate">;

export interface Teammate {
  id: string;
  company_id: string | null;
  email: string;
  first_name: string | null;
  last_name: string | null;
  profile_picture_url: string | null;
  created_at: string;
  updated_at: string | null;
}

export function mapTeammateDBToTeammate(teammateDB: TeammateDB) {
  const teammate: Teammate = {
    id: teammateDB.id,
    email: teammateDB.email,
    company_id: teammateDB.company_id,
    first_name: teammateDB.first_name,
    last_name: teammateDB.last_name,
    profile_picture_url: teammateDB.profile_picture_url,
    created_at: teammateDB.created_at,
    updated_at: teammateDB.updated_at,
  };
  return teammate;
}

export function renderTeammateDisplayName(teammate: Teammate): string;
export function renderTeammateDisplayName(
  teammate: Teammate | undefined,
): string | undefined;
export function renderTeammateDisplayName(
  teammate: Teammate | undefined,
): string | undefined {
  if (!teammate) {
    return undefined;
  }

  const parts = compact([teammate.first_name, teammate.last_name]);
  return parts.length > 0 ? parts.join(" ") : teammate.email;
}
