import { TrashIcon } from "@heroicons/react/24/outline";
import {
  Company,
  LoadingStatesEnum,
  getCompanyHasFeatureAccess,
  getFaviconUrl,
  getLogoUrl,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, FileUpload } from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import { UpsellBanner } from "../../billing/upsellBanner";
import { setCompany } from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

interface CompanyImageEditorProps {
  company: Company;
  type: "logo" | "favicon";
}

export const CompanyImageEditor: FC<CompanyImageEditorProps> = ({
  company,
  type,
}) => {
  const dispatch = useAppDispatch();

  const [uploadLoadingState, setUploadLoadingState] = useState(
    LoadingStatesEnum.LOADED,
  );
  const [deleteLoadingState, setDeleteLoadingState] = useState(
    LoadingStatesEnum.LOADED,
  );

  const imageUrl =
    type === "logo"
      ? company.settings.logo_file_name
        ? getLogoUrl(
            import.meta.env.VITE_SUPABASE_APP_URL,
            company.settings.logo_file_name,
          )
        : null
      : company.settings.favicon_file_name
        ? getFaviconUrl(
            import.meta.env.VITE_SUPABASE_APP_URL,
            company.settings.favicon_file_name,
          )
        : null;

  const onFileUpload = async (file: File) => {
    setUploadLoadingState(LoadingStatesEnum.LOADING);
    const formData = new FormData();
    formData.append(type, file);

    try {
      const axios = await getAxiosInstanceWithAuth();

      // Remove the existing image if necessary
      if (imageUrl) {
        await axios.delete(`/company/${type}`);
      }

      const { data } = await axios.post(`/company/${type}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setUploadLoadingState(LoadingStatesEnum.LOADED);
      dispatch(
        setCompany({
          ...company,
          settings: {
            ...company.settings,
            [type === "logo" ? "logo_file_name" : "favicon_file_name"]:
              data[`${type}_file_name`],
          },
        }),
      );
      return data;
    } catch (error) {
      showNotification(dispatch, {
        id: `${type}-error-${new Date().getTime()}`,
        primaryMessage: `An error occurred - please try again`,
        type: NotificationTypeEnum.FAILURE,
      });

      setUploadLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const onRemoveImage = async () => {
    setDeleteLoadingState(LoadingStatesEnum.LOADING);

    try {
      const axios = await getAxiosInstanceWithAuth();
      await axios.delete(`/company/${type}`);
      setDeleteLoadingState(LoadingStatesEnum.LOADED);
      dispatch(
        setCompany({
          ...company,
          settings: {
            ...company.settings,
            [type === "logo" ? "logo_file_name" : "favicon_file_name"]: null,
          },
        }),
      );
    } catch (error) {
      showNotification(dispatch, {
        id: `${type}-error-${new Date().getTime()}`,
        primaryMessage: `An error occurred - please try again`,
        type: NotificationTypeEnum.FAILURE,
      });

      setDeleteLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling",
  );

  return (
    <div>
      {imageUrl ? (
        <div className="my-2">
          <img
            src={imageUrl}
            alt={`Company ${type}`}
            className={
              type === "logo"
                ? "max-w-[150px] max-h-[50px]"
                : "max-w-[32px] max-h-[32px]"
            }
          />
        </div>
      ) : null}
      <div className="flex flex-row gap-3 mt-2">
        <FileUpload
          onUpload={onFileUpload}
          isLoading={uploadLoadingState === LoadingStatesEnum.LOADING}
          label={imageUrl ? `Change ${type}` : `Upload ${type}`}
          isDisabled={isMissingFeatureAccess}
        />
        {imageUrl ? (
          <Button
            icon={<TrashIcon className="w-4 h-4 mr-2" />}
            variant={ButtonVariantsEnum.Warning}
            onClick={onRemoveImage}
            label={`Remove ${type}`}
            isLoading={deleteLoadingState === LoadingStatesEnum.LOADING}
          />
        ) : null}
      </div>
      <div className="mt-2 text-sm text-gray-500">
        {type === "logo"
          ? "This logo (.png or .jpeg) will be displayed on the welcome screen for all interviews. We recommend an image width of at least 100px and aspect ratio between 1:1 to 4:1."
          : "This favicon (.ico, .png, or .svg) will be displayed in the browser tab. We recommend a square image with dimensions of 16x16, 32x32, or 64x64 pixels."}
      </div>
      {isMissingFeatureAccess ? (
        <div className="mt-2">
          <UpsellBanner planFeatureName="whitelabeling" />
        </div>
      ) : null}
    </div>
  );
};
