import {
  InterviewLanguagesEnum,
  LANGUAGE_DISPLAY_NAMES,
  LANGUAGE_TO_FLAG,
} from "app-types";
import { useState } from "react";
import { Label, Link, Select, SizesEnum } from "ui";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";

interface InterviewLanguageSettingProps {
  language: InterviewLanguagesEnum;
  onSave: (language: InterviewLanguagesEnum) => void;
}

export const InterviewLanguageSetting = ({
  language,
  onSave,
}: InterviewLanguageSettingProps) => {
  const [selectedLanguage, setSelectedLanguage] = useState(language);

  const languageOptions = Object.entries(InterviewLanguagesEnum).map(
    ([_, value]) => ({
      name: `${LANGUAGE_TO_FLAG[value]}\u00A0\u00A0${LANGUAGE_DISPLAY_NAMES[value]}`,
      value,
    }),
  );

  const hasChanges = selectedLanguage !== language;

  return (
    <div className="space-y-2">
      <Label size={SizesEnum.SMALL}>Interview language</Label>
      <div className="w-[280px]">
        <Select
          currentSelection={languageOptions.find(
            (option) => option.value === selectedLanguage,
          )}
          onChange={(option) => {
            setSelectedLanguage(option.value as InterviewLanguagesEnum);
          }}
          options={languageOptions}
        />
      </div>
      <div className="text-sm text-slate-600">
        Select the language that the AI interviewer will use during interviews.
        Your interview questions do not need to match this language.{" "}
        <Link href="mailto:support@alpharun.com">Reach out to support</Link> to
        access additional languages.
      </div>
      {hasChanges ? (
        <SaveAndCancelButtons
          onSave={() => {
            onSave(selectedLanguage);
          }}
          onCancel={() => {
            setSelectedLanguage(language);
          }}
        />
      ) : null}
    </div>
  );
};
