import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Handler, noop } from "app-types";
import React, { FC, Fragment, ReactNode, useState } from "react";
import { Button, ButtonVariantsEnum } from "../Buttons/button";
import { SizesEnum } from "../helpers/helpers";

interface ModalProps {
  isOpen: boolean;
  onCancel?: () => void;
  children?: React.ReactNode;
  size?: SizesEnum.LARGE | SizesEnum.SMALL;
}

export enum SimpleModalVariantsEnum {
  Standard = "STANDARD",
  Warning = "WARNING",
}

interface SimpleModalProps extends ModalProps {
  variant: SimpleModalVariantsEnum;
  icon?: ReactNode;
  title: string;
  subtitle: string | ReactNode;
  confirmButtonText: string;
  onConfirm: Handler;
  isPrimaryButtonLoading?: boolean;
}

export const Modal: React.FC<ModalProps> = (props) => {
  const { isOpen, onCancel, size, children } = props;

  // To avoid rendering stale content during the leave animation. Inspired by react-spectrum's DialogContainer and framer-motion's AnimatePresence.
  const [openChildren, setOpenChildren] = useState<ReactNode>(null);

  if (isOpen && openChildren !== children) {
    setOpenChildren(children);
    // Short-circuit this render.
    return null;
  }

  const afterLeave = (): void => {
    setOpenChildren(null);
  };

  return (
    <Transition.Root afterLeave={afterLeave} as={Fragment} show={isOpen}>
      <Dialog className="relative z-10" onClose={onCancel || noop}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full ${
                  size === SizesEnum.LARGE ? "sm:max-w-4xl" : "sm:max-w-lg"
                } sm:p-6`}
              >
                {openChildren}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const SimpleModal: FC<SimpleModalProps> = (props) => {
  const {
    isOpen,
    variant,
    icon,
    title,
    subtitle,
    confirmButtonText,
    onCancel,
    onConfirm,
    isPrimaryButtonLoading,
    size,
  } = props;

  const renderIcon = (): JSX.Element | null => {
    if (variant === SimpleModalVariantsEnum.Warning) {
      return (
        <div className="mb-3 m:mt-5 mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="h-6 w-6 text-red-600"
          />
        </div>
      );
    }

    if (!icon) return null;

    return <div className="mb-4">{icon}</div>;
  };

  const renderPrimaryButton = (): JSX.Element => {
    if (variant === SimpleModalVariantsEnum.Warning)
      return (
        <Button
          isLoading={isPrimaryButtonLoading}
          label={confirmButtonText}
          onClick={onConfirm}
          variant={ButtonVariantsEnum.Warning}
        />
      );

    return (
      <Button
        isLoading={isPrimaryButtonLoading}
        label={confirmButtonText}
        onClick={onConfirm}
        variant={ButtonVariantsEnum.Primary}
      />
    );
  };

  const renderButtons = (): JSX.Element => {
    if (onCancel)
      return (
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <Button
            label="Cancel"
            onClick={onCancel}
            variant={ButtonVariantsEnum.Secondary}
          />
          {renderPrimaryButton()}
        </div>
      );

    // If no cancel handler, only render the primary button
    return (
      <div className="mt-5 sm:mt-6 flex justify-center">
        {renderPrimaryButton()}
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} onCancel={onCancel} size={size}>
      <>
        <div>
          {renderIcon()}
          <div className="text-center">
            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
              {title}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500">{subtitle}</p>
            </div>
            {props.children}
          </div>
        </div>
        {renderButtons()}
      </>
    </Modal>
  );
};
