import { TrashIcon } from "@heroicons/react/24/outline";
import { CompanyInvitation, HandlerOf, Teammate } from "app-types";
import { format } from "date-fns";
import { FC, useState } from "react";
import {
  ButtonVariantsEnum,
  IconButton,
  ModalVariantsEnum,
  Pill,
  PillColorsEnum,
  SimpleModal,
  SizesEnum,
  TableSkeleton,
} from "ui";
import { SettingsTable } from "../shared/settingsTable";
import { SettingsTableBodySection } from "../shared/settingsTableBodySection";
import { SettingsTableDataCell } from "../shared/settingsTableDataCell";
import { SettingsTableErrorBodyContent } from "../shared/settingsTableErrorBodyContent";
import { SettingsTableHeaderCell } from "../shared/settingsTableHeaderCell";
import { SettingsTableHeaderSection } from "../shared/settingsTableHeaderSection";

interface TeammatesTableProps {
  teammates: Teammate[];
  pendingInvitations: CompanyInvitation[];
  onDeleteInvitation: HandlerOf<string>;
  isLoading: boolean;
  hasError: boolean;
}

export const TeammatesTable: FC<TeammatesTableProps> = ({
  pendingInvitations,
  teammates,
  onDeleteInvitation,
  isLoading,
  hasError,
}) => {
  const [invitationToDelete, setInvitationToDelete] = useState<string | null>(
    null,
  );

  // Filter out any invitations for existing teammates
  const pendingInvitationsToRender = pendingInvitations.filter((i) => {
    return !teammates.find((t) => t.email === i.recipient_email);
  });

  const invitationRows = pendingInvitationsToRender.map((i) => {
    return {
      id: i.id,
      isInvitation: true,
      email: i.recipient_email,
      status: "Invited",
      dateAddedTimestamp: new Date(i.created_at).getTime(),
    };
  });

  const teammateRows = teammates.map((t) => {
    return {
      id: t.id,
      isInvitation: false,
      email: t.email,
      status: "Active",
      dateAddedTimestamp: new Date(t.created_at).getTime(),
    };
  });

  // Display rows sorted from newest to oldest
  const rows = [...invitationRows, ...teammateRows].sort(
    (a, b) => b.dateAddedTimestamp - a.dateAddedTimestamp,
  );

  const renderTableRows = () => {
    if (isLoading) return <TableSkeleton />;

    if (hasError)
      return (
        <SettingsTableErrorBodyContent colSpan={3}>
          An error occurred. Please refresh the page and try again.
        </SettingsTableErrorBodyContent>
      );

    return (
      <>
        {rows.map((row) => (
          <tr className="group relative" key={row.id}>
            <SettingsTableDataCell>{row.email}</SettingsTableDataCell>
            <SettingsTableDataCell>
              <Pill
                label={row.status}
                size={SizesEnum.SMALL}
                color={
                  row.isInvitation
                    ? PillColorsEnum.YELLOW
                    : PillColorsEnum.GREEN
                }
              />
            </SettingsTableDataCell>
            <SettingsTableDataCell>
              {format(new Date(row.dateAddedTimestamp), "MMM dd yyyy")}
              {row.isInvitation && (
                <div className="text-gray-500 absolute right-2 top-3 cursor-pointer hidden group-hover:block">
                  <IconButton
                    variant={ButtonVariantsEnum.Secondary}
                    icon={<TrashIcon className="h-4 w-4" />}
                    onClick={() => {
                      setInvitationToDelete(row.id);
                    }}
                  />
                </div>
              )}
            </SettingsTableDataCell>
          </tr>
        ))}
      </>
    );
  };

  return (
    <>
      <SettingsTable>
        <SettingsTableHeaderSection>
          <tr>
            <SettingsTableHeaderCell className="w-[55%]">
              Email
            </SettingsTableHeaderCell>
            <SettingsTableHeaderCell className="w-[20%]">
              Status
            </SettingsTableHeaderCell>
            <SettingsTableHeaderCell className="w-[25%]">
              Date added
            </SettingsTableHeaderCell>
          </tr>
        </SettingsTableHeaderSection>
        <SettingsTableBodySection>{renderTableRows()}</SettingsTableBodySection>
      </SettingsTable>
      <SimpleModal
        isOpen={Boolean(invitationToDelete)}
        onCancel={() => {
          setInvitationToDelete(null);
        }}
        variant={ModalVariantsEnum.Warning}
        title="Revoke invitation"
        subtitle="Are you sure you want to cancel this invitation?"
        confirmButtonText="Cancel invitation"
        onConfirm={() => {
          invitationToDelete && onDeleteInvitation(invitationToDelete);
          setInvitationToDelete(null);
        }}
      />
    </>
  );
};
