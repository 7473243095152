import { FC, ReactNode } from "react";

export interface SettingsTableProps {
  children: ReactNode;
}

export const SettingsTable: FC<SettingsTableProps> = (props) => (
  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
    <table className="min-w-full divide-y divide-gray-200 table-fixed w-full">
      {props.children}
    </table>
  </div>
);
