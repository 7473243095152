import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { InterviewDecision } from "app-types";
import { InfoTooltip } from "ui";

export const InterviewDecisionIndicator = ({
  decision,
}: {
  decision: InterviewDecision | null;
}) => {
  if (decision === "advanced") {
    return (
      <InfoTooltip
        content="Candidate was advanced"
        id="decision-advanced"
        place="right"
      >
        <CheckCircleIcon className="h-4 w-4 flex-shrink-0 text-green-500" />
      </InfoTooltip>
    );
  }

  if (decision === "rejected") {
    return (
      <InfoTooltip
        content="Candidate was rejected"
        id="decision-rejected"
        place="right"
      >
        <XCircleIcon className="h-4 w-4 flex-shrink-0 text-red-500" />
      </InfoTooltip>
    );
  }

  return null;
};
