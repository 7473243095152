import { Teammate } from "app-types";
import { FC } from "react";
import { joinClassnames } from "ui";
import { TeammateAvatar } from "./teammateAvatar";

export interface TeammateAvatarPileProps {
  className?: string;
  teammates: readonly (Teammate | null | undefined)[];
}

export const TeammateAvatarPile: FC<TeammateAvatarPileProps> = (props) => {
  const { teammates } = props;

  return (
    <div
      className={joinClassnames(
        "flex flex-row shrink-0 -space-x-2",
        props.className,
      )}
    >
      {teammates.map((teammate, index) => (
        <TeammateAvatar key={teammate?.id ?? index} teammate={teammate} />
      ))}
    </div>
  );
};
