import {
  CustomFieldDefinition,
  CustomFieldMetadata,
  CustomFieldResourceTypesEnum,
  LoadingStatesEnum,
} from "app-types";
import { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  SearchableSelect,
  SearchableSelectModesEnum,
  SearchableSelectOption,
  toHumanReadableString,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

interface IntegrationFieldPickerProps {
  resourceType:
    | CustomFieldResourceTypesEnum.ACCOUNT
    | CustomFieldResourceTypesEnum.CONTACT;
  integrationId: string;
  selectedCustomFieldDefinitions: CustomFieldDefinition[];
  onSave: (fieldDefinitions: CustomFieldMetadata[]) => void;
}

const resourceTypeToDescriptionCopy = {
  [CustomFieldResourceTypesEnum.ACCOUNT]:
    "The account's name and ID will be automatically synced.",
  [CustomFieldResourceTypesEnum.CONTACT]:
    "The contact's ID, name, and associated account will be automatically synced.",
};

const resourceTypeToRecommendedFields = {
  [CustomFieldResourceTypesEnum.ACCOUNT]: ["OwnerId", "Website", "Industry"],
  [CustomFieldResourceTypesEnum.CONTACT]: [],
};

export const IntegrationFieldPicker: FC<IntegrationFieldPickerProps> = ({
  resourceType,
  selectedCustomFieldDefinitions: selectedFieldDefinitions,
  onSave,
  integrationId,
}) => {
  const dispatch = useAppDispatch();

  const initialFields = useMemo(
    () =>
      selectedFieldDefinitions.map((fd) => {
        return {
          id: fd.field_name,
          name: fd.display_name,
        };
      }),
    [selectedFieldDefinitions],
  );

  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [selectedFields, setSelectedFields] =
    useState<SearchableSelectOption[]>(initialFields);
  const [allAvailableFields, setAllAvailableFields] = useState<
    CustomFieldMetadata[]
  >([]);
  const [fieldsLoadingState, setFieldsLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADING);

  const multiSelectOptions = useMemo(
    () =>
      allAvailableFields.map((field) => ({
        id: field.field_name,
        name: field.display_name,
      })),
    [allAvailableFields],
  );

  useEffect(() => {
    const fetchFields = async () => {
      // Retrieve available fields from the CRM
      try {
        const axios = await getAxiosInstanceWithAuth();
        const { data } = await axios.get(
          `/integrations/${integrationId}/fields?resource_type=${resourceType}`,
        );

        setAllAvailableFields(data);
        setFieldsLoadingState(LoadingStatesEnum.LOADED);
      } catch (err) {
        setFieldsLoadingState(LoadingStatesEnum.ERROR);
      }
    };

    fetchFields();
  }, []);

  const recommendedFields = useMemo(() => {
    // Return recommended fields that match this salesforce instance and aren't already added
    return resourceTypeToRecommendedFields[resourceType]
      ?.filter((rf) => {
        return allAvailableFields.some((af) => af.field_name === rf);
      })
      .filter((recommendedFieldName) => {
        return !selectedFields.some(
          (selectedField) => selectedField.id === recommendedFieldName,
        );
      });
  }, [resourceType, allAvailableFields, selectedFields]);

  return (
    <div>
      <SearchableSelect
        mode={SearchableSelectModesEnum.MULTI}
        label={toHumanReadableString(`${resourceType} Fields`)}
        options={multiSelectOptions}
        selectedOptions={selectedFields}
        onChange={(options: SearchableSelectOption[]) => {
          if (options.length > 15) {
            showNotification(dispatch, {
              id: `limit-reached-${new Date().getTime()}`,
              primaryMessage:
                "Limit of 15 fields reached. If you need more fields, please reach out to support@alpharun.com.",
              type: NotificationTypeEnum.FAILURE,
            });
            return;
          }

          setHasMadeChanges(true);
          setSelectedFields(options);
        }}
        placeholder={`Search for ${resourceType} fields to display...`}
        shouldDisableCustomOptions={true}
      />
      <div className="mt-2 text-sm text-gray-600">
        {resourceTypeToDescriptionCopy[resourceType]}
        {recommendedFields.length > 0 ? (
          <>
            {" "}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                setHasMadeChanges(true);

                setSelectedFields([
                  ...selectedFields,
                  ...recommendedFields.map((rf) => ({ id: rf, name: rf })),
                ]);
              }}
            >
              Add recommended fields
            </span>
            {"."}
          </>
        ) : null}
      </div>
      {hasMadeChanges && (
        <div className="flex mt-3 space-x-2">
          <Button
            variant={ButtonVariantsEnum.Secondary}
            onClick={() => {
              setSelectedFields(initialFields);
              setHasMadeChanges(false);
            }}
            label="Cancel"
          />
          <Button
            variant={ButtonVariantsEnum.Primary}
            label="Save changes"
            onClick={() => {
              const selectedFieldNames = selectedFields.map((sf) => sf.id);

              const customFieldMetadatas = allAvailableFields.filter((af) =>
                selectedFieldNames.includes(af.field_name),
              );

              onSave(customFieldMetadatas);
              setHasMadeChanges(false);
            }}
          />
        </div>
      )}
    </div>
  );
};
