export enum InterviewAgentTypesEnum {
  GenericInterviewer = "generic",
  RecruitingInterviewer = "recruiter",
}

export enum ProjectEmailDigestFrequencyEnum {
  INSTANT = "instant",
  DAILY = "daily",
}

export interface ProjectPublicAPI {
  id: string;
  name: string;
}
