import {
  BasicQuestion,
  Company,
  ComplexQuestion,
  ComplexQuestionInsert,
  Project,
  ProjectModesEnum,
  ProjectUpdate,
  isComplexProjectQuestion,
  isComplexProjectQuestionInsert,
  isValidComplexQuestion,
  reorderArrayItems,
} from "app-types";
import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  InfoTooltip,
  Label,
  ModalVariantsEnum,
  SimpleModal,
  SizesEnum,
  Slideover,
} from "ui";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { AddSurveyQuestionsDropdown } from "./addSurveyQuestionsDropdown";
import { BasicQuestionEditor } from "./basicQuestionEditor";
import { ComplexQuestionEditor } from "./complexQuestionEditor";
import { ImportQuestionsModal } from "./importQuestionsModal";
import { QuestionsManagerCell } from "./questionsManagerCell";

const MAX_INTERVIEW_QUESTIONS = 25;

interface QuestionsManagerSlideoverProps {
  complexQuestions: (ComplexQuestion | ComplexQuestionInsert)[];
  basicQuestions: BasicQuestion[];
  project: Project;
  company: Company;
  onSaveProject: (changes: ProjectUpdate) => void;
  isOpen: boolean;
  onClose: () => void;
}

const emptyBasicQuestion = { question: "" };

export const QuestionsManagerSlideover: FC<QuestionsManagerSlideoverProps> = (
  props,
) => {
  const { project, onSaveProject, isOpen, company } = props;

  const dispatch = useAppDispatch();

  // The question that's currently selected (can be survey question or interview question)
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [complexQuestions, setComplexQuestions] = useState<
    (ComplexQuestion | ComplexQuestionInsert)[]
  >(props.complexQuestions);
  const [basicQuestions, setBasicQuestions] = useState<BasicQuestion[]>(
    props.basicQuestions.length > 0
      ? props.basicQuestions
      : [emptyBasicQuestion],
  );
  const [hasMadeChanges, setHasMadeChanges] = useState(false);
  const [isSaveConfirmationModalOpen, setIsSaveConfirmationModalOpen] =
    useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const questionsContainerRef = useRef<HTMLDivElement>(null);

  // Since complex questions are shown first, we need to subtract the number of complex questions
  const basicQuestionIndex = selectedIndex - complexQuestions.length;
  const currentQuestion =
    selectedIndex < complexQuestions.length
      ? complexQuestions[selectedIndex]
      : basicQuestions[basicQuestionIndex];

  useEffect(() => {
    // When the slideover opens and closes, make sure we update our state with the latest questions
    setComplexQuestions(props.complexQuestions);
    setBasicQuestions(
      props.basicQuestions.length > 0
        ? props.basicQuestions
        : [emptyBasicQuestion],
    );
  }, [isOpen]);

  const onSave = () => {
    // For each complexQuestion that was edited, remove the ID so that the server treats it as a new question
    const updatedComplexQuestions = complexQuestions.map((q) => {
      const matchingOriginalQuestion = props.complexQuestions.find(
        (originalQuestion) =>
          "id" in q && "id" in originalQuestion && q.id === originalQuestion.id,
      );
      if (!_.isEqual(q, matchingOriginalQuestion)) {
        return {
          ...q,
          id: undefined,
        };
      }

      return q;
    });

    onSaveProject({
      questions: [
        ...updatedComplexQuestions,
        ...basicQuestions.filter((q) => q.question), // Filter out an empty basic questions
      ],
    });

    onClose();
  };

  const onClose = () => {
    // Reset questions to currently saved state
    setComplexQuestions(props.complexQuestions);
    setBasicQuestions(props.basicQuestions);
    setHasMadeChanges(false);
    setSelectedIndex(0);
    props.onClose();
  };

  const onClickReorderBasicQuestion = (
    index: number,
    direction: "up" | "down",
  ) => {
    // Reorder the index'th element in the questions array
    const newQuestions = reorderArrayItems(basicQuestions, index, direction);

    // If the reorder is invalid, don't update.
    if (!newQuestions) return;

    setBasicQuestions(newQuestions);
    setHasMadeChanges(true);
  };

  const onClickReorderComplexQuestion = (
    index: number,
    direction: "up" | "down",
  ) => {
    // Reorder the index'th element in the questions array
    const newQuestions = reorderArrayItems(complexQuestions, index, direction);

    // If the reorder is invalid, don't update.
    if (!newQuestions) return;

    setComplexQuestions(newQuestions);
    setHasMadeChanges(true);
  };

  const renderCurrentQuestionContent = () => {
    if (!currentQuestion) return null;

    if (
      isComplexProjectQuestion(currentQuestion) ||
      isComplexProjectQuestionInsert(currentQuestion)
    ) {
      return (
        <ComplexQuestionEditor
          question={currentQuestion}
          onChange={(question) => {
            // Replace question at the currentIndex
            setComplexQuestions(
              complexQuestions.map((q, i) =>
                i === selectedIndex ? question : q,
              ),
            );
            setHasMadeChanges(true);
          }}
        />
      );
    }

    return (
      <BasicQuestionEditor
        question={currentQuestion}
        onChange={(question) => {
          setBasicQuestions(
            basicQuestions.map((q, i) =>
              i === basicQuestionIndex ? question : q,
            ),
          );
          setHasMadeChanges(true);
        }}
        key={`basic-question-${basicQuestionIndex}`}
      />
    );
  };

  const noInterviewQuestionsOnLiveProject =
    basicQuestions.length === 0 && project.is_live;

  return (
    <>
      <Slideover
        title="Edit interview questions"
        onClickClose={onClose}
        shouldShow={isOpen}
        buttons={
          <>
            <Button
              variant={ButtonVariantsEnum.Primary}
              label="Save changes"
              onClick={() => {
                // We don't check for basicQuestion errors because we automatically filter out empty basic questions when saving
                const hasError = Boolean(
                  complexQuestions.find(
                    (q) => !isValidComplexQuestion(q, true),
                  ),
                );
                if (hasError) {
                  showNotification(dispatch, {
                    id: `questions-save-error-${new Date().getTime()}`,
                    primaryMessage:
                      "Some of your questions have errors - please fix them and try again.",
                    type: NotificationTypeEnum.FAILURE,
                  });
                  return;
                }

                if (project.is_live) setIsSaveConfirmationModalOpen(true);
                else onSave();
              }}
              size={SizesEnum.MEDIUM}
              isDisabled={!hasMadeChanges || noInterviewQuestionsOnLiveProject}
              tooltip={
                noInterviewQuestionsOnLiveProject
                  ? {
                      content:
                        "At least one interview question is required on a live project. If you'd like to stop interviews for this project, you can pause it instead.",
                      id: "no-questions-tooltip",
                    }
                  : undefined
              }
            />
            <Button
              variant={ButtonVariantsEnum.Secondary}
              label="Discard changes"
              onClick={onClose}
              size={SizesEnum.MEDIUM}
            />
          </>
        }
      >
        <div className="flex h-full bg-white flex-row divide-y divide-gray-100 text-sm leading-6">
          <div
            className="flex-shrink-0 w-[400px] border-r overflow-y-auto"
            ref={questionsContainerRef}
          >
            {project.mode === ProjectModesEnum.SURVEY ? (
              <>
                <div className="px-3 py-2 flex flex-row space-between items-center justify-between">
                  <div className="flex flex-row items-center space-x-2">
                    <Label size={SizesEnum.SMALL}>Survey questions</Label>
                    <span>
                      <InfoTooltip
                        id="ai-survey-questions-tooltip"
                        place="right"
                        content={
                          <span>
                            Use survey questions to collect structured,
                            quantitative data before the open-ended interview
                            questions.
                          </span>
                        }
                      />
                    </span>
                  </div>

                  <AddSurveyQuestionsDropdown
                    company={company}
                    project={project}
                    onAddQuestion={(newQuestion) => {
                      setComplexQuestions([...complexQuestions, newQuestion]);
                      setSelectedIndex(complexQuestions.length);
                      setHasMadeChanges(true);
                    }}
                    complexQuestions={complexQuestions}
                  />
                </div>
                {complexQuestions.map((question, index) => {
                  return (
                    <QuestionsManagerCell
                      key={`complex-question-${index}`}
                      questionNumber={index + 1}
                      question={question}
                      isSelected={selectedIndex === index}
                      onClick={() => {
                        setSelectedIndex(index);
                      }}
                      onRemove={() => {
                        setComplexQuestions(
                          complexQuestions.filter((_, i) => i !== index),
                        );
                        setHasMadeChanges(true);
                      }}
                      onClickMoveUp={
                        index !== 0
                          ? () => {
                              onClickReorderComplexQuestion(index, "up");
                            }
                          : undefined
                      }
                      onClickMoveDown={
                        index !== complexQuestions.length - 1
                          ? () => {
                              onClickReorderComplexQuestion(index, "down");
                            }
                          : undefined
                      }
                    />
                  );
                })}
              </>
            ) : null}
            <div
              className={`px-3 py-2 ${
                project.mode === ProjectModesEnum.SURVEY ? "mt-4" : "mt-0"
              } flex flex-row space-between items-center justify-between`}
            >
              <div className="flex flex-row items-center space-x-2">
                <Label size={SizesEnum.SMALL}>AI interview questions</Label>
                <span>
                  <InfoTooltip
                    id="ai-interview-questions-tooltip"
                    place="right"
                    content={
                      <span>
                        Participants answer these questions out loud one at a
                        time and we'll automatically ask clarifying questions
                        based on their answers. For the best insights, we
                        recommend simple, open-ended questions.
                      </span>
                    }
                  />
                </span>
              </div>
              <div className="flex space-x-2">
                <Button
                  label="Import"
                  onClick={() => {
                    setIsImportModalOpen(true);
                  }}
                  variant={ButtonVariantsEnum.Secondary}
                  size={SizesEnum.SMALL}
                />
                <Button
                  label="Add"
                  onClick={() => {
                    setBasicQuestions((previousBasicQuestions) => {
                      const newQuestions = [
                        ...previousBasicQuestions,
                        emptyBasicQuestion,
                      ];
                      // Update basicQuestions and immediately calculate and set selectedIndex
                      setSelectedIndex(
                        complexQuestions.length + newQuestions.length - 1,
                      );

                      setTimeout(() => {
                        if (questionsContainerRef.current) {
                          questionsContainerRef.current.scrollTop =
                            questionsContainerRef.current.scrollHeight;
                        }
                      }, 100);

                      return newQuestions;
                    });
                  }}
                  variant={ButtonVariantsEnum.Secondary}
                  size={SizesEnum.SMALL}
                  isDisabled={basicQuestions.length >= MAX_INTERVIEW_QUESTIONS}
                  tooltip={
                    basicQuestions.length >= MAX_INTERVIEW_QUESTIONS
                      ? {
                          content: `To ensure a good interview experience, we support up to ${MAX_INTERVIEW_QUESTIONS} interview questions.`,
                          id: "interview-questions-limit-tooltip",
                        }
                      : undefined
                  }
                />
              </div>
            </div>
            {basicQuestions.map((question, index) => {
              const questionNumber = complexQuestions.length + index + 1;
              return (
                <QuestionsManagerCell
                  key={`basic-question-${index}`}
                  questionNumber={questionNumber}
                  question={question}
                  isSelected={selectedIndex === complexQuestions.length + index}
                  onClick={() => {
                    setSelectedIndex(complexQuestions.length + index);
                  }}
                  onRemove={() => {
                    setHasMadeChanges(true);
                    setBasicQuestions(
                      basicQuestions.filter((_, i) => i !== index),
                    );
                  }}
                  onClickMoveUp={
                    index !== 0
                      ? () => {
                          onClickReorderBasicQuestion(index, "up");
                        }
                      : undefined
                  }
                  onClickMoveDown={
                    index !== basicQuestions.length - 1
                      ? () => {
                          onClickReorderBasicQuestion(index, "down");
                        }
                      : undefined
                  }
                />
              );
            })}
          </div>
          <div className="px-4 py-3 w-full bg-gray-50 overflow-y-auto">
            {renderCurrentQuestionContent()}
          </div>
        </div>
        <SimpleModal
          isOpen={isSaveConfirmationModalOpen}
          variant={ModalVariantsEnum.Standard}
          title="Update interview questions?"
          subtitle="Existing interview transcripts will remain unchanged, but future interviews and insights will use latest version of your questions."
          confirmButtonText="Proceed"
          onCancel={() => {
            setIsSaveConfirmationModalOpen(false);
          }}
          onConfirm={onSave}
        />
        <ImportQuestionsModal
          isOpen={isImportModalOpen}
          onClose={() => {
            setIsImportModalOpen(false);
          }}
          onImport={(questions: BasicQuestion[]) => {
            setBasicQuestions(questions);
            setHasMadeChanges(true);
            setIsImportModalOpen(false);
          }}
          projectId={project.id}
        />
      </Slideover>
    </>
  );
};
