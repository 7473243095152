import {
  isValidEmail,
  LoadingStatesEnum,
  ProjectEmailDigestFrequencyEnum,
  ProjectUpdate,
  ProjectWithInterviewCount,
} from "app-types";
import { FC, useState } from "react";
import {
  Label,
  SearchableSelect,
  SearchableSelectModesEnum,
  SearchableSelectOption,
  Select,
  SimpleRadioGroup,
  SimpleRadioOption,
  SizesEnum,
} from "ui";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import {
  selectAllTeammates,
  selectTeammatesLoadingState,
} from "../../teammates/teammatesSlice";
import { updateProject } from "../projectsSlice";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";
import { SlackProjectNotificationsSection } from "../slackProjectNotificationsSection";

interface ProjectNotificationsSettingsSectionProps {
  project: ProjectWithInterviewCount;
  onSaveProject: (changes: ProjectUpdate) => void;
}

const enum RecipientTeammatesOption {
  All = "all",
  ProjectOwners = "owners",
  SpecifiedOnly = "specific",
}

export const ProjectNotificationsSettingsSection: FC<
  ProjectNotificationsSettingsSectionProps
> = ({ project, onSaveProject }) => {
  const dispatch = useAppDispatch();
  const teammates = useAppSelector((state) =>
    selectAllTeammates(state.teammates),
  );
  const teammatesLoadingStatus = useAppSelector(selectTeammatesLoadingState);

  const [recipientTeammatesOption, setRecipientTeammatesOption] = useState(
    computeRecipientTeammatesOption(project),
  );
  const [otherDigestRecipientEmails, setOtherDigestRecipientEmails] = useState<
    string[]
  >(project.settings.email_digest_recipients);
  const [hasChangedDigestRecipientEmails, setHasChangedDigestRecipientEmails] =
    useState(false);

  const [emailDigestFrequency, setEmailDigestFrequency] =
    useState<ProjectEmailDigestFrequencyEnum>(
      project.settings.email_digest_frequency,
    );

  const frequencyOptions = [
    { value: ProjectEmailDigestFrequencyEnum.INSTANT, name: "Instant" },
    { value: ProjectEmailDigestFrequencyEnum.DAILY, name: "Daily" },
  ];

  return (
    <div>
      <Label size={SizesEnum.LARGE}>Notifications</Label>
      <div className="flex flex-col space-y-2 mb-3">
        <Label size={SizesEnum.SMALL}>Email</Label>
        <SimpleRadioGroup
          value={recipientTeammatesOption}
          onChange={(value) => setRecipientTeammatesOption(value)}
        >
          <SimpleRadioOption value={RecipientTeammatesOption.All}>
            Send to all teammates in your organization{" "}
            {teammatesLoadingStatus === LoadingStatesEnum.LOADED
              ? `(${teammates.length})`
              : null}
          </SimpleRadioOption>
          <SimpleRadioOption
            value={RecipientTeammatesOption.ProjectOwners}
            isDisabled={project.owner_teammate_ids.length === 0}
          >
            Send to project owners ({project.owner_teammate_ids.length})
          </SimpleRadioOption>
          <SimpleRadioOption value={RecipientTeammatesOption.SpecifiedOnly}>
            Send only to recipients specified below
          </SimpleRadioOption>
        </SimpleRadioGroup>
        <div>
          <div className="flex items-center space-x-4">
            <div className="w-[400px]">
              <SearchableSelect
                mode={SearchableSelectModesEnum.MULTI}
                placeholder="Add an email address"
                selectedOptions={otherDigestRecipientEmails.map((e) => ({
                  id: e,
                  name: e,
                }))}
                options={teammates.map((t) => ({ id: t.email, name: t.email }))}
                onChange={(options: SearchableSelectOption[]) => {
                  if (options.length > 50) {
                    showNotification(dispatch, {
                      id: `limit-reached-${new Date().getTime()}`,
                      primaryMessage:
                        "Limit of 50 recipients reached. Consider adding a distribution list email address instead.",
                      type: NotificationTypeEnum.FAILURE,
                    });
                    return;
                  }
                  setHasChangedDigestRecipientEmails(true);
                  setOtherDigestRecipientEmails(
                    options.map((o) => o.id).filter((e) => isValidEmail(e)),
                  );
                }}
                label={
                  recipientTeammatesOption ===
                  RecipientTeammatesOption.SpecifiedOnly
                    ? "Recipients"
                    : "Additional recipients"
                }
              />
            </div>
            <div className="w-[120px]">
              <Label size={SizesEnum.SMALL} className="mb-2">
                Frequency
              </Label>
              <Select
                options={frequencyOptions}
                currentSelection={frequencyOptions.find(
                  (o) => o.value === emailDigestFrequency,
                )}
                onChange={(option) => {
                  const newFrequency = frequencyOptions.find(
                    (o) => o.name === option.name,
                  )?.value;
                  if (newFrequency) {
                    setEmailDigestFrequency(newFrequency);
                  }
                }}
              />
            </div>
          </div>
          {(hasChangedDigestRecipientEmails ||
            computeRecipientTeammatesOption(project) !==
              recipientTeammatesOption ||
            project.settings.email_digest_frequency !==
              emailDigestFrequency) && (
            <SaveAndCancelButtons
              onSave={() => {
                dispatch(
                  updateProject({
                    projectId: project.id,
                    changes: {
                      settings: {
                        ...project.settings,
                        email_digest_recipients: otherDigestRecipientEmails,
                        send_email_digest_to_all_teammates:
                          recipientTeammatesOption ===
                          RecipientTeammatesOption.All,
                        send_email_digest_to_project_owners:
                          recipientTeammatesOption ===
                            RecipientTeammatesOption.All ||
                          recipientTeammatesOption ===
                            RecipientTeammatesOption.ProjectOwners,
                        email_digest_frequency: emailDigestFrequency,
                      },
                    },
                  }),
                );
                setHasChangedDigestRecipientEmails(false);
              }}
              onCancel={() => {
                setOtherDigestRecipientEmails(
                  project.settings.email_digest_recipients,
                );
                setRecipientTeammatesOption(
                  computeRecipientTeammatesOption(project),
                );
                setEmailDigestFrequency(
                  project.settings.email_digest_frequency,
                );
                setHasChangedDigestRecipientEmails(false);
              }}
            />
          )}
        </div>
        <div className="text-sm text-gray-600">
          You can add specific teammates or distribution lists that should
          receive email digests as interviews are completed.
        </div>
      </div>
      <SlackProjectNotificationsSection
        project={project}
        onSave={onSaveProject}
      />
    </div>
  );
};

function computeRecipientTeammatesOption(
  project: ProjectWithInterviewCount,
): string {
  return project.settings.send_email_digest_to_all_teammates
    ? RecipientTeammatesOption.All
    : project.settings.send_email_digest_to_project_owners
      ? RecipientTeammatesOption.ProjectOwners
      : RecipientTeammatesOption.SpecifiedOnly;
}
