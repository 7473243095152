import { FC } from "react";
import { Button, ButtonVariantsEnum } from "ui";

interface SaveAndCancelButtonsProps {
  onSave: () => void;
  isSaving?: boolean;
  isSaveDisabled?: boolean;
  onCancel: () => void;
}

export const SaveAndCancelButtons: FC<SaveAndCancelButtonsProps> = ({
  onSave,
  onCancel,
  isSaving,
  isSaveDisabled,
}) => {
  return (
    <div className="flex mt-3 space-x-2">
      <Button
        variant={ButtonVariantsEnum.Secondary}
        onClick={onCancel}
        label="Cancel"
      />
      <Button
        variant={ButtonVariantsEnum.Primary}
        label="Save changes"
        onClick={onSave}
        isLoading={isSaving}
        isDisabled={isSaving || isSaveDisabled}
      />
    </div>
  );
};
