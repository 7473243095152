import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { Account, Contact, LoadingStatesEnum } from "app-types";
import { Selector } from "react-redux";
import { RootState } from "../../app/store";
import {
  fetchFocusedInterview,
  fetchNonEmptyInterviewsForProject,
} from "../../features/interviews/interviewsSlice";

export const accountsAdapter = createEntityAdapter<Account>();

const initialState = accountsAdapter.getInitialState<{
  status: LoadingStatesEnum;
  error: string | null;
}>({
  status: LoadingStatesEnum.LOADED,
  error: null,
});

export const selectAccountsDictionary = (state: RootState) =>
  accountsAdapter
    .getSelectors((state: RootState) => state.accounts)
    .selectEntities(state);

export const selectAccountForContact = (
  contact: Contact,
): Selector<RootState, Account | null> =>
  createSelector([selectAccountsDictionary], (accounts) => {
    if (!contact.account_id) return null;

    return accounts[contact.account_id] || null;
  });

const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    accountsAdded: accountsAdapter.addMany,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNonEmptyInterviewsForProject.fulfilled,
      (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        accountsAdapter.addMany(state, action.payload.accounts);
      },
    );
    builder.addCase(fetchFocusedInterview.fulfilled, (state, action) => {
      if (action.payload.account)
        accountsAdapter.upsertOne(state, action.payload.account);
    });
  },
});

export const { accountsAdded } = accountsSlice.actions;
export default accountsSlice.reducer;
