import { FC, useEffect, useRef, useState } from "react";

export const AuthPopupCallbackPage: FC = () => {
  const [error, setError] = useState<string | null>(null);
  const hasMessageBeenSent = useRef(false);

  useEffect(() => {
    const opener = window.opener as WindowProxy | null;

    // Extract the code from the URL search params
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    const errorParam = searchParams.get("error");
    const errorDescription = searchParams.get("error_description");

    if (errorParam ?? errorDescription) {
      setError(
        errorDescription ??
          errorParam ??
          "An error occurred during sign in - please close this window and try again.",
      );
      return;
    }

    if (!code) {
      setError(
        "No authentication code found - please close this window and try again.",
      );
      return;
    }

    // Only send the message if we haven't sent it already
    if (!hasMessageBeenSent.current) {
      const message = { code };
      opener?.postMessage(message, window.location.origin);
      hasMessageBeenSent.current = true;
      window.close();
    }
  }, []);

  if (error) {
    return (
      <div className="p-4 text-center">
        <p className="text-red-600 text-sm">{error}</p>
      </div>
    );
  }

  return <div className="p-4 text-sm text-center">Completing sign in...</div>;
};
