import { FC, ReactNode } from "react";

export interface SettingsTableBodySectionProps {
  children: ReactNode;
}

export const SettingsTableBodySection: FC<SettingsTableBodySectionProps> = (
  props,
) => (
  <tbody className="bg-white divide-y divide-gray-200">{props.children}</tbody>
);
