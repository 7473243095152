import { LoadingStatesEnum } from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, Modal, ModalHeader, Select } from "ui";
import { v4 as uuidv4 } from "uuid";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import {
  BalanceIncreaseOptionToValue,
  balanceIncreaseOptions,
} from "../../../helpers/transactionBalanceHelpers";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";

interface GiftCardBalanceIncreaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAddToBalance: () => void;
}

export const GiftCardBalanceIncreaseModal: FC<
  GiftCardBalanceIncreaseModalProps
> = ({ isOpen, onClose, onAddToBalance }) => {
  const dispatch = useAppDispatch();
  const [balanceIncreaseOption, setBalanceIncreaseOption] = useState<string>(
    balanceIncreaseOptions[4],
  );
  const [increaseBalanceLoadingState, setIncreaseBalanceLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const onClickSubmit = async () => {
    setIncreaseBalanceLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const value = BalanceIncreaseOptionToValue[balanceIncreaseOption];
      const unitAmount = value * 100;
      const { data } = await axios.post(`/billing/fund-balance`, {
        balance_transaction_id: uuidv4(),
        unit_amount: unitAmount,
      });

      showNotification(dispatch, {
        id: `balance-increased-${new Date().getTime()}`,
        primaryMessage: `$${value} added to balance`,
        type: NotificationTypeEnum.SUCCESS,
      });

      // Inform the parent to reload balance data
      onAddToBalance();
      onClose();

      setIncreaseBalanceLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      showNotification(dispatch, {
        id: `balance-increase-error-${new Date().getTime()}`,
        primaryMessage: `Sorry, an error occurred. Please try again.`,
        type: NotificationTypeEnum.FAILURE,
      });

      onClose();

      setIncreaseBalanceLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader onClose={onClose}>Add to Gift Card Balance</ModalHeader>

      <div className="mt-6 mb-2 w-[120px]">
        <Select
          label="Amount (USD)"
          onChange={(option) => {
            setBalanceIncreaseOption(option.name);
          }}
          options={balanceIncreaseOptions.map((o) => {
            return { name: o };
          })}
          currentSelection={{ name: balanceIncreaseOption }}
        />
      </div>
      <div className="text-sm text-gray-600">
        Your credit card will be charged and an invoice will be emailed to the
        billing contact.
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            variant={ButtonVariantsEnum.Primary}
            label={`Add $${BalanceIncreaseOptionToValue[balanceIncreaseOption]} to balance`}
            isDisabled={
              increaseBalanceLoadingState === LoadingStatesEnum.LOADING
            }
            onClick={() => onClickSubmit()}
            isLoading={
              increaseBalanceLoadingState === LoadingStatesEnum.LOADING
            }
          />
        </div>
        <Button
          variant={ButtonVariantsEnum.Secondary}
          label="Cancel"
          onClick={() => onClose()}
        />
      </div>
    </Modal>
  );
};
