import { TrashIcon } from "@heroicons/react/24/outline";
import { ApiKey, LoadingStatesEnum } from "app-types";
import { format } from "date-fns";
import { FC, useEffect, useState } from "react";
import {
  Button,
  ButtonVariantsEnum,
  IconButton,
  Link,
  TableSkeleton,
} from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import { SettingsTable } from "../shared/settingsTable";
import { SettingsTableBodySection } from "../shared/settingsTableBodySection";
import { SettingsTableDataCell } from "../shared/settingsTableDataCell";
import { SettingsTableEmptyBodyContent } from "../shared/settingsTableEmptyBodyContent";
import { SettingsTableErrorBodyContent } from "../shared/settingsTableErrorBodyContent";
import { SettingsTableHeaderCell } from "../shared/settingsTableHeaderCell";
import { SettingsTableHeaderSection } from "../shared/settingsTableHeaderSection";
import { CreateApiKeyModal } from "./createApiKeyModal";
import { DeleteApiKeyModal } from "./deleteApiKeyModal";

export const DeveloperSettingsSection: FC = () => {
  const dispatch = useAppDispatch();

  const [listApiKeysLoadingState, setListApiKeysLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<ApiKey | null>(null);
  const [isCreateApiKeyModalOpen, setIsCreateApiKeyModalOpen] = useState(false);

  const fetchApiKeys = async () => {
    setListApiKeysLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.get(`/api-keys`);

      setApiKeys(data);
      setListApiKeysLoadingState(LoadingStatesEnum.LOADED);
    } catch (err) {
      setListApiKeysLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const renderTableRows = () => {
    if (listApiKeysLoadingState === LoadingStatesEnum.LOADING)
      return <TableSkeleton />;

    if (listApiKeysLoadingState === LoadingStatesEnum.ERROR)
      return (
        <SettingsTableErrorBodyContent colSpan={3}>
          An error occurred. Please refresh the page and try again.
        </SettingsTableErrorBodyContent>
      );

    if (apiKeys.length === 0)
      return (
        <SettingsTableEmptyBodyContent colSpan={4}>
          Create an API key to use the Alpharun API
        </SettingsTableEmptyBodyContent>
      );

    return (
      <>
        {apiKeys.map((apiKey) => (
          <tr className="group relative" key={apiKey.id}>
            <SettingsTableDataCell>{apiKey.name}</SettingsTableDataCell>
            <SettingsTableDataCell>{`*****${apiKey.key_suffix}`}</SettingsTableDataCell>
            <SettingsTableDataCell>
              {format(new Date(apiKey.created_at), "MMM dd yyyy")}
              <div className="text-gray-500 absolute right-2 top-1.5 cursor-pointer hidden group-hover:block">
                <IconButton
                  variant={ButtonVariantsEnum.Secondary}
                  icon={<TrashIcon className="h-4 w-4" />}
                  onClick={() => {
                    setApiKeyToDelete(apiKey);
                  }}
                />
              </div>
            </SettingsTableDataCell>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="max-w-2xl min-w-[450px] mx-auto px-8 py-6">
      <SettingsTable>
        <SettingsTableHeaderSection>
          <tr>
            <SettingsTableHeaderCell className="w-[45%]">
              Name
            </SettingsTableHeaderCell>
            <SettingsTableHeaderCell className="w-[30%]">
              API Key
            </SettingsTableHeaderCell>
            <SettingsTableHeaderCell className="w-[25%]">
              Date Created
            </SettingsTableHeaderCell>
          </tr>
        </SettingsTableHeaderSection>
        <SettingsTableBodySection>{renderTableRows()}</SettingsTableBodySection>
      </SettingsTable>
      <div className="mt-4 text-sm text-gray-600">
        API keys allow you to use the Alpharun API.{" "}
        <Link href="https://help.alpharun.com/en/articles/2476993">
          View API documentation here
        </Link>
        {"."}
      </div>
      <div className="mt-4">
        <Button
          variant={ButtonVariantsEnum.Primary}
          label="Create API Key"
          onClick={() => {
            setIsCreateApiKeyModalOpen(true);
          }}
        />
      </div>
      <CreateApiKeyModal
        isOpen={isCreateApiKeyModalOpen}
        onClose={() => {
          setIsCreateApiKeyModalOpen(false);
          fetchApiKeys();
        }}
      />
      <DeleteApiKeyModal
        isOpen={Boolean(apiKeyToDelete)}
        onClose={() => {
          setApiKeyToDelete(null);
          fetchApiKeys();
        }}
        apiKeyToDelete={apiKeyToDelete}
      />
    </div>
  );
};
