import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { InterviewInsights } from "app-types";
import { FC } from "react";
import { joinClassnames } from "ui";

export interface InterviewSummaryProps {
  insights: InterviewInsights;
  isToolTipMode?: boolean;
}

export const InterviewSummary: FC<InterviewSummaryProps> = ({
  insights,
  isToolTipMode = false,
}) => {
  const { strengths, weaknesses, summary } = insights;

  // If we have strengths/weaknesses (newer format for recruiting projects), display those
  if (strengths || weaknesses) {
    return (
      <div
        className={
          isToolTipMode ? "text-xs text-white" : "text-sm text-gray-900"
        }
      >
        {strengths && strengths.length > 0 && (
          <div className="mb-4">
            <ul className="space-y-2">
              {strengths.map((strength, index) => (
                <li
                  key={`strength-${index}`}
                  className="flex items-center gap-2"
                >
                  <PlusCircleIcon className="h-5 w-5 flex-shrink-0 text-green-500" />
                  <span>{strength}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        {weaknesses && weaknesses.length > 0 && (
          <div>
            <ul className="space-y-2">
              {weaknesses.map((weakness, index) => (
                <li
                  key={`weakness-${index}`}
                  className="flex items-center gap-2"
                >
                  <MinusCircleIcon className="h-5 w-5 flex-shrink-0 text-red-500" />
                  <span>{weakness}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  // Fall back to legacy summary if available
  if (summary) {
    return (
      <div
        className={joinClassnames(
          "pl-5",
          isToolTipMode ? "text-xs text-white" : "text-sm text-gray-900",
        )}
      >
        <ul className="list-disc">
          {summary.split(". ").map((sentence, index, array) => {
            // Add back period for each sentence except the last one
            const processedSentence =
              index < array.length - 1 ? `${sentence}.` : sentence;
            return (
              <li key={`summary-sentence-${index}`} className="mb-2">
                {processedSentence}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};
