import { ApiKey, LoadingStatesEnum } from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, Modal, ModalHeader } from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";

interface DeleteApiKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  apiKeyToDelete: ApiKey | null;
}

export const DeleteApiKeyModal: FC<DeleteApiKeyModalProps> = ({
  isOpen,
  onClose,
  apiKeyToDelete,
}) => {
  const dispatch = useAppDispatch();
  const [deleteApiKeyLoadingState, setDeleteApiKeyLoadingState] =
    useState<LoadingStatesEnum>(LoadingStatesEnum.LOADED);

  const onClickDeleteApiKey = async () => {
    if (!apiKeyToDelete) return;

    setDeleteApiKeyLoadingState(LoadingStatesEnum.LOADING);
    try {
      const axios = await getAxiosInstanceWithAuth();
      const { data } = await axios.delete(`/api-keys/${apiKeyToDelete.id}`);

      setDeleteApiKeyLoadingState(LoadingStatesEnum.LOADED);
      onClose();
    } catch (err) {
      setDeleteApiKeyLoadingState(LoadingStatesEnum.ERROR);
    }
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader onClose={onClose}>Are you sure?</ModalHeader>
      <div className="mt-5 text-sm ">
        {`Deleting the "${apiKeyToDelete?.name}" API key will revoke API access for all services using it. Are you sure you want to continue?`}
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <div className="ml-3">
          <Button
            variant={ButtonVariantsEnum.Warning}
            label={"Delete API Key"}
            isDisabled={deleteApiKeyLoadingState === LoadingStatesEnum.LOADING}
            onClick={() => onClickDeleteApiKey()}
            isLoading={deleteApiKeyLoadingState === LoadingStatesEnum.LOADING}
          />
        </div>
        <Button
          variant={ButtonVariantsEnum.Secondary}
          label="Cancel"
          onClick={() => onClose()}
        />
      </div>
    </Modal>
  );
};
