import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { LoadingStatesEnum, Teammate } from "app-types";
import { getAxiosInstanceWithAuth } from "../../api/axiosConfig";
import { RootState } from "../../app/store";

const teammatesAdapter = createEntityAdapter<Teammate>();

const initialState = teammatesAdapter.getInitialState<{
  status: LoadingStatesEnum;
  error: string | null;
}>({
  status: LoadingStatesEnum.LOADED,
  error: null,
});

export const fetchTeammates = createAsyncThunk(
  "teammates/fetchAll",
  async (_parameters, thunkAPI) => {
    try {
      const axios = await getAxiosInstanceWithAuth();
      const response = await axios.get("/teammates");

      return thunkAPI.fulfillWithValue(response.data.teammates);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const teammatesSlice = createSlice({
  name: "teammates",
  initialState: initialState,
  reducers: {
    teammateAdded: teammatesAdapter.addOne,
    teammateRemoved: teammatesAdapter.removeOne,
    teammateUpdated: teammatesAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTeammates.pending, (state) => {
      state.status = LoadingStatesEnum.LOADING;
    });
    builder.addCase(fetchTeammates.fulfilled, (state, action) => {
      state.status = LoadingStatesEnum.LOADED;
      teammatesAdapter.setAll(state, action.payload);
    });
    builder.addCase(fetchTeammates.rejected, (state, action) => {
      state.status = LoadingStatesEnum.ERROR;
      state.error = action.error.message || null;
    });
  },
});

export const selectAllTeammates = teammatesAdapter.getSelectors().selectAll;
export const selectTeammatesLoadingState = (state: RootState) =>
  state.teammates.status;

export default teammatesSlice.reducer;
