import { HandlerOf } from "app-types";
import { FC, useState } from "react";
import { Textarea } from "ui";
import { SaveAndCancelButtons } from "../../saveAndCancelButtons";

const MIN_CONTEXT_LENGTH = 15;
const MAX_CONTEXT_LENGTH = 3000;

interface JobTypeContextProps {
  context: string | null;
  onSave: HandlerOf<string | null>;
}

export const JobTypeContext: FC<JobTypeContextProps> = (props) => {
  const { onSave } = props;

  const context = props.context ?? "";
  const [newContext, setNewContext] = useState(context);
  const [contextError, setContextError] = useState("");

  const onClickSaveContext = (): void => {
    if (newContext && newContext.length < MIN_CONTEXT_LENGTH) {
      setContextError(
        `Must be at least ${MIN_CONTEXT_LENGTH.toString()} characters long`,
      );
      return;
    }

    if (newContext && newContext.length > MAX_CONTEXT_LENGTH) {
      setContextError(
        `Must be less than ${MAX_CONTEXT_LENGTH.toString()} characters long`,
      );
      return;
    }

    setContextError("");
    onSave(newContext);
  };

  return (
    <div className="flex flex-col space-y-3">
      <div>
        <Textarea
          label="Company and role details"
          value={newContext}
          onChange={(evt) => {
            setContextError("");
            setNewContext(evt.target.value);
          }}
          maxLength={MAX_CONTEXT_LENGTH}
          placeholder="Job name: Customer Service Representative..."
          errorDescription={contextError}
          description={
            <>
              Provide context (location, pay range, benefits, hours etc.) about
              your company and role that the AI interviewer can use to answer
              candidate questions during the interview.
            </>
          }
          className="w-[700px]"
        />
      </div>
      {context !== newContext ? (
        <SaveAndCancelButtons
          onSave={onClickSaveContext}
          onCancel={() => {
            setNewContext(context || "");
            setContextError("");
          }}
        />
      ) : null}
    </div>
  );
};
