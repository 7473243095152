import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { LoadingStatesEnum, renderTeammateDisplayName } from "app-types";
import { FC, useEffect, useState } from "react";
import { joinClassnames } from "ui";
import { useAppSelector } from "../../hooks/hook";
import { TeammateAvatarPile } from "../teammate/teammateAvatarPile";
import {
  selectAllTeammates,
  selectTeammatesLoadingState,
} from "../teammates/teammatesSlice";

export interface ProjectOwnersWidgetProps {
  teammateIds: readonly string[];
  onTeammateIdsSave: (teammateIds: readonly string[]) => Promise<void>;
}

export const ProjectOwnersWidget: FC<ProjectOwnersWidgetProps> = (props) => {
  const teammatesLoadingState = useAppSelector(selectTeammatesLoadingState);
  const teammates = useAppSelector((state) =>
    selectAllTeammates(state.teammates),
  );

  const [unsavedTeammateIds, setUnsavedTeammateIds] = useState<
    readonly string[] | undefined
  >(undefined);

  const [saveDepth, setSaveDepth] = useState(0);

  const onSave = async () => {
    if (!unsavedTeammateIds) {
      return;
    }

    setSaveDepth((depth) => depth + 1);
    try {
      await props.onTeammateIdsSave(unsavedTeammateIds);
    } finally {
      setUnsavedTeammateIds((teammateIds) =>
        teammateIds === unsavedTeammateIds ? undefined : teammateIds,
      );
      setSaveDepth((depth) => depth - 1);
    }
  };

  const isSaving = saveDepth > 0;
  const teammateIds = unsavedTeammateIds ?? props.teammateIds;

  return (
    <Listbox
      as="div"
      className="relative"
      multiple
      disabled={teammatesLoadingState !== LoadingStatesEnum.LOADED || isSaving}
      value={teammateIds}
      onChange={setUnsavedTeammateIds}
    >
      {({ open }) => (
        <>
          <OpenWatcher open={open} onClose={onSave} />
          <Listbox.Button className="overflow-hidden rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center gap-x-1">
            {teammateIds.length === 0 ? (
              <span className="truncate">Set owner</span>
            ) : (
              <>
                <div className="h-5 shrink-0 flex">
                  <TeammateAvatarPile
                    className="-ml-2 -my-1"
                    teammates={teammateIds.map((teammateId) =>
                      teammates.find((teammate) => teammate.id === teammateId),
                    )}
                  />
                </div>
                <ChevronDownIcon
                  aria-hidden="true"
                  className="-mr-1 size-5 shrink-0 text-gray-400"
                />
              </>
            )}
          </Listbox.Button>
          <Listbox.Options className="block absolute right-0 z-10 mt-2 w-48 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {open
              ? teammates.map((teammate) => (
                  <Listbox.Option
                    key={teammate.id}
                    className={({ active }) =>
                      joinClassnames(
                        active ? "bg-gray-100" : "",
                        "flex px-4 py-2 gap-x-2 text-sm text-gray-700 cursor-pointer",
                      )
                    }
                    value={teammate.id}
                  >
                    {({ active, selected }) => (
                      <>
                        <div
                          className={joinClassnames(
                            "size-5",
                            active ? "text-gray-900" : "text-blue-600",
                          )}
                        >
                          {selected ? <CheckIcon className="size-5" /> : null}
                        </div>
                        <div
                          className={joinClassnames(
                            "truncate",
                            selected ? "font-medium" : "font-normal",
                          )}
                        >
                          {renderTeammateDisplayName(teammate)}
                        </div>
                      </>
                    )}
                  </Listbox.Option>
                ))
              : null}
          </Listbox.Options>
        </>
      )}
    </Listbox>
  );
};

// TODO: Use a better dropdown component. This is a hack.
const OpenWatcher: FC<{ open: boolean; onClose: () => void }> = (props) => {
  useEffect(() => {
    if (!props.open) {
      props.onClose();
    }
  }, [props.open]);

  return null;
};
