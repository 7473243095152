import { Contact, TranscriptFragment } from "app-types";
import { FC } from "react";
import { joinClassnames } from "ui";
import { ContactNameWithTime } from "./contactNameWithTime";
import { TranscriptFragmentText } from "./transcriptFragmentText";

interface ContactNameWithTranscriptFragmentProps {
  contact: Contact;
  date?: Date;
  transcriptFragments: TranscriptFragment[];
  shouldHideQuestion?: boolean;
  onClick: () => void;
  classNames?: string;
}

export const ContactNameWithTranscriptFragment: FC<
  ContactNameWithTranscriptFragmentProps
> = ({
  contact,
  date,
  transcriptFragments,
  onClick,
  classNames,
  shouldHideQuestion,
}) => {
  return (
    <div
      className={joinClassnames(
        "flex flex-col ring-1 ring-gray-100 rounded-md cursor-pointer",
        classNames,
      )}
      onClick={onClick}
    >
      <div className="px-3 py-2 border-b border-gray-100">
        <ContactNameWithTime contact={contact} date={date} />
      </div>
      <div className={`${shouldHideQuestion ? "" : "p-3"}`}>
        {transcriptFragments.map((t, i) => {
          return (
            <div
              key={`contact-name-with-fragment-${t.id}`}
              className={`text-sm text-gray-900 ${
                t.is_dynamic_question && !shouldHideQuestion ? "pl-6" : ""
              }`}
            >
              {shouldHideQuestion ? null : (
                <div className={`text-gray-700 ${i !== 0 ? "mt-2" : ""} mb-2`}>
                  {t.question}
                </div>
              )}
              <TranscriptFragmentText text={t.text_transcript} role={t.role} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
