import { BasicContact, Project } from "app-types";
import {
  maybeCreateInterviewsForContactsDb,
  upsertContactsDb,
} from "../../api/supabaseService";

export const createContactsAndInterviewsForProject = async (
  contacts: BasicContact[],
  project: Project,
) => {
  try {
    // Add company_id to meet row level security requirements
    const contactsWithCompanyId = contacts.map((contact) => ({
      ...contact,
      company_id: project.company_id,
    }));

    // Return value will include both newly created and updated contacts
    const upsertedContacts = await upsertContactsDb(contactsWithCompanyId);

    // Return value will include all newly created interviews
    // If a pending/in_progress interview already exists for a contact, it will be skipped
    const interviews = await maybeCreateInterviewsForContactsDb(
      upsertedContacts,
      project,
    );

    return interviews;
  } catch (error: any) {
    throw error;
  }
};
