import type {
  CustomFieldDefinition,
  CustomFieldDefinitionInsertClient,
  TablesInsertWithFixedTypes,
  TablesUpdateWithFixedTypes,
  TablesWithFixedTypes,
} from "app-types";
import { InterviewLanguagesEnum } from "../constants/languages.ts";
import { CustomFieldTypesEnum } from "./customField.ts";

export type AssessmentDB = TablesWithFixedTypes<"assessment">;
export type AssessmentUpdate = TablesUpdateWithFixedTypes<"assessment">;

export enum SpecialAssessmentFieldNamesEnum {
  CefrLanguageLevelEnglish = "cefr_language_level_english",
  CefrLanguageLevelGerman = "cefr_language_level_german",
}

export type CefrLanguageAssessmentFieldLanguage =
  | InterviewLanguagesEnum.ENGLISH
  | InterviewLanguagesEnum.GERMAN;

export const CefrAssessmentFieldNameToLanguage = {
  [SpecialAssessmentFieldNamesEnum.CefrLanguageLevelEnglish]:
    InterviewLanguagesEnum.ENGLISH,
  [SpecialAssessmentFieldNamesEnum.CefrLanguageLevelGerman]:
    InterviewLanguagesEnum.GERMAN,
};
export const LanguageToCefrAssessmentFieldName: Record<
  CefrLanguageAssessmentFieldLanguage,
  SpecialAssessmentFieldNamesEnum
> = {
  [InterviewLanguagesEnum.ENGLISH]:
    SpecialAssessmentFieldNamesEnum.CefrLanguageLevelEnglish,
  [InterviewLanguagesEnum.GERMAN]:
    SpecialAssessmentFieldNamesEnum.CefrLanguageLevelGerman,
};

export function isCefrLanguageAssessmentLanguage(
  language: InterviewLanguagesEnum,
): language is CefrLanguageAssessmentFieldLanguage {
  return language in LanguageToCefrAssessmentFieldName;
}

export const CANDIDATE_ALIGN_FIELD_NAME =
  "Candidate's preferences and working style are aligned with the job";

export const SpecialAssessmentFieldDetails = {
  [SpecialAssessmentFieldNamesEnum.CefrLanguageLevelEnglish]: {
    display_name: "CEFR English level estimate",
    field_type: CustomFieldTypesEnum.Text,
  },
  [SpecialAssessmentFieldNamesEnum.CefrLanguageLevelGerman]: {
    display_name: "CEFR German level estimate",
    field_type: CustomFieldTypesEnum.Text,
  },
};

export const isSpecialAssessmentFieldName = (fieldName: string) => {
  return Object.values(SpecialAssessmentFieldNamesEnum).includes(
    fieldName as SpecialAssessmentFieldNamesEnum,
  );
};

export const getSpecialAssessmentField = (
  customFieldDefinitions: (
    | CustomFieldDefinition
    | CustomFieldDefinitionInsertClient
  )[],
  specialFieldName: SpecialAssessmentFieldNamesEnum,
) => {
  return customFieldDefinitions.find(
    (d) =>
      (d.field_name as SpecialAssessmentFieldNamesEnum) === specialFieldName,
  );
};

export const getCEFRLanguageAssessmentField = (
  customFieldDefinitions: (
    | CustomFieldDefinition
    | CustomFieldDefinitionInsertClient
  )[],
) => {
  return customFieldDefinitions.find(
    (d) =>
      (d.field_name as SpecialAssessmentFieldNamesEnum) ===
        SpecialAssessmentFieldNamesEnum.CefrLanguageLevelEnglish ||
      (d.field_name as SpecialAssessmentFieldNamesEnum) ===
        SpecialAssessmentFieldNamesEnum.CefrLanguageLevelGerman,
  );
};

export type AssessmentHasCustomFieldDefinitionInsert =
  TablesInsertWithFixedTypes<"assessment_custom_field_definition">;

export interface Assessment {
  id: string;
  custom_field_definitions: CustomFieldDefinition[];
}

export function mapAssessmentDBToAssessment(
  assessmentDB: AssessmentDB,
  customFieldDefinitions: CustomFieldDefinition[],
) {
  return {
    id: assessmentDB.id,
    custom_field_definitions: customFieldDefinitions,
  };
}

// Sorts assessment field so that special fields are displayed first
export function sortAssessmentFields(fields: CustomFieldDefinition[]) {
  return [...fields].sort((a) => {
    return isSpecialAssessmentFieldName(a.field_name) ? -1 : 1;
  });
}

export const ASSESSMENT_FIELD_NAME_MIN_LENGTH = 10;
export const ASSESSMENT_FIELD_NAME_MAX_LENGTH = 200;

export function getErrorMessageForAssessmentCriteriaValue(
  value: string,
): string {
  if (value.length < ASSESSMENT_FIELD_NAME_MIN_LENGTH) {
    return `Criteria must be at least ${ASSESSMENT_FIELD_NAME_MIN_LENGTH} characters long`;
  }
  if (value.length > ASSESSMENT_FIELD_NAME_MAX_LENGTH) {
    return `Criteria must not exceed ${ASSESSMENT_FIELD_NAME_MAX_LENGTH} characters`;
  }

  // Don't allow the user to rename the field to one of the reserved field names
  if (isSpecialAssessmentFieldName(value)) {
    return "This field name is reserved, please choose another one";
  }

  return "";
}

export interface AssessmentFieldChanges {
  fieldsToRemove: CustomFieldDefinition[];
  fieldsToAdd: (CustomFieldDefinition | CustomFieldDefinitionInsertClient)[];
  hasChanges: boolean;
}

export function getAssessmentFieldChanges(
  originalFields: CustomFieldDefinition[],
  newFields: (CustomFieldDefinition | CustomFieldDefinitionInsertClient)[],
): AssessmentFieldChanges {
  // Find any fields that have been removed
  const removedFields = originalFields.filter(
    (field) => !newFields.map((f) => f.id).includes(field.id),
  );

  // Find any fields that have been added/updated (they don't have an id)
  const fieldsToAdd = newFields.filter((field) => !field.id);

  return {
    fieldsToRemove: removedFields,
    fieldsToAdd,
    hasChanges: removedFields.length > 0 || fieldsToAdd.length > 0,
  };
}

// Convenience function
export function getHaveAssessmentFieldsChanged(
  originalFields: CustomFieldDefinition[],
  newFields: (CustomFieldDefinition | CustomFieldDefinitionInsertClient)[],
): boolean {
  return getAssessmentFieldChanges(originalFields, newFields).hasChanges;
}
