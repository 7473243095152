import { supabase } from "./supabaseService";

export const apiBaseUrl = import.meta.env.VITE_API_BASE_URL;

export async function getApiAuthorizationHeaderValue() {
  // getSession() will use the refresh token to get a new session if accessToken has expired
  const result = await supabase.auth.getSession();
  const session = result.data.session;

  if (!session) {
    throw new Error("Attempting to make a request with no session");
  }

  return `Bearer ${session.access_token}`;
}
