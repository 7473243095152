import type {
  ComplexQuestionType,
  ProjectQuestion,
  SurveyScale,
} from "app-types";

export enum TemplateCategoriesEnum {
  Research = "Market & Product Research",
  SalesAndSuccess = "Sales & Success",
  HrAndRecruiting = "HR & Recruiting",
}

export enum ProjectTypesEnum {
  Win = "WON_DEAL_INTERVIEW",
  Loss = "LOST_DEAL_INTERVIEW",
  Churn = "CHURN_INTERVIEW",
  AccountCheckIn = "ACCOUNT_CHECK_IN",
  SuperchargedNPS = "NPS",
  BrandPerception = "BRAND_PERCEPTION",
  ConceptTesting = "CONCEPT_TESTING",
  Discovery = "DISCOVERY",
  CandidateScreen = "CANDIDATE_SCREEN",
  CandidateReferenceCheck = "CANDIDATE_REFERENCE_CHECK",
  EmployeeFeedback = "EMPLOYEE_FEEDBACK",
  EmployeeExitInterview = "EMPLOYEE_EXIT_INTERVIEW",
  Custom = "CUSTOM",
}

export interface ProjectTemplate {
  type: ProjectTypesEnum;
  category: TemplateCategoriesEnum | null;
  name: string;
  projectDefaultName: string;
  description: string;
}

export const projectTemplates: ProjectTemplate[] = [
  {
    type: ProjectTypesEnum.Win,
    category: TemplateCategoriesEnum.SalesAndSuccess,
    name: "Won Deal Interview",
    projectDefaultName: "Won Deal Interviews",
    description: "Win more deals by deeply understanding your best prospects.",
  },
  {
    type: ProjectTypesEnum.Loss,
    category: TemplateCategoriesEnum.SalesAndSuccess,
    name: "Lost Deal Interview",
    projectDefaultName: "Lost Deal Interviews",
    description: "Uncover the reasons why customers choose not to buy.",
  },
  {
    type: ProjectTypesEnum.SuperchargedNPS,
    category: TemplateCategoriesEnum.SalesAndSuccess,
    name: "NPS Check-In",
    projectDefaultName: "NPS Follow-up Interviews",
    description:
      "Get ultra high fidelity customer feedback from low NPS customers.",
  },
  {
    type: ProjectTypesEnum.Churn,
    category: TemplateCategoriesEnum.SalesAndSuccess,
    name: "Churn Interview",
    projectDefaultName: "Churn Interviews",
    description: "Uncover the real causes behind customer churn.",
  },
  {
    type: ProjectTypesEnum.BrandPerception,
    category: TemplateCategoriesEnum.Research,
    name: "Brand Perception",
    projectDefaultName: "Brand Perception Interviews",
    description: "Understand how your brand is perceived in the market.",
  },
  {
    type: ProjectTypesEnum.Discovery,
    category: TemplateCategoriesEnum.Research,
    name: "Discovery Interview",
    projectDefaultName: "Discovery Interviews",
    description: "Discover customer needs and pain points.",
  },
  {
    type: ProjectTypesEnum.ConceptTesting,
    category: TemplateCategoriesEnum.Research,
    name: "Concept Testing",
    projectDefaultName: "Concept Testing Interviews",
    description: "Test new product concepts with potential customers.",
  },
  {
    type: ProjectTypesEnum.CandidateScreen,
    category: TemplateCategoriesEnum.HrAndRecruiting,
    name: "Candidate Screen",
    projectDefaultName: "Candidate Screening Interviews",
    description: "Screen candidates for open positions.",
  },
  {
    type: ProjectTypesEnum.CandidateReferenceCheck,
    category: TemplateCategoriesEnum.HrAndRecruiting,
    name: "Candidate Reference Check",
    projectDefaultName: "Candidate Reference Check Interviews",
    description: "Check references for potential hires.",
  },
  {
    type: ProjectTypesEnum.EmployeeFeedback,
    category: TemplateCategoriesEnum.HrAndRecruiting,
    name: "Employee Feedback",
    projectDefaultName: "Employee Feedback Interviews",
    description: "Get feedback from employees about your company.",
  },
  {
    type: ProjectTypesEnum.EmployeeExitInterview,
    category: TemplateCategoriesEnum.HrAndRecruiting,
    name: "Employee Exit Interview",
    projectDefaultName: "Employee Exit Interviews",
    description: "Understand why employees are leaving your company.",
  },
  {
    type: ProjectTypesEnum.Custom,
    category: null,
    name: "",
    projectDefaultName: "",
    description: "",
  },
];

export const winProjectSurveyQuestion = {
  question:
    "How important were each of the following factors in your decision to buy {{COMPANY_NAME}}?",
  type: "rating" as ComplexQuestionType,
  scale: "important_unimportant" as SurveyScale,
  options: [
    {
      name: "Price — the value provided by {{COMPANY_NAME}} compared to the cost.",
    },
    {
      name: "Product capabilities — the extent to which the {{COMPANY_NAME}} product met your needs.",
    },
    {
      name: "Sales experience — the experience working with the {{COMPANY_NAME}} team during the purchase process.",
    },
    {
      name: "Competition — how {{COMPANY_NAME}} compared to other competitors you evaluated.",
    },
  ],
};

export const lossProjectSurveyQuestion = {
  question:
    "How important were each of the following factors in your decision not to continue with {{COMPANY_NAME}}?",
  type: "rating" as ComplexQuestionType,
  scale: "important_unimportant" as SurveyScale,
  options: [
    {
      name: "Price — the cost of {{COMPANY_NAME}} relative to the value it provides.",
    },
    {
      name: "Product capabilities — missing features or capabilities important to your requirements.",
    },
    {
      name: "Sales experience — the experience working with the {{COMPANY_NAME}} team during the sales process.",
    },
    {
      name: "Competition — alternatives to {{COMPANY_NAME}}, including your previous solution.",
    },
  ],
};

export const churnProjectSurveyQuestion = {
  question:
    "How important were each of the following factors in your decision to stop using {{COMPANY_NAME}}?",
  type: "rating" as ComplexQuestionType,
  scale: "important_unimportant" as SurveyScale,
  options: [
    {
      name: "Price — the cost of {{COMPANY_NAME}} relative to the value it provides.",
    },
    {
      name: "Product capabilities — missing features or capabilities important to your requirements.",
    },
    {
      name: "Competition — alternatives to {{COMPANY_NAME}}, including your previous solution.",
    },
    {
      name: "Business changes — changes to your business that resulted in you no longer getting value from {{COMPANY_NAME}}.",
    },
  ],
};

export const ProjectTypeToDefaultQuestions: Record<
  ProjectTypesEnum,
  ProjectQuestion[]
> = {
  [ProjectTypesEnum.Win]: [
    winProjectSurveyQuestion,
    {
      question:
        "How did you first learn about {{COMPANY_NAME}}? What was the primary problem you were looking to solve?",
    },
    {
      question:
        "What was your role in the purchasing process? Who else from your team was involved?",
    },
    {
      question:
        "Did you consider any other competitors? If so, why did you choose {{COMPANY_NAME}} over them?",
    },
    {
      question:
        "How would you rate your overall experience with the {{COMPANY_NAME}} sales team? What went well, and what could be improved?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.Loss]: [
    lossProjectSurveyQuestion,
    {
      question: "How did you first learn about {{COMPANY_NAME}}?",
    },
    {
      question:
        "Did you evaluate other competitors alongside {{COMPANY_NAME}}? If so, what factors led you to choose a different solution?",
    },
    {
      question:
        "Can you provide feedback on your interactions with the {{COMPANY_NAME}} sales team? What went well, and what could be improved?",
    },
    {
      question:
        "Were there any key features or capabilities you were looking for that {{COMPANY_NAME}} did not provide or address?",
    },
    {
      question:
        "Do you have any other feedback about your decision not to choose {{COMPANY_NAME}}?",
    },
  ],
  [ProjectTypesEnum.AccountCheckIn]: [
    {
      question:
        "Can you share how you and your team currently use {{COMPANY_NAME}}? What is the primary value it provides?",
    },
    {
      question:
        "On a scale from 1 to 10, how satisfied are you with {{COMPANY_NAME}} overall?",
    },
    {
      question:
        "If you could change or improve one aspect of {{COMPANY_NAME}}, what would it be and why?",
    },
    {
      question:
        "Are there any ways the {{COMPANY_NAME}} team can provide better training or support for your team?",
    },
    {
      question:
        "How likely are you to renew your subscription with {{COMPANY_NAME}}?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.SuperchargedNPS]: [
    {
      question:
        "Can you describe how you and your team use {{COMPANY_NAME}} today? Which features or capabilities do you find most valuable?",
    },
    {
      question:
        "If you could change or improve one aspect of {{COMPANY_NAME}}, what would it be and why?",
    },
    {
      question:
        "How would you rate the quality of {{COMPANY_NAME}}'s customer support?",
    },
    {
      question:
        "On a scale from 1 to 10, how satisfied are you with {{COMPANY_NAME}}?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.Churn]: [
    churnProjectSurveyQuestion,
    {
      question:
        "Could you describe the primary reasons you decided to stop using {{COMPANY_NAME}}?",
    },
    {
      question:
        "Have you switched to another product that better serves your needs? If so, which product and why did you choose it?",
    },
    {
      question:
        "How would you rate the quality of support and customer success that {{COMPANY_NAME}} provided?",
    },
    {
      question:
        "Is there anything that {{COMPANY_NAME}} could have done differently to keep you as a customer?",
    },
    {
      question:
        "Would you consider returning to {{COMPANY_NAME}} in the future if your concerns were addressed?",
    },
  ],
  [ProjectTypesEnum.BrandPerception]: [
    {
      question:
        "What key attributes come to mind when you think of {{COMPANY_NAME}}?",
    },
    {
      question:
        "How do you perceive the {{COMPANY_NAME}} brand compared to its competitors?",
    },
    {
      question: "How likely are you to recommend {{COMPANY_NAME}} to others?",
    },
    {
      question:
        "What improvements or changes would you suggest for the {{COMPANY_NAME}} brand?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.Discovery]: [
    {
      question:
        "What are the main challenges or pain points you are currently facing?",
    },
    {
      question: "How do you currently address these challenges or pain points?",
    },
    {
      question:
        "What features or capabilities would you find most valuable in a solution?",
    },
    {
      question:
        "How would you prioritize these features or capabilities in terms of importance?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.ConceptTesting]: [
    {
      question:
        "What are your initial impressions of the new product concept from {{COMPANY_NAME}}?",
    },
    {
      question:
        "How well do you think this product concept addresses your needs or pain points?",
    },
    {
      question:
        "What features or aspects of the product concept do you find most appealing?",
    },
    {
      question:
        "What improvements or changes would you suggest for the product concept?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.CandidateScreen]: [
    {
      question:
        "What motivated you to apply for this position at {{COMPANY_NAME}}?",
    },
    {
      question:
        "Can you describe your relevant experience and skills for this position?",
    },
    {
      question:
        "How do you handle challenges or difficult situations in the workplace?",
    },
    {
      question:
        "What are your long-term career goals and how does this position align with them?",
    },
    {
      question: "Is there any other information you'd like to share?",
    },
  ],
  [ProjectTypesEnum.CandidateReferenceCheck]: [
    {
      question: "How long have you known the candidate and in what capacity?",
    },
    {
      question:
        "Can you provide examples of the candidate's strengths and areas for improvement?",
    },
    {
      question:
        "How would you describe the candidate's work ethic and reliability?",
    },
    {
      question:
        "On a scale of 1 to 10, how strongly would you recommend the candidate for this position at {{COMPANY_NAME}}? Why?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.EmployeeFeedback]: [
    {
      question:
        "How satisfied are you with your current role and responsibilities at {{COMPANY_NAME}}?",
    },
    {
      question:
        "What do you think {{COMPANY_NAME}} is doing well in terms of employee satisfaction?",
    },
    {
      question:
        "What areas do you think {{COMPANY_NAME}} could improve to enhance employee satisfaction?",
    },
    {
      question:
        "How would you rate the support and resources provided by {{COMPANY_NAME}} for your professional development?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.EmployeeExitInterview]: [
    {
      question: "What prompted you to start looking for a new job?",
    },
    {
      question: "What did you like most about working at {{COMPANY_NAME}}?",
    },
    {
      question: "What did you like least about working at {{COMPANY_NAME}}?",
    },
    {
      question:
        "What could {{COMPANY_NAME}} have done differently to keep you as an employee?",
    },
    {
      question: "Is there any other feedback you'd like to share?",
    },
  ],
  [ProjectTypesEnum.Custom]: [],
};

function replaceCompanyNameInText(text: string, companyName: string): string {
  return text.replace(/{{COMPANY_NAME}}/g, companyName);
}

export function replaceCompanyNameInObject<T>(obj: T, companyName: string): T {
  if (typeof obj === "string") {
    return replaceCompanyNameInText(obj, companyName) as unknown as T;
  } else if (Array.isArray(obj)) {
    return obj.map((item: unknown) =>
      replaceCompanyNameInObject(item, companyName),
    ) as T;
  } else if (typeof obj === "object" && obj !== null) {
    const entries = Object.entries(obj).map(
      ([key, value]): [string, unknown] => {
        return [key, replaceCompanyNameInObject(value, companyName)];
      },
    );
    return Object.fromEntries(entries) as T;
  }
  return obj;
}

export function getQuestionsForProjectType(
  projectType: ProjectTypesEnum,
  companyName: string,
  isVoiceAgentMode: boolean,
): ProjectQuestion[] {
  // In this case, questions will be generated using the job description.
  if (isVoiceAgentMode && projectType === ProjectTypesEnum.CandidateScreen)
    return [];

  const questions = ProjectTypeToDefaultQuestions[projectType];
  return questions.map((question) =>
    replaceCompanyNameInObject<ProjectQuestion>(question, companyName),
  );
}

export function generateSurveyTemplateForProject(
  projectName: string,
  companyName: string,
) {
  const name = projectName.toLowerCase();

  // We don't have project template types saved, but we can guess by the name. Otherwise we'll
  // fallback to a generic template.
  if (name.includes("won") || name.includes("win")) {
    return replaceCompanyNameInObject(winProjectSurveyQuestion, companyName);
  }

  if (name.includes("lost") || name.includes("loss")) {
    return replaceCompanyNameInObject(lossProjectSurveyQuestion, companyName);
  }

  if (name.includes("churn")) {
    return replaceCompanyNameInObject(churnProjectSurveyQuestion, companyName);
  }

  return replaceCompanyNameInObject(
    {
      question:
        "Please rate your satisfaction with the following aspects of the {{COMPANY_NAME}} product and service.",
      type: "rating" as ComplexQuestionType,
      scale: "satisfied_dissatisfied" as SurveyScale,
      options: [
        { name: "Product capabilities" },
        { name: "Customer support" },
        { name: "Pricing and value" },
      ],
    },
    companyName,
  );
}
