import { LockClosedIcon } from "@heroicons/react/24/outline";
import { getCompanyHasFeatureAccess, Project, ProjectUpdate } from "app-types";
import { FC, useState } from "react";
import { Textarea } from "ui";
import { useAppDispatch, useAppSelector } from "../../../hooks/hook";
import { UpgradeText } from "../../billing/upgradeText";
import { selectCompany, updateCompanyData } from "../../company/companySlice";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";

interface WelcomeAndOutroMessagesEditorProps {
  project?: Project;
  onSaveProject?: (updates: ProjectUpdate) => void;
}

export const WelcomeAndOutroMessagesEditor: FC<
  WelcomeAndOutroMessagesEditorProps
> = ({ project, onSaveProject }) => {
  const dispatch = useAppDispatch();
  const company = useAppSelector(selectCompany);

  if (!company) return null;

  const entity = project || company;
  if (!entity) return null;

  const [welcomeMessage, setWelcomeMessage] = useState(
    entity.settings.welcome_message || "",
  );
  const [outroMessage, setOutroMessage] = useState(
    entity.settings.outro_message || "",
  );

  const isMissingFeatureAccess = !getCompanyHasFeatureAccess(
    company,
    "whitelabeling",
  );

  const handleSave = (type: "welcome" | "outro") => {
    const updates = {
      welcome_message:
        type === "welcome"
          ? welcomeMessage || null
          : entity.settings.welcome_message,
      outro_message:
        type === "outro" ? outroMessage || null : entity.settings.outro_message,
    };

    if (project && onSaveProject) {
      onSaveProject({
        id: project.id,
        settings: {
          ...project.settings,
          ...updates,
        },
      });
    } else {
      dispatch(
        updateCompanyData({
          id: company.id,
          updates: {
            settings: {
              ...company.settings,
              ...updates,
            },
          },
        }),
      );

      showNotification(dispatch, {
        id: `company-updated-${new Date().getTime()}`,
        primaryMessage: `Organization successfully updated`,
        type: NotificationTypeEnum.SUCCESS,
      });
    }
  };

  return (
    <>
      <label className="block text-sm font-semibold leading-6 text-gray-900 mt-2">
        Welcome message
      </label>
      <div className="mt-2 w-[500px]">
        <Textarea
          value={welcomeMessage}
          onChange={(evt) => setWelcomeMessage(evt.target.value)}
          placeholder="We're excited to get your responses!"
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess}
          maxLength={500}
        />
      </div>
      {welcomeMessage !== (entity.settings.welcome_message || "") && (
        <SaveAndCancelButtons
          onSave={() => handleSave("welcome")}
          onCancel={() =>
            setWelcomeMessage(entity.settings.welcome_message || "")
          }
        />
      )}
      <div className="mt-2 text-sm text-gray-600">
        A custom greeting (up to 500 characters) to show to participants before
        they start their interview.
        {project ? (
          <span className="text-gray-600">
            {" "}
            This message will override the welcome message set in your
            organization settings.
          </span>
        ) : null}
        {isMissingFeatureAccess && (
          <UpgradeText planFeatureName="whitelabeling" />
        )}
      </div>

      <label className="block text-sm font-semibold leading-6 text-gray-900 mt-4">
        Outro message
      </label>
      <div className="mt-2 w-[500px]">
        <Textarea
          value={outroMessage}
          onChange={(evt) => setOutroMessage(evt.target.value)}
          placeholder="We really appreciate your responses. You'll hear from the team soon."
          leftIcon={
            isMissingFeatureAccess ? (
              <LockClosedIcon className="h-4 w-4 text-gray-700" />
            ) : undefined
          }
          isDisabled={isMissingFeatureAccess}
          maxLength={500}
        />
      </div>
      {outroMessage !== (entity.settings.outro_message || "") && (
        <SaveAndCancelButtons
          onSave={() => handleSave("outro")}
          onCancel={() => setOutroMessage(entity.settings.outro_message || "")}
        />
      )}
      <div className="mt-2 text-sm text-gray-600">
        A custom message (up to 500 characters) to show to participants after
        they complete their interview.
        {project ? (
          <span className="text-gray-600">
            {" "}
            This message will override the outro message set in your
            organization settings.
          </span>
        ) : null}
        {isMissingFeatureAccess && (
          <UpgradeText planFeatureName="whitelabeling" />
        )}
      </div>
    </>
  );
};
