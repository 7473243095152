import { CheckCircleIcon } from "@heroicons/react/24/outline";
import {
  getProjectAndJobTypeProperty,
  InterviewLanguagesEnum,
  JobTypePropertyEnum,
  ProjectUpdate,
  ProjectWithInterviewCount,
} from "app-types";
import { FC, useState } from "react";
import { Button, ButtonVariantsEnum, Label, SizesEnum } from "ui";
import { ReadOnlyBulletedList } from "../../../../components/readOnlyBulletedList";
import { useAppSelector } from "../../../../hooks/hook";
import { selectAssessmentById } from "../../../assessments/assessmentsSlice";
import { RecruitingQuestionsManagerSlideover } from "../../surveyQuestions/recruitingQuestionsManagerSlideover";

const NUM_QUESTIONS_TO_DISPLAY = 4;
const NUM_CRITERIA_TO_DISPLAY = 4;

interface RecruitingSettingsQuestionsSectionProps {
  project: ProjectWithInterviewCount;
  language: InterviewLanguagesEnum;
  onSaveProject: (changes: ProjectUpdate) => void;
}

export const RecruitingSettingsQuestionsSection: FC<
  RecruitingSettingsQuestionsSectionProps
> = ({ project, language, onSaveProject }) => {
  const assessment = useAppSelector(
    selectAssessmentById(
      getProjectAndJobTypeProperty(project, JobTypePropertyEnum.AssessmentId),
    ),
  );

  const questions = getProjectAndJobTypeProperty(
    project,
    JobTypePropertyEnum.Questions,
  );

  const [isQuestionsManagerSlideoverOpen, setIsQuestionsManagerSlideoverOpen] =
    useState(false);

  // Criteria items will only be displayed for recruiting projects with an assessment
  const criteriaItems = (assessment?.custom_field_definitions || []).map(
    (criterion) => criterion.display_name,
  );

  return (
    <div className="flex flex-col justify-between mb-2">
      <div className="flex flex-col space-y-3">
        {assessment ? (
          <div className="">
            <Label size={SizesEnum.SMALL} className="mb-2">
              Assessment criteria
            </Label>
            {criteriaItems.length > 0 ? (
              <ReadOnlyBulletedList
                items={criteriaItems}
                maxItemsToDisplay={NUM_CRITERIA_TO_DISPLAY}
                onListClick={() => {
                  setIsQuestionsManagerSlideoverOpen(true);
                }}
                itemDescriptor="criteria"
                icon={<CheckCircleIcon className="w-4 h-4 text-green-600" />}
              />
            ) : (
              <div className="text-sm text-gray-500">
                <Button
                  variant={ButtonVariantsEnum.Primary}
                  onClick={() => {
                    setIsQuestionsManagerSlideoverOpen(true);
                  }}
                  label="Add assessment criteria"
                />
              </div>
            )}
          </div>
        ) : null}
        <div>
          <Label size={SizesEnum.SMALL} className="mb-2">
            AI interview questions
          </Label>
          {questions.length > 0 ? (
            <ReadOnlyBulletedList
              items={questions.map((question) => question.question)}
              maxItemsToDisplay={NUM_QUESTIONS_TO_DISPLAY}
              onListClick={() => {
                setIsQuestionsManagerSlideoverOpen(true);
              }}
              itemDescriptor="questions"
            />
          ) : (
            <div className="mt-2">
              <Button
                variant={ButtonVariantsEnum.Primary}
                onClick={() => {
                  setIsQuestionsManagerSlideoverOpen(true);
                }}
                label="Add interview questions"
              />
            </div>
          )}
        </div>
      </div>

      <RecruitingQuestionsManagerSlideover
        basicQuestions={questions}
        projectId={project.id}
        language={language}
        assessment={assessment ?? null}
        onSaveProject={onSaveProject}
        isOpen={isQuestionsManagerSlideoverOpen}
        onClose={() => {
          setIsQuestionsManagerSlideoverOpen(false);
        }}
      />
    </div>
  );
};
