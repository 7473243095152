import {
  ComplexQuestion,
  ComplexQuestionInsert,
  getRatingsFromSurveyScale,
  surveyScales,
} from "app-types";
import { FC } from "react";
import { InfoTooltip, Label, Select, SizesEnum, Textarea } from "ui";
import { MAX_CHARACTERS_PER_QUESTION } from "./basicQuestionEditor";
import { SurveyOptionsEditor } from "./surveyOptionsEditor";

const surveyScalesDropdownOptions = surveyScales.map((key) => ({
  name: key
    .toLowerCase()
    .split("_")
    .join(" / ")
    .replace(/\b\w/g, (letter) => letter.toUpperCase()), // Transform key into human-readable format
  value: key,
}));

interface MatrixQuestionEditorProps {
  question: ComplexQuestion | ComplexQuestionInsert;
  onChange: (question: ComplexQuestion | ComplexQuestionInsert) => void;
}

export const MatrixQuestionEditor: FC<MatrixQuestionEditorProps> = ({
  question,
  onChange,
}) => {
  if (question.type !== "rating") {
    return null;
  }

  return (
    <div>
      <div className="w-full">
        <div>
          <Textarea
            label="Question"
            value={question.question}
            placeholder="Add your survey question..."
            onChange={(evt) => {
              onChange({
                ...question,
                question: evt.target.value,
              });
            }}
            shouldDisableNewline
            maxLength={MAX_CHARACTERS_PER_QUESTION}
          />
        </div>

        <div className="flex justify-between items-center mt-3">
          <div className="w-52">
            <Select
              label={
                <div className="flex align-">
                  <span>Rating scale</span>
                  <span className="mt-0.5 ml-0.5">
                    <InfoTooltip
                      id="rating-scale-tooltip"
                      place="right"
                      content={
                        <span>
                          The 5-point scale participants will use to rate your
                          survey options. For example:{" "}
                          {getRatingsFromSurveyScale(question.scale)
                            .map((r) => r.name)
                            .join(", ")}
                          .
                        </span>
                      }
                    />
                  </span>
                </div>
              }
              options={surveyScalesDropdownOptions}
              currentSelection={surveyScalesDropdownOptions.find(
                (o) => o.value === question.scale,
              )}
              placeholder="Select a scale for this survey"
              onChange={(option) => {
                const newScale = surveyScalesDropdownOptions.find(
                  (o) => o.name === option.name,
                )?.value;

                if (newScale) {
                  onChange({
                    ...question,
                    scale: newScale,
                  });
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <Label size={SizesEnum.SMALL}>Options</Label>
      </div>
      <SurveyOptionsEditor
        onOptionsChange={(options) => {
          onChange({
            ...question,
            options,
          });
        }}
        options={question.options}
        maxNumberOfOptions={8}
      />
    </div>
  );
};
