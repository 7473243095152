import { FC } from "react";
import { SettingsSection } from "../shared/settingsSection";
import { CRMIntegrationsSection } from "./crmIntegrationsSection";
import { SlackIntegrationSection } from "./slackIntegrationSection";

export const IntegrationSettingsSection: FC = () => {
  return (
    <SettingsSection>
      <SlackIntegrationSection />
      <CRMIntegrationsSection />
    </SettingsSection>
  );
};
