import { PlanFeatureName } from "app-types";
import { FC, useState } from "react";
import { Modal, ModalHeader } from "ui";
import { UpsellBanner } from "./upsellBanner";

interface UpgradeTextProps {
  planFeatureName: PlanFeatureName;
}

export const UpgradeText: FC<UpgradeTextProps> = ({ planFeatureName }) => {
  const [shouldShowUpsellBanner, setShouldShowUpsellBanner] = useState(false);

  return (
    <>
      <span
        className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
        onClick={() => setShouldShowUpsellBanner(true)}
      >
        {" "}
        Upgrade to access.
      </span>
      <Modal
        isOpen={shouldShowUpsellBanner}
        onCancel={() => setShouldShowUpsellBanner(false)}
      >
        <ModalHeader onClose={() => setShouldShowUpsellBanner(false)}>
          Upgrade for access
        </ModalHeader>
        <div className="mt-6">
          <UpsellBanner planFeatureName={planFeatureName} />
        </div>
      </Modal>
    </>
  );
};
