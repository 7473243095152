import { useState } from "react";
import { Label, Select, SizesEnum } from "ui";
import { SaveAndCancelButtons } from "../saveAndCancelButtons";

interface AutoAdvanceSettingProps {
  autoAdvanceAssessmentScoreThreshold: number | null;
  onSave: (autoAdvanceAssessmentScoreThreshold: number | null) => void;
}

const THRESHOLD_OPTIONS = [
  {
    name: "Disabled (recommended)",
    value: undefined,
  },
  {
    name: "Acceptable (3) and above",
    value: 3,
  },
  {
    name: "Good (4) and above",
    value: 4,
  },
  {
    name: "Excellent (5)",
    value: 5,
  },
];

export const AutoAdvanceSetting = ({
  autoAdvanceAssessmentScoreThreshold,
  onSave,
}: AutoAdvanceSettingProps) => {
  const [selectedThreshold, setSelectedThreshold] = useState(
    autoAdvanceAssessmentScoreThreshold,
  );

  const hasChanges =
    (selectedThreshold ?? null) !== autoAdvanceAssessmentScoreThreshold;

  return (
    <div className="space-y-2">
      <Label size={SizesEnum.SMALL}>Auto-advance candidates</Label>
      <div className="w-[220px]">
        <Select
          currentSelection={THRESHOLD_OPTIONS.find(
            (option) => option.value === (selectedThreshold ?? undefined),
          )}
          onChange={(option) => {
            setSelectedThreshold(option.value as number);
          }}
          options={THRESHOLD_OPTIONS}
        />
      </div>
      <div className="text-sm text-slate-600">
        Candidates that meet or exceed this score will be automatically
        advanced. Other candidates will require recruiter review.
      </div>
      {hasChanges ? (
        <SaveAndCancelButtons
          onSave={() => {
            onSave(selectedThreshold);
          }}
          onCancel={() => {
            setSelectedThreshold(autoAdvanceAssessmentScoreThreshold);
          }}
        />
      ) : null}
    </div>
  );
};
