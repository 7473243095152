import {
  ArrowTopRightOnSquareIcon,
  BuildingOfficeIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import {
  Contact,
  CustomField,
  CustomFieldValueData,
  Integration,
  getContactDisplayNames,
} from "app-types";
import { format, parseISO } from "date-fns";
import { FC, useEffect, useRef, useState } from "react";
import { Button, ButtonVariantsEnum, IconButton } from "ui";
import { useAppSelector } from "../../hooks/hook";
import { selectAccountForContact } from "../accounts/accountsSlice";

interface CustomFieldsSectionProps {
  contact: Contact;
  integration: Integration;
}

const maxHeight = 48;

export const CustomFieldsSection: FC<CustomFieldsSectionProps> = ({
  contact,
  integration,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const gridRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const account = useAppSelector(selectAccountForContact(contact));

  useEffect(() => {
    if (gridRef.current) {
      const isContentOverflowing = gridRef.current.scrollHeight > maxHeight;
      setIsOverflowing(isContentOverflowing);
    }
  }, [contact, account]); // Add dependencies that could change the grid's content

  const providerContactFields = sortCustomFields(
    contact.custom_fields.filter((f) => f.provider === integration.provider),
  );
  const providerAccountFields = sortCustomFields(
    (account?.custom_fields || []).filter(
      (f) => f.provider === integration.provider,
    ),
  );

  const { primaryDisplayName } = getContactDisplayNames(contact);

  // If we don't have any fields, don't show anything
  if (
    providerContactFields.length === 0 &&
    providerAccountFields.length === 0 &&
    !contact.ext_id &&
    !account?.ext_id
  ) {
    return null;
  }

  return (
    <div className="flex flex-row space-x-4 mt-4">
      <img rel="icon" src="/salesforce.png" className="h-4 mt-[2px]" />
      <div>
        <div
          ref={gridRef}
          className="overflow-hidden grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-4"
          style={{
            maxHeight: isExpanded ? "none" : `${maxHeight}px`,
            overflow: "hidden",
          }}
        >
          {contact &&
            contact.ext_id &&
            renderField({
              label: "Contact",
              value: primaryDisplayName,
              link:
                "instance_url" in integration.settings
                  ? `${integration.settings.instance_url}/${contact.ext_id}`
                  : undefined,
              key: `contact-field-${contact.id}`,
            })}
          {account &&
            account.ext_id &&
            renderField({
              label: "Account",
              value: account.name,
              link:
                "instance_url" in integration.settings
                  ? `${integration.settings.instance_url}/${account.ext_id}`
                  : undefined,
              isAccountField: true,
              key: `account-field-${account.id}`,
            })}
          {providerAccountFields.map((f, i) => {
            return renderField({
              label: f.display_name,
              value: customFieldDataValueToRenderable(f.data),
              isAccountField: true,
              key: `account-field-${f.field_name}`,
            });
          })}
          {providerContactFields.map((f, i) => {
            return renderField({
              label: f.display_name,
              value: customFieldDataValueToRenderable(f.data),
              key: `contact-field-${f.field_name}`,
            });
          })}
        </div>
        {isOverflowing && (
          <div className="mt-2">
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              variant={ButtonVariantsEnum.Tertiary}
              icon={
                isExpanded ? (
                  <MinusIcon className="h-3.5 w-3.5 mr-1" />
                ) : (
                  <PlusIcon className="h-3.5 w-3.5 mr-1" />
                )
              }
              label={isExpanded ? "Show less" : "Show all fields"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

interface FieldRenderOptions {
  label: string;
  value: string;
  key: string;
  link?: string;
  isAccountField?: boolean;
}
function renderField({
  label,
  value,
  link,
  isAccountField,
  key,
}: FieldRenderOptions) {
  return (
    <div className="flex flex-col text-sm min-w-[100px]" key={key}>
      <div className="mb-1 flex flex-row space-x-1 items-center font-semibold text-gray-500">
        {isAccountField && (
          <BuildingOfficeIcon className="h-3.5 w-3.5 text-gray-600" />
        )}
        <div>{label}</div>
        {link && (
          <IconButton
            icon={
              <ArrowTopRightOnSquareIcon className="h-3.5 w-3.5 text-gray-600" />
            }
            variant={ButtonVariantsEnum.Tertiary}
            onClick={() => {
              window.open(link, "_blank");
            }}
          ></IconButton>
        )}
      </div>
      <div className="flex flex-row space-x-1 break-all whitespace-normal text-wrap">
        {value}
      </div>
    </div>
  );
}

function sortCustomFields(fields: CustomField[]) {
  return fields.sort((a, b) => {
    // Sort alphabetically by display_name
    return a.display_name.localeCompare(b.display_name);
  });
}

const dateDisplayFormat = "MMM d, yyyy h:mma";
function customFieldDataValueToRenderable(
  customFieldData: CustomFieldValueData,
): string {
  const { value, display_value } = customFieldData;

  // If we have a display value string (like email address for a reference field), display that
  if (display_value) return display_value;

  if (value === null) {
    return "-";
  }

  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }

  // Check if value is a string that can be parsed as a date
  if (typeof value === "string") {
    const parsedDate = parseISO(value);
    // Check if the parsed date is valid
    if (!isNaN(parsedDate.getTime())) {
      // Format the parsed date
      return format(parsedDate, dateDisplayFormat);
    }
  }

  if (value instanceof Date) {
    // Example format: 'yyyy-MM-dd HH:mm:ss'
    // You can change the format string as per your requirement
    return format(value, dateDisplayFormat);
  }

  return (value || "").toString();
}
