import { PencilIcon } from "@heroicons/react/24/outline";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariantsEnum, IconButton } from "ui";
import {
  KeywordTrackerCard,
  KeywordTrackerCardProps,
} from "./keywordTrackerCard";

export const KeywordTrackerCardContainer: FC<
  KeywordTrackerCardProps & { questionNumber: number | undefined }
> = (props) => {
  const navigate = useNavigate();
  const { keywordTracker } = props;

  const renderContent = () => {
    if (keywordTracker.keywords.length === 0) {
      return (
        <div className="p-4 flex flex-col text-center text-base text-gray-700">
          {`Add keywords like competitor names to track when they appear in interviews.`}
          <div className="flex items-center justify-center mt-4">
            <Button
              variant={ButtonVariantsEnum.Primary}
              onClick={() => {
                navigate(`/settings/keyword-trackers`);
              }}
            >
              Add keywords
            </Button>
          </div>
        </div>
      );
    }

    return <KeywordTrackerCard {...props} />;
  };

  const title =
    keywordTracker.type === "competitive"
      ? "Competitive analysis"
      : keywordTracker.name;

  return (
    <InsightsCard
      header={
        <>
          <div className="flex flex-row items-center text-base font-semibold text-gray-900 space-x-1 min-h-[28px]">
            <div>{title}</div>
            {keywordTracker.type === "competitive" && (
              <div className="hidden group-hover:block">
                <IconButton
                  variant={ButtonVariantsEnum.Tertiary}
                  onClick={() => {
                    navigate(`/settings/keyword-trackers`);
                  }}
                  icon={<PencilIcon className="h-4 w-4 text-gray-600" />}
                />
              </div>
            )}
          </div>
        </>
      }
      body={renderContent()}
    />
  );
};

export const InsightsCard: FC<{ header: ReactNode; body: ReactNode }> = ({
  header,
  body,
}) => {
  return (
    <div className="overflow-hidden flex flex-col rounded-xl border border-slate-200">
      <div className="flex group flex-row items-center border-b border-slate-900/5 bg-slate-100 px-3 py-1.5">
        <div className="flex flex-col">{header}</div>
      </div>
      {body}
    </div>
  );
};
