import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingStatesEnum, Teammate } from "app-types";
import { putTeammate } from "../../api/supabaseService";
import type { RootState } from "../../app/store";
import { postCompanyData } from "../company/companySlice";

export interface TeammateState {
  teammate: Teammate | null;
  status: LoadingStatesEnum;
  error: string | null;
}
const initialState: TeammateState = {
  teammate: null,
  status: LoadingStatesEnum.LOADING,
  error: null,
};

// Only updating first_name and last_name is supported at the moment (see 'putTeammate').
export const putTeammateData = createAsyncThunk(
  "teammate/post",
  async (teammate: Teammate, thunkAPI) => {
    try {
      const response = await putTeammate(teammate);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const teammateSlice = createSlice({
  name: "teammate",
  initialState,
  reducers: {
    setTeammate: (state, action: PayloadAction<Teammate>) => {
      return {
        ...state,
        teammate: action.payload,
        status: LoadingStatesEnum.LOADED,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(putTeammateData.pending, (state) => {
        state.status = LoadingStatesEnum.LOADING;
      })
      .addCase(putTeammateData.fulfilled, (state, action) => {
        state.status = LoadingStatesEnum.LOADED;
        state.teammate = action.payload;
      })
      .addCase(putTeammateData.rejected, (state, action) => {
        state.status = LoadingStatesEnum.ERROR;
        state.error = action.error.message || null;
      })
      .addCase(postCompanyData.fulfilled, (state, action) => {
        // Update the teammate's company_id when we create a new company for a new user
        if (state.teammate) state.teammate.company_id = action.payload.id;
      });
  },
});

export const { setTeammate } = teammateSlice.actions;
export default teammateSlice.reducer;

export const selectTeammateLoadingStatus = (state: RootState) =>
  state.teammate.status;
export const selectTeammate = (state: RootState) => state.teammate.teammate;
