import { FC, ReactNode } from "react";

interface SettingsTableEmptyBodyContentProps {
  colSpan: number;
  children: ReactNode;
}

export const SettingsTableEmptyBodyContent: FC<
  SettingsTableEmptyBodyContentProps
> = ({ colSpan, children }) => (
  <tr>
    <td
      className="px-3 py-9 text-center text-sm font-semibold text-gray-700"
      colSpan={colSpan}
    >
      {children}
    </td>
  </tr>
);
