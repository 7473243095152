import {
  Company,
  parseMultilineContent,
  ProjectUpdate,
  ProjectWithInterviewCount,
  splitProjectQuestions,
} from "app-types";
import { getInterviewLinkForProject, Label, SizesEnum } from "ui";
import { useUpdateJobTypeMutation } from "../../../../api/redux";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../../notificationsOverlay/notificationsSlice";
import { AppearanceSettingsSection } from "../appearanceSettingsSection";
import { AutoAdvanceSetting } from "../autoAdvanceSetting";
import { InterviewLanguageSetting } from "../interviewLanguageSetting";
import { InterviewLinkCopybox } from "../interviewLinkCopybox";
import { JobDescriptionSetting } from "../jobDescriptionSetting";
import { MessagingAutomationSection } from "../messagingAutomationSection";
import { PhoneNumberDisplaySection } from "../phoneNumberDisplaySection";
import { ProjectNotificationsSettingsSection } from "../projectNotificationsSettingsSection";
import { JobTypeContext } from "./jobTypeContext";
import { RecruitingOtherSettings } from "./recruitingOtherSettings";
import { RecruitingSettingsQuestionsSection } from "./recruitingSettingsQuestionsSection";

interface RecruitingProjectSettingsProps {
  project: ProjectWithInterviewCount;
  company: Company;
  onSaveProject: (project: ProjectUpdate) => Promise<void>;
  onClickSharingTemplates: () => void;
}

export const RecruitingProjectSettings = ({
  project,
  company,
  onSaveProject,
  onClickSharingTemplates,
}: RecruitingProjectSettingsProps) => {
  const dispatch = useAppDispatch();
  const { basicQuestions } = splitProjectQuestions(project.questions);
  const teammate = useAppSelector((state) => state.teammate.teammate);
  const [updateJobType] = useUpdateJobTypeMutation();

  return (
    <>
      <div className="flex flex-col space-y-6">
        <div>
          <div className="bg-white border border-gray-200 rounded-lg px-4 py-3">
            <div className="flex flex-row space-x-4">
              <div className="flex-shrink-0">
                <InterviewLinkCopybox
                  inviteLinkUrl={getInterviewLinkForProject(
                    import.meta.env.VITE_INTERVIEW_APP_BASE_URL,
                    company,
                    project,
                  )}
                  project={project}
                  onCopyInterviewLink={() => {
                    showNotification(dispatch, {
                      id: `project-${
                        project.id
                      }-link-copied-${new Date().getTime()}`,
                      primaryMessage: `Interview link copied to clipboard`,
                      type: NotificationTypeEnum.SUCCESS,
                    });
                  }}
                />
              </div>
              <div className="flex-shrink-0">
                <Label size={SizesEnum.SMALL}>Phone number</Label>
                <PhoneNumberDisplaySection projectId={project.id} />
              </div>
            </div>
            <div className="text-sm mb-2 text-slate-800">
              Send the interview link or phone number to candidates or add it
              directly to your job posting (
              <span
                onClick={onClickSharingTemplates}
                className="cursor-pointer font-medium text-blue-600 hover:text-blue-500"
              >
                See Templates
              </span>
              ). Links work on desktop or mobile browsers and can be customized
              with a custom logo and messaging.
            </div>
          </div>
        </div>

        <div>
          <Label size={SizesEnum.LARGE}>Interview Settings</Label>
          <div className="flex flex-col space-y-3">
            <div>
              <RecruitingSettingsQuestionsSection
                company={company}
                project={project}
                questions={basicQuestions}
                onSaveProject={(p) => {
                  void onSaveProject(p);

                  // Double write to the job type
                  if (project.job_type) {
                    void updateJobType({
                      id: project.job_type.id,
                      questions: p.questions,
                    });
                  }
                }}
              />
            </div>

            <JobTypeContext
              context={project.settings.prompt_context}
              onSave={(newContext) => {
                void onSaveProject({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    prompt_context: newContext ?? null,
                  },
                });

                // Double write to the job type
                if (project.job_type) {
                  void updateJobType({
                    id: project.job_type.id,
                    prompt_context: newContext
                      ? parseMultilineContent(newContext)
                      : null,
                  });
                }
              }}
            />

            <JobDescriptionSetting
              jobDescription={project.job_description}
              onSave={(newJobDescription) => {
                void onSaveProject({
                  id: project.id,
                  job_description: newJobDescription,
                });

                // Double write to the job type
                if (project.job_type && newJobDescription) {
                  void updateJobType({
                    id: project.job_type.id,
                    job_description: parseMultilineContent(newJobDescription),
                  });
                }
              }}
            />
            <InterviewLanguageSetting
              language={project.settings.interview_language}
              onSave={(newLanguage) => {
                void onSaveProject({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    interview_language: newLanguage,
                  },
                });

                // Double write to the job type
                if (project.job_type) {
                  void updateJobType({
                    id: project.job_type.id,
                    settings: {
                      ...project.job_type.settings,
                      interview_languages: [newLanguage],
                    },
                  });
                }
              }}
            />
            <AutoAdvanceSetting
              autoAdvanceAssessmentScoreThreshold={
                project.settings.auto_advance_assessment_score_threshold
              }
              onSave={(newThreshold) => {
                void onSaveProject({
                  id: project.id,
                  settings: {
                    ...project.settings,
                    auto_advance_assessment_score_threshold: newThreshold,
                  },
                });

                // Double write to the job type
                if (project.job_type) {
                  void updateJobType({
                    id: project.job_type.id,
                    settings: {
                      ...project.job_type.settings,
                      auto_advance_assessment_score_threshold: newThreshold,
                    },
                  });
                }
              }}
            />
          </div>
        </div>
      </div>

      {/* TODO: Uncomment this when sending of messages is complete */}
      {teammate?.email.endsWith("@alpharun.com") ? (
        <MessagingAutomationSection project={project} />
      ) : null}
      <ProjectNotificationsSettingsSection
        project={project}
        onSaveProject={(p) => void onSaveProject(p)}
      />
      <AppearanceSettingsSection
        onSaveProject={(p) => void onSaveProject(p)}
        company={company}
        project={project}
      />
      <RecruitingOtherSettings
        onSaveProject={(p) => void onSaveProject(p)}
        project={project}
      />
    </>
  );
};
