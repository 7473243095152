import {
  type BasicQuestion,
  type InterviewLanguagesEnum,
  type Project,
  type ResumeCollectionTypesEnum,
  splitProjectQuestions,
  stringifyMultilineContent,
} from "../..";

export enum InterviewAgentTypesEnum {
  GenericInterviewer = "generic",
  RecruitingInterviewer = "recruiter",
}

export enum ProjectEmailDigestFrequencyEnum {
  INSTANT = "instant",
  DAILY = "daily",
}

export interface ProjectPublicAPI {
  id: string;
  name: string;
}

export enum JobTypePropertyEnum {
  AssessmentId = "assessment_id",
  Questions = "questions",
  PromptContext = "prompt_context",
  JobDescription = "job_description",
  Language = "language",
  AutoAdvanceAssessmentScoreThreshold = "auto_advance_assessment_score_threshold",
  ShouldForceFullscreen = "should_force_fullscreen",
  ShouldRecordVideo = "should_record_video",
  ResumeCollectionType = "resume_collection_type",
}

export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.Questions,
): BasicQuestion[];
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName:
    | JobTypePropertyEnum.PromptContext
    | JobTypePropertyEnum.JobDescription
    | JobTypePropertyEnum.AssessmentId,
): string | null;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.Language,
): InterviewLanguagesEnum;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold,
): number | null;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName:
    | JobTypePropertyEnum.ShouldForceFullscreen
    | JobTypePropertyEnum.ShouldRecordVideo,
): boolean;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum.ResumeCollectionType,
): ResumeCollectionTypesEnum | null;
export function getProjectAndJobTypeProperty(
  project: Project,
  propertyName: JobTypePropertyEnum,
) {
  if (project.job_type) {
    const jobType = project.job_type;
    switch (propertyName) {
      case JobTypePropertyEnum.Questions:
        return jobType.questions;
      case JobTypePropertyEnum.PromptContext:
        return jobType.prompt_context
          ? stringifyMultilineContent(jobType.prompt_context)
          : null;
      case JobTypePropertyEnum.JobDescription:
        return stringifyMultilineContent(jobType.job_description);
      case JobTypePropertyEnum.Language:
        return jobType.settings.interview_languages[0];
      case JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold:
        return jobType.settings.auto_advance_assessment_score_threshold;
      case JobTypePropertyEnum.ShouldForceFullscreen:
        return jobType.settings.should_force_fullscreen;
      case JobTypePropertyEnum.ShouldRecordVideo:
        return jobType.settings.should_record_video;
      case JobTypePropertyEnum.ResumeCollectionType:
        return jobType.settings.resume_collection_type;
      case JobTypePropertyEnum.AssessmentId:
        return jobType.assessment_id;
      default:
        throw new Error(`Invalid property name`);
    }
  }

  // TODO: When we've fully moved over to job type, remove fallback to project settings
  switch (propertyName) {
    case JobTypePropertyEnum.Questions:
      return splitProjectQuestions(project.questions).basicQuestions;
    case JobTypePropertyEnum.PromptContext:
      return project.settings.prompt_context;
    case JobTypePropertyEnum.JobDescription:
      return project.job_description;
    case JobTypePropertyEnum.Language:
      return project.settings.interview_language;
    case JobTypePropertyEnum.AutoAdvanceAssessmentScoreThreshold:
      return project.settings.auto_advance_assessment_score_threshold;
    case JobTypePropertyEnum.ShouldForceFullscreen:
      return project.settings.should_force_fullscreen;
    case JobTypePropertyEnum.ShouldRecordVideo:
      return project.settings.should_record_video;
    case JobTypePropertyEnum.ResumeCollectionType:
      return project.settings.resume_collection_type;
    case JobTypePropertyEnum.AssessmentId:
      return project.assessment_id;
    default:
      throw new Error(`Invalid property name`);
  }
}
