import { useCallback, useState } from "react";
import { SimpleModal, SimpleModalVariantsEnum } from "../Modal/modal";

interface UseConfirmationModalProps {
  needsConfirmation?: boolean;
  onClose: () => void;
}

interface RenderConfirmationModalProps {
  message: string;
}

export const useConfirmationModal = ({
  needsConfirmation,
  onClose: onConfirm,
}: UseConfirmationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderConfirmationModal = ({
    message,
  }: RenderConfirmationModalProps) => (
    <SimpleModal
      isOpen={isOpen}
      onCancel={() => setIsOpen(false)}
      title={"Are you sure?"}
      subtitle={message}
      confirmButtonText={"Close"}
      onConfirm={() => {
        setIsOpen(false);
        onConfirm();
      }}
      variant={SimpleModalVariantsEnum.Standard}
    />
  );

  const onConfirmThenClose = useCallback(() => {
    if (needsConfirmation) {
      setIsOpen(true);
    } else {
      onConfirm();
    }
  }, [needsConfirmation, onConfirm]);

  return {
    renderConfirmationModal,
    onConfirmThenClose,
  };
};
