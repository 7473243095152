import {
  MessageTemplate,
  parseMultilineContent,
  parseSingleLineContent,
  stringifyMultilineContent,
  stringifySingleLineContent,
} from "app-types";
import { FC, useEffect, useState } from "react";
import { Button, ButtonVariantsEnum, Modal, ModalHeader } from "ui";
import { useUpdateMessageTemplateMutation } from "../../../api/redux";
import { MessageTemplateForm } from "./messageTemplateForm";

export interface MessageTemplateEditDialogProps {
  messageTemplate: MessageTemplate | undefined;
  onClose: () => void;
}

export const MessageTemplateEditDialog: FC<MessageTemplateEditDialogProps> = ({
  messageTemplate,
  onClose,
}) => {
  const [updateMessageTemplate, { isLoading: isSaving }] =
    useUpdateMessageTemplateMutation();
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  useEffect(() => {
    if (messageTemplate) {
      setSubject(stringifySingleLineContent(messageTemplate.subject));
      setBody(stringifyMultilineContent(messageTemplate.body));
    }
  }, [messageTemplate]);

  const resetState = () => {
    setSubject("");
    setBody("");
  };

  const onCancel = () => {
    resetState();
    onClose();
  };

  const onEdit = async () => {
    if (!messageTemplate) return;

    await updateMessageTemplate({
      id: messageTemplate.id,
      subject: parseSingleLineContent(subject),
      body: parseMultilineContent(body),
    }).unwrap();

    resetState();
    onClose();
  };

  const isValid = !!subject.trim() && !!body.trim();

  return (
    <Modal isOpen={!!messageTemplate} onCancel={onCancel}>
      <ModalHeader onClose={onCancel}>Edit message template</ModalHeader>
      <div className="my-4">
        <MessageTemplateForm
          subject={subject}
          body={body}
          onSubjectChange={setSubject}
          onBodyChange={setBody}
        />
      </div>
      <div className="flex justify-end space-x-2">
        <Button variant={ButtonVariantsEnum.Secondary} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant={ButtonVariantsEnum.Primary}
          isLoading={isSaving}
          isDisabled={!isValid}
          onClick={onEdit}
        >
          Save changes
        </Button>
      </div>
    </Modal>
  );
};
