import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { JobTypeDB as JobType, MessageTemplate } from "app-types";
import { apiBaseUrl, getApiAuthorizationHeaderValue } from "./shared";

const baseQuery = fetchBaseQuery({
  baseUrl: apiBaseUrl,
  prepareHeaders: async (headers) => {
    const authorization = await getApiAuthorizationHeaderValue();
    headers.set("Authorization", authorization);
  },
});

const TagType = {
  MessageTemplate: "MessageTemplate",
  JobType: "JobType",
} as const;

type TagTypes = (typeof TagType)[keyof typeof TagType];

const SpecialTagId = {
  All: "$all",
} as const;

// Helper functions for tag generation
const generateTags = <T extends { id: string }>(
  type: TagTypes,
  result?: T[],
) => [
  { type, id: SpecialTagId.All },
  ...(result ?? []).map(({ id }) => ({ type, id })),
];

const generateInvalidationTags = (type: TagTypes, id?: string) =>
  id
    ? [
        { type, id },
        { type, id: SpecialTagId.All },
      ]
    : [{ type, id: SpecialTagId.All }];

export const api = createApi({
  baseQuery,
  tagTypes: [TagType.MessageTemplate, TagType.JobType],
  endpoints: (builder) => ({
    // Message Template endpoints
    allMessageTemplates: builder.query<MessageTemplate[], void>({
      query: () => "/message-templates",
      providesTags: (result) => generateTags(TagType.MessageTemplate, result),
    }),
    createMessageTemplate: builder.mutation<
      MessageTemplate,
      Pick<MessageTemplate, "type" | "subject" | "body">
    >({
      query: (values) => ({
        method: "POST",
        url: "/message-templates",
        body: values,
      }),
      invalidatesTags: () => generateInvalidationTags(TagType.MessageTemplate),
    }),
    updateMessageTemplate: builder.mutation<
      MessageTemplate,
      Pick<MessageTemplate, "id"> &
        Partial<Pick<MessageTemplate, "type" | "subject" | "body">>
    >({
      query: (values) => ({
        method: "PATCH",
        url: `/message-templates/${encodeURIComponent(values.id)}`,
        body: values,
      }),
      invalidatesTags: (result, error, { id }) =>
        generateInvalidationTags(TagType.MessageTemplate, id),
    }),
    deleteMessageTemplate: builder.mutation<void, string>({
      query: (id) => ({
        method: "DELETE",
        url: `/message-templates/${encodeURIComponent(id)}`,
      }),
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        const patch = dispatch(
          api.util.updateQueryData(
            "allMessageTemplates",
            undefined,
            (draft) => {
              const index = draft.findIndex((template) => template.id === id);
              if (index !== -1) {
                draft.splice(index, 1);
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patch.undo();
        }
      },
      invalidatesTags: (result, error, id) =>
        generateInvalidationTags(TagType.MessageTemplate, id),
    }),

    // Job Type endpoints
    updateJobType: builder.mutation<
      JobType,
      Pick<JobType, "id"> &
        Partial<
          Pick<
            JobType,
            | "name"
            | "questions"
            | "job_description"
            | "prompt_context"
            | "settings"
          >
        >
    >({
      query: (values) => ({
        method: "PATCH",
        url: `/job-types/${encodeURIComponent(values.id)}`,
        body: values,
      }),
      invalidatesTags: (result, error, { id }) =>
        generateInvalidationTags(TagType.JobType, id),
    }),
  }),
});

export const {
  // Message Template hooks
  useAllMessageTemplatesQuery,
  useCreateMessageTemplateMutation,
  useUpdateMessageTemplateMutation,
  useDeleteMessageTemplateMutation,
  // Job Type hooks
  useUpdateJobTypeMutation,
} = api;
