import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  PencilIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  InterviewAgentTypesEnum,
  JobTypePropertyEnum,
  Project,
  ProjectSettingsSections,
  buildProjectUrlPath,
  getContactDisplayNames,
  getProjectAndJobTypeProperty,
} from "app-types";
import { FC, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonVariantsEnum,
  IconButton,
  InfoTooltip,
  SmallTimeStamp,
  TableSkeleton,
} from "ui";
import { InterviewWithContactAndActivities } from "../../app/admin_client_types";
import { useAppDispatch, useAppSelector } from "../../hooks/hook";
import { selectAssessmentById } from "../assessments/assessmentsSlice";
import { AssessmentScore } from "../insights/assessmentScore";
import { SentimentScore } from "../insights/sentimentScore";
import { PaginationControls } from "../miscellaneous/paginationControls";
import { InterviewDecisionIndicator } from "./interviewDecisionIndicator";
import { InterviewQuoteMatch } from "./interviewQuoteMatch";
import { InterviewAssessmentRating } from "./interviewSlideover/interviewAssessmentRating";
import { InterviewSummary } from "./interviewSlideover/interviewSummary";
import { UnansweredQuestionsBanner } from "./interviewSlideover/unansweredQuestionsBanner";
import {
  fetchNonEmptyInterviewsForProject,
  selectInterviewIdToMatchingFragmentId,
  selectInterviewParameters,
  selectTotalInterviewPages,
} from "./interviewsSlice";

export interface InterviewsTableProps {
  interviews: InterviewWithContactAndActivities[];
  isLoading: boolean;
  onInterviewClick: (
    interviewId: string,
    matchingTranscriptFragmentId?: string,
  ) => void;
  project: Project;
}

export const InterviewsTable: FC<InterviewsTableProps> = (props) => {
  const navigate = useNavigate();
  const { interviews, onInterviewClick, project } = props;
  const isRecruitingProject =
    project.settings.interview_agent_type ===
    InterviewAgentTypesEnum.RecruitingInterviewer;
  const dispatch = useAppDispatch();
  const interviewParameters = useAppSelector(selectInterviewParameters);
  const totalPages = useAppSelector(selectTotalInterviewPages);
  const interviewIdToMatchingFragmentId = useAppSelector(
    selectInterviewIdToMatchingFragmentId,
  );
  const assessment = useAppSelector(
    selectAssessmentById(
      getProjectAndJobTypeProperty(project, JobTypePropertyEnum.AssessmentId),
    ),
  );

  const placeholder = <span className="text-gray-400">--</span>;

  const getAssessmentColumns =
    (): ColumnDef<InterviewWithContactAndActivities>[] => {
      // For non recruiting projects we just show the contact and summary
      if (!isRecruitingProject) {
        return [];
      }

      // If there are no assessment criteria, show a placeholder
      if (!assessment?.custom_field_definitions.length) {
        return [
          {
            accessorFn: () => (
              <span className="text-gray-400">No assessment criteria.</span>
            ),
            id: "assessment_placeholder",
            header: () => (
              <div className="flex flex-row gap-2">
                <span>Assessment Fields</span>
                <IconButton
                  icon={<PencilIcon className="w-3 h-3" />}
                  onClick={() => {
                    navigate(
                      buildProjectUrlPath(
                        project.id,
                        undefined,
                        undefined,
                        undefined,
                        ProjectSettingsSections.InterviewAssessment,
                      ),
                    );
                  }}
                  variant={ButtonVariantsEnum.Tertiary}
                />
              </div>
            ),
            enableSorting: false,
          },
        ];
      }

      // Show a column for each assessment criteria
      return assessment.custom_field_definitions.map((field) => ({
        accessorFn: (row: InterviewWithContactAndActivities) => {
          const customField = row.custom_fields.find(
            (cf) => cf.custom_field_definition_id === field.id,
          );
          return <InterviewAssessmentRating rating={customField?.data.value} />;
        },
        id: `assessment_${field.id}`,
        header: () => <span>{field.display_name}</span>,
        enableSorting: false,
      }));
    };

  // Define table columns. Sortable by email, status, and started_at but not names.
  const coreColumns: ColumnDef<InterviewWithContactAndActivities>[] = [
    {
      accessorFn: (row: InterviewWithContactAndActivities) => {
        const {
          primaryDisplayName,
          secondaryDisplayName,
          tertiaryDisplayName,
        } = getContactDisplayNames(
          row.contact,
          row.contact.account ? row.contact.account.name : undefined,
        );

        return (
          <div className="truncate">
            <div className="flex items-center gap-1">
              <span className="truncate">{primaryDisplayName}</span>
              {row.started_at ? (
                <SmallTimeStamp timestamp={row.started_at} />
              ) : null}
              <InterviewDecisionIndicator decision={row.decision} />
              {row.status === "in_progress" ? (
                <InfoTooltip
                  content="Interview partially completed"
                  id={`completed-${row.id}`}
                  place="right"
                >
                  <ClockIcon className="h-4 w-4 flex-shrink-0 text-gray-500" />
                </InfoTooltip>
              ) : null}
              {row.insights?.unanswered_candidate_questions &&
              row.insights.unanswered_candidate_questions.length > 0 ? (
                <InfoTooltip
                  content={
                    <UnansweredQuestionsBanner
                      isTooltipMode
                      unansweredQuestions={
                        row.insights.unanswered_candidate_questions
                      }
                    />
                  }
                  id={`unanswered-${row.id}`}
                  place="right"
                >
                  <QuestionMarkCircleIcon className="h-4 w-4 flex-shrink-0 text-yellow-600" />
                </InfoTooltip>
              ) : null}
            </div>
            {secondaryDisplayName ? (
              <div className="truncate text-gray-600">
                {secondaryDisplayName}
              </div>
            ) : null}
            {tertiaryDisplayName ? (
              <div className="truncate text-gray-600">
                {tertiaryDisplayName}
              </div>
            ) : null}
          </div>
        );
      },
      id: "email",
      header: () => <span>Contact</span>,
      enableSorting: false,
    },
    {
      accessorFn: (row: InterviewWithContactAndActivities) => {
        // For recruiting projects we show the assessment score
        if (isRecruitingProject) {
          if (!row.insights?.assessment_score) {
            return placeholder;
          }

          return (
            <div className="text-wrap flex flex-col gap-2">
              <InfoTooltip
                content={
                  <InterviewSummary insights={row.insights} isToolTipMode />
                }
                id={`assessment-${row.id}`}
                place="right"
              >
                <div className="w-fit">
                  <AssessmentScore score={row.insights.assessment_score} />
                </div>
              </InfoTooltip>
              <InterviewQuoteMatch interview={row} />
            </div>
          );
        }

        // For non recruiting projects we show the sentiment score and interview summary
        return (
          <div className="text-wrap flex flex-col space-y-2">
            {row.insights?.sentiment_score ? (
              <div>
                <SentimentScore score={row.insights.sentiment_score} />
              </div>
            ) : null}
            {row.insights ? <InterviewSummary insights={row.insights} /> : null}
            <InterviewQuoteMatch interview={row} />
          </div>
        );
      },
      id: "summary",
      header: () => (
        <span>
          {isRecruitingProject ? "Overall Score" : "Sentiment & Summary"}
        </span>
      ),
      enableSorting: false,
    },
    ...getAssessmentColumns(),
  ];

  const columns = useMemo(() => coreColumns, [coreColumns]);

  const readOnlyColumn: Partial<ColumnDef<InterviewWithContactAndActivities>> =
    {
      cell: ({ getValue }) => {
        return (
          <div className="py-2 px-3 text-left text-sm">
            {getValue() as string}
          </div>
        );
      },
    };

  const table = useReactTable({
    data: interviews,
    columns,
    defaultColumn: readOnlyColumn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const renderTableBodyRows = () => {
    if (props.isLoading) {
      return <TableSkeleton />;
    }

    // Empty state.
    if (!interviews.length) {
      const defaultEmptyText =
        "When interviews are completed, AI-generated summaries and transcripts will appear here.";
      const searchEmptyText = "No matching interview transcripts found.";
      const scoreFilterEmptyText =
        "No interviews match the minimum score filter.";

      return (
        <tr>
          <td
            className="px-3 py-9 text-center text-sm text-gray-700"
            colSpan={table.getAllColumns().length}
          >
            {interviewParameters?.min_assessment_score !== undefined
              ? scoreFilterEmptyText
              : interviewParameters?.search_text
                ? searchEmptyText
                : defaultEmptyText}
          </td>
        </tr>
      );
    }

    return table.getRowModel().rows.map((row, index) => {
      // For recruiting projects we fix the width of the contact and assessment columns
      const cellIndexToStyle: Record<number, string> = {
        0: isRecruitingProject
          ? "left-0 w-[250px] min-w-[250px] max-w-[250px]"
          : "",
        1: isRecruitingProject
          ? "left-[250px] w-[130px] min-w-[140px] max-w-[130px] border-r border-gray-200"
          : "",
      };

      return (
        <tr
          className={`${
            index % 2 === 0 ? "bg-white" : "bg-gray-50"
          } hover:bg-blue-50 cursor-pointer`}
          key={row.id}
          onClick={() => {
            const interviewId = row.original.id;
            const matchingTranscriptFragmentId =
              interviewIdToMatchingFragmentId[interviewId];
            onInterviewClick(interviewId, matchingTranscriptFragmentId);
          }}
        >
          {row.getVisibleCells().map((cell, cellIndex) => {
            return (
              <td
                className={`whitespace-nowrap text-xs text-gray-900 align-middle text-left ${
                  cellIndexToStyle[cellIndex] || ""
                } ${isRecruitingProject ? "align-middle" : "align-text-top"}`}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col flex-grow overflow-auto">
      {/* Container for the table that allows the body to scroll */}
      <div className="flex flex-col mx-auto mt-1 overflow-auto rounded-t-md border-t w-full outline-none">
        <table className="min-w-full border-x outline-none">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    className={`bg-gray-100 sticky top-0 py-2 px-3 text-left text-xs font-semibold align-top min-w-[120px] break-words whitespace-normal ${
                      index === 1 ? "border-r border-gray-200" : ""
                    }`}
                    colSpan={header.colSpan}
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "flex flex-col items-start cursor-pointer select-none"
                            : "flex flex-col items-start",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {header.column.getCanSort()
                          ? getSortingChevron(
                              header.column.getIsSorted() as string,
                            )
                          : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white overflow-y-auto">
            {renderTableBodyRows()}
          </tbody>
        </table>
      </div>
      <div className="bg-gray-100 border-gray-200 text-sm px-3 py-2 mb-4 rounded-b-md border min-h-11 flex">
        <PaginationControls
          onClickNextPage={() => {
            if (interviewParameters) {
              dispatch(
                fetchNonEmptyInterviewsForProject({
                  ...interviewParameters,
                  page: interviewParameters.page + 1,
                }),
              );
            }
          }}
          onClickPreviousPage={() => {
            if (interviewParameters) {
              dispatch(
                fetchNonEmptyInterviewsForProject({
                  ...interviewParameters,
                  page: interviewParameters.page - 1,
                }),
              );
            }
          }}
          page={interviewParameters?.page || 1}
          totalPages={totalPages || 1}
        />
      </div>
    </div>
  );
};

function getSortingChevron(sortDirection?: string) {
  switch (sortDirection) {
    case "asc":
      return (
        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-200 h-fit">
          <ChevronUpIcon aria-hidden="true" className="h-4 w-4" />
        </span>
      );
    case "desc":
      return (
        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-200 h-fit">
          <ChevronDownIcon aria-hidden="true" className="h-4 w-4" />
        </span>
      );
    default:
      return <div aria-hidden="true" className="ml-2 h-4 w-4" />; // Placeholder with the same size as the chevrons.
  }
}
