import { Keyword } from "app-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/hook";
import { selectContactsDictionary } from "../contacts/contactsSlice";
import { selectInterviewsDictionary } from "../interviews/interviewsSlice";
import { selectTranscriptFragmentGroupsForKeyword } from "../transcriptFragments/transcriptFragmentsSlice";
import { ContactNameWithTranscriptFragment } from "./contactNameWithTranscriptFragment";

interface QuestionThemeQuoteProps {
  keyword_tracker_id: string;
  keyword: Keyword;
}

export const QuestionThemeQuotes: React.FC<QuestionThemeQuoteProps> = ({
  keyword,
  keyword_tracker_id,
}) => {
  const navigate = useNavigate();

  const contactsDictionary = useAppSelector(selectContactsDictionary);
  const interviewsDictionary = useAppSelector(selectInterviewsDictionary);

  const transcriptFragmentGroups = useAppSelector(
    selectTranscriptFragmentGroupsForKeyword(keyword_tracker_id, keyword),
  );

  return (
    <div className="space-y-2 bg-white">
      {transcriptFragmentGroups.map((tfg) => {
        const primaryFragment = tfg.length > 0 ? tfg[0] : null;
        if (!primaryFragment || !primaryFragment.text_transcript) return null;

        const interview = interviewsDictionary[primaryFragment.interview_id];
        if (!interview) return null; // Shouldn't happen with proper state management

        const contact = contactsDictionary[interview.contact_id];
        if (!contact) return null; // Shouldn't happen with proper state management

        return (
          <ContactNameWithTranscriptFragment
            key={`keyword-tf-${primaryFragment.id}`}
            transcriptFragments={tfg}
            date={new Date(primaryFragment?.start_time)}
            contact={contact}
            onClick={() => {
              // Add interview_id query parameter to the current location
              navigate({
                search: `?interview_id=${interview.id}&f=${primaryFragment.id}`,
              });
            }}
            classNames="rounded-none"
          />
        );
      })}
    </div>
  );
};
