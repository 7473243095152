/*
 * Supabase types.
 */

import type {
  ProjectQuestion,
  ResumeCollectionTypesEnum,
} from "./src/client/client.ts";
import { InterviewLanguagesEnum } from "./src/constants/languages.ts";
import type { TablesInsert, TablesUpdate } from "./src/db/supabase.ts";
import {
  type AlpharunTableName,
  type DatabaseWithFixedTypes,
  type TablesInsertWithFixedTypes,
  type TablesUpdateWithFixedTypes,
  type TablesWithFixedTypes,
} from "./src/db/supabase_with_fixed_types.ts";
import type {
  BalanceTransactionStatus,
  BalanceTransactionType,
  CompanyInvitationStatus,
  ComplexQuestionOption,
  CustomFieldProvider,
  IntegrationProvider,
  IntegrationSettings,
  KeywordAnalysis,
  KeywordTrackerType,
  SurveyScale,
} from "./src/db/types.ts";
import type { CustomFieldTypesEnum } from "./src/entities/customField.ts";
import { type JobTypeDB } from "./src/entities/jobType.ts";
import {
  InterviewAgentTypesEnum,
  ProjectEmailDigestFrequencyEnum,
} from "./src/entities/project.ts";

export interface ProjectSettings {
  send_email_digest_to_all_teammates: boolean;
  send_email_digest_to_project_owners: boolean;
  email_digest_recipients: string[];
  email_digest_frequency: ProjectEmailDigestFrequencyEnum;
  max_interview_count: number | null;
  is_incentive_charity_only: boolean;
  is_slack_enabled: boolean;
  slack_channel_id: string | null;
  prompt_context: string | null;
  max_clarifying_questions_count: number | null;
  redirect_url: string | null;
  welcome_message: string | null;
  outro_message: string | null;
  interview_agent_type: InterviewAgentTypesEnum;
  resume_collection_type: ResumeCollectionTypesEnum | null;
  interview_language: InterviewLanguagesEnum;
  should_force_fullscreen: boolean;
  should_record_video: boolean;
  acceptance_template_id: string | null;
  rejection_template_id: string | null;
  auto_advance_assessment_score_threshold: number | null;
}

export enum QuestionsImportSourcesEnum {
  LIST_OF_QUESTIONS = "list_of_questions",
  RESEARCH_DISCUSSION_GUIDE = "research_discussion_guide",
  JOB_DESCRIPTION = "job_description",
}

export type ComplexQuestionDb = TablesWithFixedTypes<"question">;

export type ComplexQuestion = MatrixQuestion | MultipleChoiceQuestion;

interface ComplexQuestionBase {
  id: string;
  company_id: string;
  project_id: string;
  question: string;
}

const complexQuestionTypes = ["rating", "multiple_choice"] as const;
export type ComplexQuestionType = (typeof complexQuestionTypes)[number];

export type MatrixQuestion = ComplexQuestionBase & {
  type: "rating";
  options: ComplexQuestionOption[];
  scale: SurveyScale;
};

export type MultipleChoiceQuestion = ComplexQuestionBase & {
  type: "multiple_choice";
  options: ComplexQuestionOption[];
};

export type ComplexQuestionInsert =
  | Omit<MatrixQuestion, "id" | "project_id">
  | Omit<MultipleChoiceQuestion, "id" | "project_id">;

// When fetching projects from the DB, we also join the `question` table into `complex_questions`
// That way, ProjectDb has all the necessary data to be deserialized into Project
export type ProjectDb = TablesWithFixedTypes<"project"> & {
  complex_questions: ComplexQuestionDb[];
  members: readonly ProjectMemberDb[];
  job_type: JobTypeDB | null;
};

export type ProjectInsert = Omit<TablesInsert<"project">, "questions"> & {
  questions: ProjectQuestion[];
  owner_teammate_ids?: readonly string[];
};

export type ProjectUpdate = Omit<TablesUpdate<"project">, "questions"> & {
  questions?: ProjectQuestion[];
  owner_teammate_ids?: readonly string[];
};

export enum ProjectModesEnum {
  SURVEY = "survey",
  VOICE_AGENT = "voice_agent",
}

export type Project = Omit<
  ProjectDb,
  | "questions"
  | "settings"
  | "complex_questions"
  | "mode"
  | "members"
  | "job_type_id"
> & {
  job_type: JobTypeDB | null;
  questions: ProjectQuestion[];
  owner_teammate_ids: readonly string[];
  settings: ProjectSettings;
  mode: ProjectModesEnum;
};

export type ProjectWithInterviewCount = Project & {
  interview_count: number;
  pending_interview_count: number;
  has_reached_interview_limit: boolean;
};

export type ProjectMemberDb = TablesWithFixedTypes<"project_member">;
export type ProjectMemberInsert = TablesInsertWithFixedTypes<"project_member">;
export type ProjectMemberUpdate = TablesUpdateWithFixedTypes<"project_member">;
export interface ProjectMember {
  project_id: string;
  teammate_id: string;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
}

export type TranscriptFragmentDB = TablesWithFixedTypes<"transcript_fragment">;
export type TranscriptFragmentInsert =
  TablesInsertWithFixedTypes<"transcript_fragment">;

export interface TranscriptFragmentDbWithDynamicFragmentsList
  extends TranscriptFragmentDB {
  dynamic_transcript_fragment_ids?: string[];
}

export type AccountDb = TablesWithFixedTypes<"account">;
export type AccountInsert = TablesInsertWithFixedTypes<"account">;
export type AccountUpdate = TablesUpdateWithFixedTypes<"account">;
export interface Account {
  id: string;
  ext_id: string;
  name: string;
  custom_fields: CustomField[];
  created_at: string;
  updated_at: string | null;
}

export type ActivityDb = TablesWithFixedTypes<"activity">;
export type ActivityInsert = TablesInsertWithFixedTypes<"activity">;
export type Activity = ActivityDb;

export type CompanyInvitationDb = TablesWithFixedTypes<"company_invitation">;
export type CompanyInvitationInsert =
  TablesInsertWithFixedTypes<"company_invitation">;
export type CompanyInvitationUpdate =
  TablesUpdateWithFixedTypes<"company_invitation">;
export interface CompanyInvitation {
  id: string;
  company_id: string;
  recipient_email: string;
  invited_by_teammate_id: string;
  status: CompanyInvitationStatus;
  company_name: string;
  sender_full_name: string;
  sender_email: string;
  reminder_email_count: number;
  expires_at: string;
  created_at: string;
}

export enum CompanyInvitationErrorsEnum {
  NO_EMAIL_MATCH = "no_email_match",
  EXPIRED = "expired",
  NOT_FOUND = "not_found",
  ALREADY_JOINED = "already_joined",
}

export enum InterviewAccessErrorsEnum {
  NOT_LIVE = "not_live",
  NOT_FOUND = "not_found",
  LIMIT_REACHED = "limit_reached",
  RATE_LIMIT_EXCEEDED = "rate_limit_exceeded",
  UNKNOWN = "unknown",
}

export interface Keyword {
  phrase: string;
  transcript_fragment_ids: string[];
  analyses: KeywordAnalysis[];
}

export type KeywordTrackerDb = TablesWithFixedTypes<"keyword_tracker">;

export type KeywordTrackerInsert =
  TablesInsertWithFixedTypes<"keyword_tracker">;
export type KeywordTrackerUpdate =
  TablesUpdateWithFixedTypes<"keyword_tracker">;

export interface KeywordTracker {
  id: string;
  name: string;
  keywords: Keyword[];
  type: KeywordTrackerType;
  project_id: string | null;
  question: string | null;
  created_at: string;
  updated_at: string | null;
}

export interface KeywordMatches {
  transcript_fragment_ids: string[];
  interview_ids: string[];
}

export type KeywordTrackerMatches = Record<string, KeywordMatches>;

export type IntegrationDb = TablesWithFixedTypes<"integration">;
export type IntegrationInsert = TablesInsertWithFixedTypes<"integration">;
export type IntegrationUpdate = TablesUpdateWithFixedTypes<"integration">;

export interface Integration {
  id: string;
  provider: IntegrationProvider;
  settings: IntegrationSettings;
  created_at: string;
  updated_at: string | null;
}

export interface IntegrationWithCredentials extends Integration {
  access_token: string | null;
  refresh_token: string | null;
}

export type CustomFieldDefinitionDb =
  TablesWithFixedTypes<"custom_field_definition">;
export type CustomFieldDefinitionInsert =
  TablesInsertWithFixedTypes<"custom_field_definition">;
export type CustomFieldDefinitionUpdate =
  TablesUpdateWithFixedTypes<"custom_field_definition">;

export type CustomFieldDefinitionInsertClient = Omit<
  CustomFieldDefinitionInsert,
  "company_id"
>;

export type CustomFieldValueDb = TablesWithFixedTypes<"custom_field_value">;
export type CustomFieldValueInsert =
  TablesInsertWithFixedTypes<"custom_field_value">;
export type CustomFieldValueUpdate =
  TablesUpdateWithFixedTypes<"custom_field_value">;

export interface CustomFieldValueData {
  value: string | number | Date | boolean | null;
  display_value: string | undefined;
}

export interface CustomFieldValue {
  id: string;
  custom_field_definition_id: string;
  resource_id: string;
  resource_type: CustomFieldResourceTypesEnum;
  data: CustomFieldValueData;
  created_at: string;
  updated_at: string | null;
}

export enum CustomFieldResourceTypesEnum {
  CONTACT = "contact",
  ACCOUNT = "account",
  INTERVIEW = "interview",
}

// The metadata required to allow user to pick external field to sync
export interface CustomFieldMetadata {
  provider: CustomFieldProvider;
  resource_type: CustomFieldResourceTypesEnum;
  display_name: string;
  field_name: string;
  field_type: CustomFieldTypesEnum;
}

export const customFieldDefinitionSettingsDefaults: CustomFieldDefinitionSettings =
  {
    description: null,
  };

export interface CustomFieldDefinitionSettings {
  description: string | null; // Not used yet - for future control of the meaning of an AI criteria
}

// A custom field definition that has been saved
export type CustomFieldDefinition = CustomFieldMetadata & {
  id: string;
  created_at: string;
  updated_at: string | null;
};

// Partial combination of a field definition + value that's used for display on the client
export interface CustomField {
  custom_field_definition_id: string;
  data: CustomFieldValueData;
  provider: CustomFieldProvider;
  display_name: string;
  field_name: string;
  field_type: CustomFieldTypesEnum;
}

export interface BillingProductInfo {
  billing_period_starts_at: string;
  billing_period_ends_at: string;
  usage_count: number | null;
  product_name: string;
}

export type ApiKeyDb = TablesWithFixedTypes<"api_key">;
export type ApiKeyInsert = TablesInsertWithFixedTypes<"api_key">;
export type ApiKeyUpdate = TablesUpdateWithFixedTypes<"api_key">;
export interface ApiKey {
  id: string;
  name: string;
  key_suffix: string;
  created_at: string;
  updated_at: string | null;
}

export type ApiKeyWithFullKey = ApiKey & {
  key: string;
};

// the amount in the smallest currency unit (USD cents until we support other currencies)
export type BalanceTransactionDb = TablesWithFixedTypes<"balance_transaction">;
export type BalanceTransactionInsert =
  TablesInsertWithFixedTypes<"balance_transaction">;

export interface BalanceTransaction {
  id: string;
  description: string;
  unit_amount: number;
  type: BalanceTransactionType;
  status: BalanceTransactionStatus;
  interview_id: string | null;
  created_at: string;
  updated_at: string | null;
}

// Payment methods like Stripe Link dont have card details
export type PaymentMethodInfo =
  | {
      brand: string;
      last4: string;
      exp_month: number;
      exp_year: number;
      payment_method_type: string;
      is_card: true;
    }
  | { payment_method_type: string; is_card: false };

/*
 * Client types.
 */

export * from "./src/client/chartTypes.ts";
export * from "./src/client/client.ts";

/*
 * Helpers.
 */

export const projectSettingsDefaults: ProjectSettings = {
  send_email_digest_to_all_teammates: true,
  send_email_digest_to_project_owners: true,
  email_digest_recipients: [],
  email_digest_frequency: ProjectEmailDigestFrequencyEnum.DAILY,
  max_interview_count: null,
  is_slack_enabled: true,
  slack_channel_id: null,
  prompt_context: null,
  max_clarifying_questions_count: 2,
  redirect_url: null,
  welcome_message: null,
  outro_message: null,
  interview_agent_type: InterviewAgentTypesEnum.GenericInterviewer,
  is_incentive_charity_only: false,
  resume_collection_type: null,
  interview_language: InterviewLanguagesEnum.ENGLISH,
  should_force_fullscreen: false,
  should_record_video: false,
  acceptance_template_id: null,
  rejection_template_id: null,
  auto_advance_assessment_score_threshold: null,
};

export const mapProjectDbToProject = (projectDb: ProjectDb): Project => {
  const questions = projectDb.questions.map((q) => {
    if ("question_id" in q) {
      const matchingComplexQuestion = projectDb.complex_questions.find(
        (cq: any) => cq.id === q.question_id,
      );

      if (!matchingComplexQuestion) {
        throw new Error(`Complex question with id ${q.question_id} not found`);
      }

      return mapComplexQuestionDbToComplexQuestion(matchingComplexQuestion);
    }

    return q;
  }) as ProjectQuestion[];

  const owner_teammate_ids = projectDb.members
    .filter((member) => !member.deleted_at)
    .map((member) => member.teammate_id);

  return {
    ...projectDb,
    questions,
    owner_teammate_ids,
    settings: mapProjectSettingsDbToProjectSettings(projectDb.settings),
    mode: (projectDb.mode || ProjectModesEnum.SURVEY) as ProjectModesEnum,
    job_type: projectDb.job_type ?? null,
  };
};

export const mapProjectSettingsDbToProjectSettings = (
  projectSettingsDb: any,
) => {
  const settings: ProjectSettings = {
    ...projectSettingsDefaults,
    ...(typeof projectSettingsDb === "object" ? projectSettingsDb : {}),
  };

  return settings;
};

export const mapComplexQuestionDbToComplexQuestion = (
  questionDb: ComplexQuestionDb,
): ComplexQuestion => {
  if (questionDb.type === "rating")
    return {
      id: questionDb.id,
      company_id: questionDb.company_id,
      project_id: questionDb.project_id,
      question: questionDb.question,
      type: questionDb.type,
      options: questionDb.options as unknown as ComplexQuestionOption[],
      scale: questionDb.scale!,
    };
  else if (questionDb.type === "multiple_choice")
    return {
      id: questionDb.id,
      company_id: questionDb.company_id,
      project_id: questionDb.project_id,
      question: questionDb.question,
      type: questionDb.type,
      options: questionDb.options as unknown as ComplexQuestionOption[],
    };
  throw new Error(`Unknown question type: ${questionDb.type}`);
};

export function isComplexQuestionType(
  value: string,
): value is ComplexQuestionType {
  return complexQuestionTypes.includes(value as ComplexQuestionType);
}

/*
 * Shared constants.
 */

export const DEMO_INTERVIEW_ID = "demo-interview";
export const QUESTION_SUMMARY_MAX_INTERVIEW_COUNT = 25; // Summarize the 25 most recent interview responses for a given question
export const QUESTION_SUMMARY_MIN_INTERVIEW_COUNT = 5; // Don't summarize until we have at least 5 responses

export const MAX_GIFT_CARD_BALANCE_INCREASE_USD = 2000;
export const MIN_GIFT_CARD_BALANCE_INCREASE_USD = 100;

export const MAX_UPLOAD_FILE_SIZE_BYTES = 10 * 1024 * 1024; // 10MB

export const COMPETITORS_KEYWORD_TRACKER_NAME = "Competitors";

export * from "./src/constants/assessmentScore.ts";
export * from "./src/constants/languages.ts";
export { ProjectTypeToDefaultQuestions } from "./src/constants/projectTypeToDefaultQuestions.ts";
export * from "./src/constants/whitelabelConstants.ts";

/*
 * Shared utilities.
 */

export * from "./src/helpers/publicStorage.ts";
export * from "./src/helpers/utilities.ts";

export * from "./src/integrations/integrations.ts";

export * from "./src/content/plaintext.ts";
export * from "./src/content/types.ts";
export * from "./src/helpers/billingFeatureGating.ts";
export * from "./src/helpers/interviewHelpers.ts";
export * from "./src/helpers/queueHelpers.ts";
export * from "./src/helpers/smsHelpers.ts";

/**
 * Business entities
 */
export * from "./src/entities/assessment.ts";
export * from "./src/entities/attachment.ts";
export * from "./src/entities/company.ts";
export * from "./src/entities/complexAnswer.ts";
export * from "./src/entities/contact.ts";
export * from "./src/entities/customField.ts";
export * from "./src/entities/interview.ts";
export * from "./src/entities/jobType.ts";
export * from "./src/entities/messageTemplate.ts";
export * from "./src/entities/phoneNumber.ts";
export * from "./src/entities/phoneNumberExtension.ts";
export * from "./src/entities/project.ts";
export * from "./src/entities/teammate.ts";
export * from "./src/entities/transcriptFragment.ts";

/**
 * Re-usable DB types
 */
export * from "./src/db/types.ts";
export type {
  DatabaseWithFixedTypes,
  AlpharunTableName as TableName,
  TablesInsertWithFixedTypes,
  TablesUpdateWithFixedTypes,
  TablesWithFixedTypes,
};
