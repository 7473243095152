import { isValidUrl } from "app-types/src/helpers/utilities";
import React, { useEffect, useState } from "react";
import { Button, ButtonVariantsEnum, Modal, ModalHeader, Textarea } from "ui";
import { getAxiosInstanceWithAuth } from "../../../api/axiosConfig";
import { useAppDispatch } from "../../../hooks/hook";
import {
  NotificationTypeEnum,
  showNotification,
} from "../../notificationsOverlay/notificationsSlice";
import { PhoneNumberSelect } from "../../phoneNumbers/phoneNumberSelect";
import { fetchPhoneNumbers } from "../../phoneNumbers/phoneNumbersSlice";
import { fetchAllProjects } from "../projectsSlice";

interface ImportJobPostingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ImportJobPostingsModal: React.FC<ImportJobPostingsModalProps> = (
  props,
) => {
  const { isOpen } = props;

  const [urls, setUrls] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loadingTextStage, setLoadingTextStage] = useState(0);
  const [selectedPhoneNumberId, setSelectedPhoneNumberId] = useState<
    string | undefined
  >(undefined);
  const dispatch = useAppDispatch();

  const onClose = () => {
    // Reset state before calling the onClose function
    setUrls("");
    setError(null);
    setIsLoading(false);
    setLoadingTextStage(0);
    props.onClose();
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    // Cycle through loading state messages sync importing can take awhile
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingTextStage((prevState) => {
          if (prevState < 2) {
            return prevState + 1;
          }
          return prevState;
        });
      }, 5000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isLoading]);

  const getLoadingLabel = () => {
    switch (loadingTextStage) {
      case 0:
        return "Scraping job posting...";
      case 1:
        return "Generating interview questions...";
      case 2:
        return "Creating projects...";
      default:
        return "Creating projects...";
    }
  };

  const handleSubmit = async () => {
    setError(null);

    const urlList = urls
      .split(/[\n,]+/) // Split by newlines and commas
      .filter(Boolean)
      .map((url) => url.trim());
    if (urlList.length > 10) {
      setError("Please provide no more than 10 job posting links.");
      return;
    }

    const invalidUrls = urlList.filter((url) => !isValidUrl(url));
    if (invalidUrls.length > 0) {
      setError(
        `Invalid URL${invalidUrls.length > 1 ? "s" : ""}: ${invalidUrls.join(
          ", ",
        )}`,
      );
      return;
    }

    setIsLoading(true);
    const axiosInstance = await getAxiosInstanceWithAuth();
    const response = await axiosInstance.post("/projects/import-jobs", {
      urls: urlList,
      phone_number_id: selectedPhoneNumberId,
    });

    // If we have no successful projects, set an error and allow the user to correct their URLs
    if (response.data.successfulProjects.length === 0) {
      showNotification(dispatch, {
        id: `projects-error-${new Date().getTime()}`,
        primaryMessage: `Sorry, an issue occurred while importing job postings. Please check the job posting URLs and try again.`,
        type: NotificationTypeEnum.FAILURE,
      });
      setIsLoading(false);
      setLoadingTextStage(0);
      return;
    }

    // If we've imported at least 1 job posting, show a notification and close the modal.
    if (response.status === 200) {
      showNotification(dispatch, {
        id: `projects-imported-${new Date().getTime()}`,
        primaryMessage: `Successfully imported ${response.data.successfulProjects.length} job postings`,
        type: NotificationTypeEnum.SUCCESS,
      });
    } else if (response.status === 207) {
      showNotification(dispatch, {
        id: `projects-partially-imported-${new Date().getTime()}`,
        primaryMessage: `Imported ${response.data.successfulProjects.length} of ${urlList.length} job postings - some job postings could not be imported.`,
        type: NotificationTypeEnum.SUCCESS,
      });
    }

    // Refetch projects and phone numbers
    dispatch(fetchAllProjects());
    dispatch(fetchPhoneNumbers());

    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onCancel={onClose}>
      <ModalHeader>Import job postings</ModalHeader>
      <div className="space-y-4 mt-4">
        <div className="text-sm ">
          Provide a list of up to 10 job posting links separated by a comma or
          newline. We'll scrape each URL for a job description, automatically
          generate interview questions, and create a project for it.
        </div>

        <Textarea
          id="job-description-urls"
          placeholder="https://example.com/jobs/customer-service-rep, https://example.com/jobs/receptionist"
          value={urls}
          onChange={(e) => {
            setUrls(e.target.value);
            setError(null);
          }}
          height="200px"
        />
        <PhoneNumberSelect
          phoneNumberId={selectedPhoneNumberId}
          onChange={setSelectedPhoneNumberId}
          className="mt-4"
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}
        <div className="flex justify-end space-x-2">
          <Button
            variant={ButtonVariantsEnum.Secondary}
            onClick={onClose}
            label="Cancel"
          />
          <Button
            variant={ButtonVariantsEnum.Primary}
            onClick={handleSubmit}
            label={isLoading ? getLoadingLabel() : "Import"}
            isDisabled={isLoading || !urls.trim() || Boolean(error)}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};
