import { Menu } from "@headlessui/react";
import { VariableIcon } from "@heroicons/react/24/outline";
import { MessageTemplateVariablesEnum } from "app-types";
import { useRef } from "react";
import {
  Button,
  ButtonVariantsEnum,
  capitalize,
  DropdownMenu,
  SizesEnum,
} from "ui";

interface UseMessageVariablesProps<
  T extends HTMLInputElement | HTMLTextAreaElement,
> {
  value: string;
  onChange: (value: string) => void;
}

export const useMessageVariables = <
  T extends HTMLInputElement | HTMLTextAreaElement,
>({
  value,
  onChange,
}: UseMessageVariablesProps<T>) => {
  const elementRef = useRef<T>(null);

  const insertPlaceholder = (placeholder: string) => {
    const element = elementRef.current;

    if (!element) {
      // If no element exists, append to end
      const newValue = value + `[${placeholder}]`;
      onChange(newValue);
      return;
    }

    const cursorPosition = element.selectionStart || 0;
    const newValue =
      value.slice(0, cursorPosition) +
      `[${placeholder}]` +
      value.slice(cursorPosition);

    onChange(newValue);

    // Maintain focus and set cursor position after the inserted placeholder
    const newPosition = cursorPosition + placeholder.length + 2; // +2 for [ and ]
    setTimeout(() => {
      element.focus();
      element.setSelectionRange(newPosition, newPosition);
    }, 0);
  };

  const dropdownItems = Object.entries(MessageTemplateVariablesEnum).map(
    ([key, value]) => ({
      label: capitalize(key.toLowerCase().replace(/_/g, " ")),
      onClick: () => insertPlaceholder(value),
    }),
  );

  const VariablesDropdown = () => (
    <Menu as="div" className="relative inline-block">
      <Menu.Button as="div">
        <Button
          icon={<VariableIcon className="w-4 h-4 mr-2" />}
          label="Add variable"
          onClick={() => {}}
          size={SizesEnum.SMALL}
          variant={ButtonVariantsEnum.Secondary}
        />
      </Menu.Button>

      <DropdownMenu align="left" items={dropdownItems} />
    </Menu>
  );

  return {
    ref: elementRef,
    VariablesDropdown,
  };
};
